import * as e from "./StockProduccionesCSS";
import { Contexto } from "../../../../../Componentes/Principal/index";
import React, { useState, useEffect, useContext } from "react";
import Boton from "../../../../../Elementos/Boton";
import Input from "../../../../../Elementos/Input";
import Opciones from "../../../../../Elementos/Opciones";
import * as api from "./../../../../../Api/Api";
import * as fg from "../../../../../Api/FuncionesGlobales";
import ModalGenerico from "./../../../../../Elementos/ModalGenerico";
import StockPdf from "./StockPdf";

function StockProducciones(props) {
  const { esComputadora, rol } = useContext(Contexto);
  const [listaMaestro, setListaMaestro] = useState([]);
  const [maestro, setMaestro] = useState([]);
  const [listaProductos, setListaProductos] = useState([]);
  const [editable, setEditable] = useState(false);
  const [estaProcesando, setestaProcesando] = useState(false);
  const [reajuste, setReajuste] = useState(false);
  const [reducir, setReducir] = useState(false);
  const [limpiar, setLimpiar] = useState(false);

  //MODAL
  const [vermodal, setvermodal] = useState(false);
  const [dispotivo, setdispositivo] = useState([]); //ayuda a identificar si es wc, hc o wm, hm
  const [opclick, setopclick] = useState("");
  const [primeracarga, setprimeracarga] = useState(true);
  const [param, setparam] = useState({
    pedido: null,
    ayudantes: null
  });
  const [opmodal, setopmodal] = useState({
    pdf: { wc: 90, hc: 90, wm: 90, hm: 70, t: "Ver pdf" }
  });
  //FIN MODAL

  useEffect(() => {
    console.log("entre en primera carga", !primeracarga, opclick !== "");
    if (!primeracarga && opclick !== "") {
      console.log("entre despues de la primera carga");
      setdispositivo(esComputadora ? ["wc", "hc"] : ["wm", "hm"]);
      setvermodal(true);
    }
  }, [opclick, esComputadora]);

  useEffect(() => {
    const llamado = async () => {
      try {
        const maestros = await api.listarMaestros(null, "S");
        if (maestros && maestros.SMListaErrores.length === 0) {
          setListaMaestro(maestros.maestro);
        } else {
          alert("Hubo un error. Se recargará la página.");
          window.location.reload();
        }
        const productos = await api.listaProductoMaestro(null, "S", "S", "N", "S");
        if (productos && productos.SMListaErrores.length === 0) {
          console.log(productos);
          setListaProductos(productos.productos);
        } else {
          alert("Hubo un error. Se recargará la página.");
          //window.location.reload()
          console.log(productos)
        }
      } catch (error) {
        alert("Hubo un problema con la conexión. Se recargará la página.");
        //window.location.reload()
      }
    };
    setprimeracarga(false);
    llamado();
  }, []);

  const cerrarModal = async () => {
    setvermodal(false);
  };

  const ajustarStockEvento = async event => {
    if (reajuste) {
      //Guarda los cambio
      setEditable(false);
      setReajuste(false);
      alert("Se guardaron los cambios exitosamente Se recargará la página.");
      window.location.reload();
    } else {
      //Abre los inputs
      setEditable(true);
      setReajuste(true);
    }
  };

  const reducirStockEvento = async event => {
    if (reducir) {
      //Guarda los cambio
      setEditable(false);
      setReducir(false);
      const guardado = await api.guardarAjusteStock();
      if (guardado && guardado.SMListaErrores.length === 0) {
        alert("Se guardaron los cambios exitosamente. Se recargará la página.");
      } else {
        alert("Hubo un error. Se recargará la página.");
      }
      window.location.reload();
    } else {
      //Abre los inputs
      setEditable(true);
      setReducir(true);
    }
  };

  const salgodeinput = async e => {
    const cantidad = e.currentTarget.value === "" ? 0 : e.currentTarget.value;
    const producto = e.currentTarget.parentNode.parentNode.id;
    if (reducir) {
      //funcion 1
      console.log("reducir la cantidad" + cantidad + "-" + producto);
      const actualizarStock = await api.ajusteStock(1, parseInt(producto), parseFloat(cantidad));
      if (actualizarStock && actualizarStock.SMListaErrores.length === 0) {
        console.log(actualizarStock);
        let total = document.getElementById(actualizarStock.producto.producto + "S");
        total.innerHTML = actualizarStock.producto.stock;
      } else {
        alert("Hubo un error. Se recargará la página.");
        //window.location.reload()
      }
    } else if (reajuste) {
      //funcion 2
      console.log("reajustar la cantidad" + cantidad + "-" + producto);
      const actualizarStock = await api.ajusteStock(2, parseInt(producto), parseFloat(cantidad));
      if (actualizarStock && actualizarStock.SMListaErrores.length === 0) {
        console.log(actualizarStock);
        let total = document.getElementById(actualizarStock.producto.producto + "S");
        total.innerHTML = actualizarStock.producto.stock;
      } else {
        alert("Hubo un error. Se recargará la página.");
        //window.location.reload()
      }
    }
  };
  const clickVerPDF = event => {
    setvermodal(true);
    setopclick(!primeracarga ? "pdf" : "");
  };

  return (
    <>
      {vermodal && dispotivo.length > 0 && (
        <ModalGenerico capa={1} width={opmodal[opclick][dispotivo[0]]} height={opmodal[opclick][dispotivo[1]]} titulo={opmodal[opclick].t} cerrarmodal={cerrarModal}>
          {["pdf"].includes(opclick) && <StockPdf cerrarmodal={cerrarModal} param={param} />}
        </ModalGenerico>
      )}
      <e.Contenido $escomputadora={esComputadora}>
        <div className='cabecera'>Producción</div>
        <div className='filtro'>
          <div>
            <Opciones
              id='opcionMaestros'
              formPersona='entra'
              alto='55px'
              moderno='Maestros'
              tipo='text'
              fontSize='0.9em'
              opciones={listaMaestro || []}
              clave='usuario'
              valor={["nombre", "apellido_paterno"]}
              esComputadora={esComputadora}
              onClick={e => setMaestro(e)}
              estilos={{
                backgroundColor: "#EFEFEF4D",
                border: "2px solid #bababa"
              }}
            />
          </div>
          <div className='filtroopciones'>
            <div>
              <Input
                style={{}}
                textoStyle={{}}
                id='fproducto'
                moderno={"Producto"}
                tipo='text'
                onChange={e => {
                  // if (e.currentTarget.value === '') {
                  //   salgodeinput(e)
                  // }
                }}
                onBlur={()=>{}}
                valorInicial={""}
                esComputadora={esComputadora}
                //limpiar={fnomape === '' ? true: false}
              />
            </div>
            <div>
              <Input
                style={{}}
                textoStyle={{}}
                id='fcategoria'
                moderno={"Categoria"}
                tipo='text'
                onChange={e => {
                  //setfnomape(e.currentTarget.value.toUpperCase().trim())
                  //filtrosNomApe(e.currentTarget.value)
                }}
                valorInicial={""}
                esComputadora={esComputadora}
                //limpiar={fnomape === '' ? true: false}
              />
            </div>
            <div>
              <Input
                style={{}}
                textoStyle={{}}
                id='fstock'
                moderno={"Stock > a"}
                tipo='number'
                onChange={e => {
                  //setfnomape(e.currentTarget.value.toUpperCase().trim())
                  //filtrosNomApe(e.currentTarget.value)
                }}
                valorInicial={""}
                esComputadora={esComputadora}
                //limpiar={fnomape === '' ? true: false}
              />
            </div>
            <div
              style={{
                padding: "0.1vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <Boton
                style={{
                  backgroundColor: "#E12121",
                  borderRadius: esComputadora ? "0.5vw" : "1vw",
                  padding: esComputadora ? "0.8vw" : "1vw"
                }}
                textoStyle={{
                  color: "white",
                  width: "100%",
                  fontSize: esComputadora ? "1vw" : "2.75vw",
                  padding: "0"
                }}
                texto='Limpiar Filtros'
                tipo='submit'
                estaProcesando={false}
                onClick={event => {
                  //event.preventDefault()
                  //setfnomape('')
                  //setfndoc('')
                  //setfusuario('')
                }}
              />
            </div>
          </div>
        </div>
        <div className='tabla'>
          {listaProductos.length > 0 &&
            listaProductos.map(e => (
              <div className='categoria' key={e.categoria.descripcion}>
                <div className='cabecera'>{e.categoria.descripcion}</div>
                <div className='cuerpo'>
                  <div className='descripcion'>
                    <div>Producto</div>
                    <div>Coches</div>
                    <div>Latas</div>
                    <div>Prod.x Latas</div>
                    <div>Paquetes</div>
                    <div>Stock</div>
                    <div>Actualizar Stock</div>
                  </div>
                  <div className='detallePedido'>
                    {e.productos.length > 0 &&
                      e.productos.map(p => (
                        <div className='productos' id={p.producto} key={p.producto}>
                          <div>{fg.capitalizar_palabra(p.descripcion)}</div>
                          <div>{p.coches}</div>
                          <div>{p.latas}</div>
                          <div>{p.canProductos}</div>
                          <div>{p.paquetes}</div>
                          <div id={p.producto + "S"}>{p.stock}</div>
                          <Input
                            tipo='number'
                            maxDigitos='4'
                            onChange={e => {
                              if (e.currentTarget.value === "" && !estaProcesando) {
                                salgodeinput(e);
                              }
                            }}
                            onBlur={(!estaProcesando || editable) && salgodeinput}
                            valorInicial={p.stockvariado}
                            esComputadora={esComputadora}
                            deshabilitado={!editable}
                            limpiar={limpiar}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='opciones'>
          <div className='botonOpciones'>
            <Boton
              style={{
                backgroundColor: "#E12121",
                borderRadius: esComputadora ? "0.5vw" : "1vw",
                padding: esComputadora ? "0.8vw" : "1vw"
              }}
              textoStyle={{
                color: "white",
                width: "100%",
                fontSize: esComputadora ? "1.3vw" : "2.75vw",
                padding: "0"
              }}
              texto='Ver PDF'
              tipo='submit'
              estaProcesando={estaProcesando}
              deshabilitado={reducir || reajuste}
              onClick={clickVerPDF}
            />
          </div>
          {rol === "SUPER ADMINISTRADOR" && (
            <div className='botonOpciones'>
              <Boton
                style={{
                  backgroundColor: "rgb(220, 173, 47)",
                  borderRadius: esComputadora ? "0.5vw" : "1vw",
                  padding: esComputadora ? "0.8vw" : "1vw"
                }}
                Promise
                textoStyle={{
                  color: "black",
                  width: "100%",
                  fontSize: esComputadora ? "1.3vw" : "2.75vw",
                  padding: "0"
                }}
                texto='Reajustar Stock'
                tipo='submit'
                estaProcesando={estaProcesando}
                deshabilitado={reducir}
                onClick={event => {
                  ajustarStockEvento(event);
                  //event.preventDefault()
                  //setfnomape('')
                  //setfndoc('')
                  //setfusuario('')
                }}
              />
            </div>
          )}
          <div className='botonOpciones'>
            <Boton
              style={{
                backgroundColor: "rgb(45, 139, 11)",
                borderRadius: esComputadora ? "0.5vw" : "1vw",
                padding: esComputadora ? "0.8vw" : "1vw"
              }}
              Promise
              textoStyle={{
                color: "white",
                width: "100%",
                fontSize: esComputadora ? "1.3vw" : "2.75vw",
                padding: "0"
              }}
              texto='Reducir Stock'
              tipo='submit'
              estaProcesando={estaProcesando}
              deshabilitado={reajuste}
              onClick={event => {
                reducirStockEvento(event);
                //generarPedidoEvento(event)
                //event.preventDefault()
                //setfnomape('')
                //setfndoc('')
                //setfusuario('')
              }}
            />
          </div>
        </div>
      </e.Contenido>
    </>
  );
}

export default StockProducciones;
