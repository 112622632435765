import styled from 'styled-components';

// Contenedores principales
export const Contenido = styled.div`
  grid-area: 1/1/21/21;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);

  .cabecera {
    grid-area: 1/1/2/21;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(173, 103, 38);
    font-size: ${props => (props.$escomputadora ? '2' : '4')}vw;
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;
  }
  
  .filtro {
    grid-area: 2/1/3/21;
    display: grid;
    grid-template-columns: 50% 30% 20%;
    grid-template-rows: 100%;
    background-color: rgb(250, 250, 250);
    box-shadow: rgb(129, 129, 129) 0px 0px 0.3vw inset;
    padding: 0.5vw;
    box-sizing: border-box;
  }

`;
