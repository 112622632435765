import { useEffect, useState, useContext, useCallback } from 'react'
import { Contexto } from '../../../index'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as e from './AEProductoProdCSS'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'

import * as api from './../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'
import { categoriaAEProdStyle } from '../Productos/AdministrarProductosCSS'

function AEProductProd (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [categoriasop, setcategoriasop] = useState(null)
  const [datosproducto, setdatosproducto] = useState({
    codcat: '',
    nomprod: '',
    cantCoch: '',
    cantLat: '',
    cantProd: '',
    cantPaq: '',
    precProd: ''
  })

  useEffect(() => {
    console.log(props)

    const ejecucion = async () => {
      const categoriascall = await api.obtenerCategorias('N')
      if (categoriascall) {
        if (categoriascall.SMListaErrores.length === 0) {
          setcategoriasop(categoriascall.categorias)
        } else {
          alert(
            'Ocurrió un error al cargar la información, comunicate con soporte técnico'
          )
        }
      }
      if (['mpp'].includes(props.modo)) {
        const datoscall = await api.obtenerDetalleProducto(
          parseInt(props.param.codigoprod),
          null
        )
        if (datoscall) {
          if (datoscall.SMListaErrores.length === 0) {
            setdatosproducto(dp => ({
              ...dp,
              codcat: datoscall.codCategoria,
              nomprod: datoscall.produccion.descripcion,
              cantCoch: datoscall.produccion.coches,
              cantLat: datoscall.produccion.latas,
              cantProd: datoscall.produccion.canProductos,
              cantPaq: datoscall.produccion.paquetes,
              precProd: datoscall.produccion.precio
            }))
          }
        }
      }
    }
    ejecucion()
  }, [])

  const asignarvalor = e => {
    let temp = e.target
    temp.removeAttribute('vacio')
    if (temp.id !== 'nomprod') {
      setdatosproducto(dp => ({
        ...dp,
        [temp.id]: parseFloat(temp.value || 0)
      }))
    } else {
      setdatosproducto(dp => ({
        ...dp,
        [temp.id]: temp.value
      }))
    }
  }
  return (
    <>
      <e.Contenido id='algonousual' $escomputadora={esComputadora}>
        <div className='nomprodparam'>
          {['mpp'].includes(props.modo) &&
            'Editando - ' + props.param.nombreprod}
          {['app'].includes(props.modo)}
        </div>
        <div className='categoriai input'>
          <Opciones
            valorInicial={datosproducto.codcat}
            id='codcat'
            formPersona='entra'
            moderno='Categoria'
            opciones={categoriasop !== null ? categoriasop : []}
            clave='codCategoria'
            valor={['descCategoria']}
            esComputadora={esComputadora}
            onClick={e => {
              setdatosproducto(dp => ({
                ...dp,
                codcat: parseInt(e)
              }))
            }}
            onChange={e => {
              asignarvalor(e)
            }}
          />
        </div>
        <div className='nombreprodi input'>
          <Input
            id='nomprod'
            moderno={'Nombre'}
            tipo='text'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datosproducto.nomprod}
            esComputadora={esComputadora}
          />
        </div>
        <div className='cantcochesin input'>
          <Input
            id='cantCoch'
            moderno={'Cantidad de Coches'}
            tipo='number'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datosproducto.cantCoch}
            esComputadora={esComputadora}
          />
        </div>
        <div className='cantlatasin input'>
          <Input
            id='cantLat'
            moderno={'Cantidad de Latas'}
            tipo='number'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datosproducto.cantLat}
            esComputadora={esComputadora}
          />
        </div>
        <div className='cantprodlatin input'>
          <Input
            id='cantProd'
            moderno={'Cant. Prod x Latas'}
            tipo='number'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datosproducto.cantProd}
            esComputadora={esComputadora}
          />
        </div>
        <div className='cantpaqin input'>
          <Input
            id='cantPaq'
            moderno={'Cant. de Paquetes'}
            tipo='number'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datosproducto.cantPaq}
            esComputadora={esComputadora}
          />
        </div>
        <div className='precioprodin input'>
          <Input
            id='precProd'
            moderno={'Precio Producción'}
            tipo='number'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datosproducto.precProd}
            esComputadora={esComputadora}
          />
        </div>
        <div className='aceptarboton input'>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Aceptar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={e => {
              const llamado = async () => {
                let paramValidos = true
                Object.keys(datosproducto).forEach(d => {
                  const etiqueta = document.getElementById(d)
                  if (['', '0', 0].includes(datosproducto[d])) {
                    etiqueta.setAttribute('vacio', 'vacio')
                    paramValidos = false
                  } else {
                    etiqueta.removeAttribute('vacio')
                  }
                })
                if (paramValidos) {
                  console.log(['app'].includes(props.param.modo))
                  const modificar = await api.registrarProducto(
                    ['app'].includes(props.modo) ? parseInt(0):parseInt(props.param.codigoprod),
                    null,
                    datosproducto.codcat,
                    datosproducto.nomprod,
                    0,
                    0,
                    0,
                    0,
                    'S',
                    1,
                    'S',
                    datosproducto.precProd,
                    datosproducto.cantCoch,
                    datosproducto.cantLat,
                    datosproducto.cantProd,
                    datosproducto.cantPaq
                  )
                  if (modificar) {
                    if (modificar.SMListaErrores.length === 0) {
                      props.cerrarmodal()
                    } else {
                      console.log(modificar)
                      alert('Fallo actualizando el producto')
                    }
                  }
                } else {
                  alert('Es necesario llenar los campos marcados en rojo')
                }
              }
              llamado()

              //setTipoAccion(e.currentTarget.id)
            }}
          />
        </div>
        <div className='cancelarboton input'>
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Cancelar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={e => {
              //setTipoAccion(e.currentTarget.id)
              props.cerrarmodal()
            }}
          />
        </div>
      </e.Contenido>
    </>
  )
}

export default AEProductProd
