import React, { useState, useEffect } from "react";
import despacho from "./../../../Imagenes/Iconos/despacho.png";
import generarPedido from "./../../../Imagenes/Iconos/generarPedido.png";
import informacionGeneral from "./../../../Imagenes/Iconos/informacionGeneral.png";
import pedidos from "./../../../Imagenes/Iconos/pedidos.png";
import stock from "./../../../Imagenes/Iconos/stock.png";
import ventaTienda from "./../../../Imagenes/Iconos/ventaTienda.png";
import menuOpciones from "./../../../Imagenes/Iconos/menuOpciones.png";
import asistencia from "./../../../Imagenes/Iconos/asistencia.png";
import almacen from "./../../../Imagenes/Iconos/almacen.png";
import produccion from "./../../../Imagenes/Iconos/produccion.png";

import { obtenerCookie } from "../../../Api/Api";
import Boton from "./../../../Elementos/Boton";
import "./MenuOpciones.css";

function MenuOpciones(param) {
  const [estaProcesando, setestaProcesando] = useState(false);
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  );
  const [isHovered, setIsHovered] = useState(false);
  const [idHovered, setIdHovered] = useState("");
  const [idSeleccionado, setIdSeleccionado] = useState(null);
  const [rol, setRol] = useState(
    obtenerCookie("descRol") !== null
      ? obtenerCookie("descRol").toUpperCase().trim()
      : ""
  );
  const [subMenuHabilitar, setSubMenuHabilitar] = useState(false);
  const [idSubMenuHabilitar, setIdSubMenuHabilitar] = useState(null);
  const [opcionesMenuSelec, setOpcionesMenuSelec] = useState("");
  const [opcionesMenuSelecTitulo, setOpcionesMenuSelectTitulo] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function hexToRGBA(hex, opacidad) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacidad})`;
  }
  const contenedorOpcionesStyle = {
    display: "grid",
    gridTemplateRows: esComputadora ? "repeat(5, 1fr)" : "10% 100%",
    gridTemplateColumns: esComputadora
      ? "repeat(1, 1fr) 160px"
      : "repeat(10, 1fr)",
    width: esComputadora ? "5vw" : "100vw",
    height: esComputadora ? "" : "10vh",
    boxSizing: "content-box",
    alignSelf: "center",
    justifySelf: "center",
    textAlign: "center",
    position: "relative",
  };
  const propiedadesAdicionalesIconos = {
    backgroundColor: hexToRGBA("#AD6726", 1.0),
    width: esComputadora ? "5vw" : "100%",
    height: esComputadora ? "15vh" : "10vh",
    boxSizing: "border-box",
    margin: "0px",
  };
  const estiloHover = {
    ...(isHovered && {
      cursor: "pointer",
      backgroundColor: hexToRGBA("#DCAD2F", 1.0),
      boxShadow: "inset 0 0 4.4vw #FFFFFF",
      ...(!esComputadora && {
        borderBottom: esComputadora
          ? "0.5vw solid #603711"
          : "1vw solid #603711",
      }),
      ...(esComputadora && {
        borderLeft: esComputadora ? "0.5vw solid #603711" : "1vw solid #603711",
      }),
    }),
  };
  const estiloSeleccionado = {
    cursor: "pointer",
    backgroundColor: hexToRGBA("#DCAD2F", 1.0),
    boxShadow: esComputadora
      ? "inset 0 0 2.2vw #FFFFFF"
      : "inset 0 0 4.4vw #FFFFFF",
    ...(!esComputadora && {
      borderBottom: esComputadora ? "0.5vw solid #603711" : "1vw solid #603711",
    }),
    ...(esComputadora && {
      borderLeft: esComputadora ? "0.5vw solid #603711" : "1vw solid #603711",
    }),
  };
  const botoMenuContextostyle = {
    backgroundColor: "red",
    gridArea: esComputadora ? "1 / 1 / 4 / 2" : "1 / 1 / 2 / 3",
    ...propiedadesAdicionalesIconos,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const pedidosstyle = {
    display: ["JEFE DE EMPAQUETADO", "ALMACENERO"].includes[rol]
      ? "none"
      : "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora
      ? ["SUPER ADMINISTRADOR"].includes(rol)
        ? "4 / 1 / 5 / 2"
        : "2 / 1 / 4 / 2"
      : ["DISTRIBUIDOR DE PRODUCTOS", "AYUDANTE DE DISTRIBUIDOR"].includes(rol)
      ? "1 / 6 / 2 / 8"
      : ["DESPACHADOR"].includes(rol)
      ? "1 / 1 / 2 / 3"
      : "1 / 5 / 2 / 7",
    ...propiedadesAdicionalesIconos,
    ...([
      rol === "SUPER ADMINISTRADOR" ? "despachoDePedidos" : "produccion",
    ].includes(idHovered)
      ? estiloHover
      : ""),
    ...([
      rol === "SUPER ADMINISTRADOR" ? "despachoDePedidos" : "produccion",
    ].includes(idHovered)
      ? estiloSeleccionado
      : ""),
  };
  const generarPedidostyle = {
    display: ["DISTRIBUIDOR DE PRODUCTOS", "AYUDANTE DE DISTRIBUIDOR"].includes(
      rol
    )
      ? "flex"
      : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora ? "3 / 1 / 4 / 2" : "1 / 4 / 2 / 6",
    height: esComputadora ? "" : "10vh",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === "generarPedido" ? estiloHover : ""),
    ...(idSeleccionado === "generarPedido" ? estiloSeleccionado : ""),
  };
  const despachostyle = {
    display: [
      "DISTRIBUIDOR DE PRODUCTOS",
      "AYUDANTE DE DISTRIBUIDOR",
      "JEFE DE EMPAQUETADO",
    ].includes(rol)
      ? "none"
      : "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora
      ? ["SUPER ADMINISTRADOR"].includes(rol)
        ? "5 / 1 / 6 / 2"
        : ["ALMACENERO"].includes(rol)
        ? "2/1/3/2"
        : "4 / 1 / 5 / 2"
      : ["SUPER ADMINISTRADOR"].includes(rol)
      ? "1 / 7 / 2 / 9"
      : "1 / 5 / 2 / 7",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === "registroDeVentas" ? estiloHover : ""),
    ...(idSeleccionado === "registroDeVentas" ? estiloSeleccionado : ""),
  };
  const ventaTiendastyle = {
    display: [
      "DISTRIBUIDOR DE PRODUCTOS",
      "AYUDANTE DE DISTRIBUIDOR",
      "SUPER ADMINISTRADOR",
      "JEFE DE EMPAQUETADO",
      "ALMACENERO",
    ].includes(rol)
      ? "none"
      : "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora ? "5 / 1 / 6 / 2" : "1 / 7 / 2 / 9",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === "ventaTienda" ? estiloHover : ""),
    ...(idSeleccionado === "ventaTienda" ? estiloSeleccionado : ""),
  };
  const stockstyle = {
    display: [
      "DISTRIBUIDOR DE PRODUCTOS",
      "AYUDANTE DE DISTRIBUIDOR",
      "ALMACENERO",
    ].includes(rol)
      ? "none"
      : "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora ? "6 / 1 / 7 / 2" : "1 / 9 / 2 / 11",
    ...propiedadesAdicionalesIconos,
    ...(["produccion", "stockProductos"].includes(idHovered)
      ? estiloHover
      : ""),
    ...(["produccion", "stockProductos"].includes(idSeleccionado)
      ? estiloSeleccionado
      : ""),
  };
  const iconostyle = {
    width: esComputadora ? "60%" : "30%",
  };
  const descripcionIconoStyle = {
    fontWeight: "bolder",
    fontSize: esComputadora ? "0.8vw" : "2vw",
    margin: "0px",
    padding: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const personal = {
    width: "20%",
  };
  const informacionGeneralstyle = {
    display: ["SUPER ADMINISTRADOR"].includes(rol) ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora ? "2 / 1 / 4 / 2" : "1 / 1 / 3 / 3",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === "informacionGeneral" ? estiloHover : ""),
    ...(idSeleccionado === "informacionGeneral" ? estiloSeleccionado : ""),
  };
  const generarPedidoDistribuidorstyle = {
    display: ["DESPACHADOR", "SUPER ADMINISTRADOR"].includes(rol)
      ? "flex"
      : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gridArea: esComputadora ? "3 / 1 / 4 / 2" : "1 / 3 / 2 / 5",
    ...propiedadesAdicionalesIconos,
    ...(idHovered === "generarPedidoDistribuidor" || idHovered === "asistencias"
      ? estiloHover
      : ""),
    ...(idSeleccionado === "generarPedidoDistribuidor" ||
    idSeleccionado === "asistencias"
      ? estiloSeleccionado
      : ""),
  };
  const manejadorOpcionClick = async (event) => {
    console.log(event.currentTarget.id);
    setOpcionesMenuSelec(event.currentTarget.id);
    setOpcionesMenuSelectTitulo(
      event.currentTarget.children[1].innerHTML + " Opciones"
    );
    if (
      !["informacionGeneral"].includes(event.currentTarget.id) &&
      (["DISTRIBUIDOR DE PRODUCTOS","AYUDANTE DE DISTRIBUIDOR", "DESPACHADOR"].includes(rol) ||
        !["despachoDePedidos"].includes(event.currentTarget.id)) &&
      (["DESPACHADOR"].includes(rol) ||
        !["registroDeVentas"].includes(event.currentTarget.id)) &&
      (["DESPACHADOR"].includes(rol) ||
        !["produccion"].includes(event.currentTarget.id))
    ) {
      param.establecerOpcion(event.currentTarget.id);
      setIdHovered(event.currentTarget.id);
      setIsHovered(true);
      setIdSeleccionado(event.currentTarget.id);
      setSubMenuHabilitar(false);
    } else {
      if (subMenuHabilitar) {
        if (opcionesMenuSelec === event.currentTarget.id)
          setSubMenuHabilitar(false);
      } else {
        setSubMenuHabilitar(true);
        setIdSubMenuHabilitar(event.currentTarget.id);
      }
    }
  };
  const subopInfGenStyle = {
    display: subMenuHabilitar ? "flex" : "none",
    gridArea: esComputadora ? "2/2/7/3" : "1/1/2/4",
    zIndex: "10",
    backgroundColor: "#F1EDE3",
    position: esComputadora ? "absolute" : "absolute",
    height: esComputadora ? "75vh" : "15.5vh",
    top: esComputadora ? "" : "-15.5vh",
    left: esComputadora ? "" : "1vw",
    padding: esComputadora ? "1vw" : "2vw",
    boxSizing: "border-box",
    borderRadius: esComputadora ? "0 1vw 1vw 0" : "2vw 2vw 0 0",
    boxShadow: `inset 0 0 8px ${hexToRGBA("#000000", 0.75)}`,
    width: esComputadora ? "14vw" : "98vw",
    flexDirection: "column",
  };
  const subopInfContGenStyle = {
    display: subMenuHabilitar ? "flex" : "none",
    gridArea: esComputadora ? "2/2/7/3" : "1/1/2/4",

    zIndex: "10",
    boxSizing: "border-box",
    overflow: "auto",
    flexDirection: param.esComputadora ? "column" : "row",
    gap: param.esComputadora ? "2% 1%" : "5% 25%",
    flexWrap: "wrap",
    justifyContent: param.esComputadora ? "flex-start" : "center",
    alignItems: "center",
    //alignContent: 'center',
    //backgroundColor: "green",
    overflow: "auto",
    height: param.esComputadora ? "95%" : "80%",
    width: "100%",
    //paddingLeft: "10%"
  };
  const elementoOpStyle = {
    width: param.esComputadora ? "100%" : "auto",
    display: param.esComputadora ? "block" : "inline-block",
  };
  const botonElementoStyle = {
    backgroundColor: "#FFFFFF",
    borderRadius: param.esComputadora ? "0.5vw" : "1vw",
    padding: param.esComputadora ? "0.8vw" : "2vw",
    border: "0.05vw solid black",
    width: param.esComputadora ? "100%" : "30vw",
    marginTop: "5px",
  };
  const subOpcionClick = (event) => {
    console.log(event.currentTarget.id);
    param.establecerOpcion(event.currentTarget.id);
    setIdSeleccionado(idSubMenuHabilitar);
    setSubMenuHabilitar(false);
  };
  const productosClick = (event) => {
    console.log(event.currentTarget.id);
    param.establecerOpcion(event.currentTarget.id);
    setIdSeleccionado(idSubMenuHabilitar);
    setSubMenuHabilitar(false);
  };
  return (
    <>
      <div id="contenedorOpciones" style={contenedorOpcionesStyle}>
        {esComputadora && (
          <div id="botoMenuContexto" style={botoMenuContextostyle}>
            <img src={menuOpciones} alt="Logo" style={personal} />
          </div>
        )}
        {
          <div
            id={
              rol === "JEFE DE PRODUCCION" ? "produccion" : "despachoDePedidos"
            }
            style={pedidosstyle}
            onMouseEnter={(event) => {
              setIsHovered(true);
              setIdHovered(event.target.id);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
              setIdHovered("");
            }}
            onClick={manejadorOpcionClick}
          >
            <img
              src={
                [
                  "DISTRIBUIDOR DE PRODUCTOS",
                  "AYUDANTE DE DISTRIBUIDOR",
                  "SUPER ADMINISTRADOR",
                ].includes(rol)
                  ? pedidos
                  : despacho
              }
              alt="Logo"
              style={iconostyle}
            />
            <p style={descripcionIconoStyle}>
              {[
                "DISTRIBUIDOR DE PRODUCTOS",
                "AYUDANTE DE DISTRIBUIDOR",
              ].includes(rol)
                ? "Lista de Pedidos"
                : ["SUPER ADMINISTRADOR"].includes(rol)
                ? "Ventas"
                : ["JEFE DE PRODUCCION"].includes(rol)
                ? "Producción"
                : "Despacho de Pedidos"}
            </p>
          </div>
        }
        <div
          id="generarPedido"
          style={generarPedidostyle}
          onMouseEnter={(event) => {
            setIsHovered(true);
            setIdHovered(event.target.id);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            setIdHovered("");
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={generarPedido} alt="Logo" style={iconostyle} />
          <p style={descripcionIconoStyle}>Generar Pedido</p>
        </div>
        {rol !== "JEFE DE PRODUCCION" && (
          <div
            id="registroDeVentas"
            style={despachostyle}
            onMouseEnter={(event) => {
              setIsHovered(true);
              setIdHovered(event.target.id);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
              setIdHovered("");
            }}
            onClick={manejadorOpcionClick}
          >
            <img
              src={
                ["SUPER ADMINISTRADOR", "ALMACENERO"].includes(rol)
                  ? almacen
                  : pedidos
              }
              alt="Logo"
              style={iconostyle}
            />
            <p style={descripcionIconoStyle}>
              {["SUPER ADMINISTRADOR"].includes(rol)
                ? "Almacén"
                : ["DESPACHADOR"].includes(rol)
                ? "Registro de Ventas"
                : ["ALMACENERO"].includes(rol)
                ? "Almacén"
                : ""}
            </p>
          </div>
        )}
        {rol !== "JEFE DE PRODUCCION" && (
          <div
            id="ventaTienda"
            style={ventaTiendastyle}
            onMouseEnter={(event) => {
              setIsHovered(true);
              setIdHovered(event.target.id);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
              setIdHovered("");
            }}
            onClick={manejadorOpcionClick}
          >
            <img src={ventaTienda} alt="Logo" style={iconostyle} />
            <p style={descripcionIconoStyle}>Venta en Tienda</p>
          </div>
        )}
        {rol !== "JEFE DE PRODUCCION" && (
          <div
            id={
              ["SUPER ADMINISTRADOR", "JEFE DE PRODUCCION"].includes(rol)
                ? "produccion"
                : "stockProductos"
            } // condicion logica ? bloque true : bloque false;
            style={stockstyle}
            onMouseEnter={(event) => {
              setIsHovered(true);
              setIdHovered(event.target.id);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
              setIdHovered("");
            }}
            onClick={manejadorOpcionClick}
          >
            <img
              src={["SUPER ADMINISTRADOR"].includes(rol) ? produccion : stock}
              alt="Logo"
              style={iconostyle}
            />
            <p style={descripcionIconoStyle}>
              {["SUPER ADMINISTRADOR"].includes(rol)
                ? "Producción"
                : "stockProductos"}
            </p>
          </div>
        )}

        <div
          id="informacionGeneral"
          style={informacionGeneralstyle}
          onMouseEnter={(event) => {
            setIsHovered(true);
            setIdHovered(event.target.id);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            setIdHovered("");
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={informacionGeneral} alt="Logo" style={iconostyle} />
          <p style={descripcionIconoStyle}>Información General</p>
        </div>

        <div
          id={
            ["SUPER ADMINISTRADOR"].includes(rol)
              ? "asistencias"
              : "generarPedidoDistribuidor"
          }
          style={generarPedidoDistribuidorstyle}
          onMouseEnter={(event) => {
            setIsHovered(true);
            setIdHovered(event.target.id);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
            setIdHovered("");
          }}
          onClick={manejadorOpcionClick}
        >
          <img
            src={
              ["SUPER ADMINISTRADOR"].includes(rol) ? asistencia : generarPedido
            }
            alt="Logo"
            style={iconostyle}
          />
          <p style={descripcionIconoStyle}>
            {["SUPER ADMINISTRADOR"].includes(rol)
              ? "Asistencias"
              : "Generar Pedido Distribuidor"}
          </p>
        </div>
        {/*SUB MENÚ*/}
        <div
          id="subopInfGen"
          style={{
            ...subopInfGenStyle,
          }}
        >
          <div
            style={{
              display: "flex",
              height: param.esComputadora ? "10%" : "20%",
              fontSize: param.esComputadora ? "0.9vw" : "2.5vw",
              fontWeight: "700",
              //padding: "0.5vw",
              color: "white",
              backgroundColor: "#ad6726",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: param.esComputadora ? "0.5vw" : "1vw",
            }}
          >
            {opcionesMenuSelecTitulo}
          </div>
          <div
            style={{
              ...subopInfContGenStyle,
            }}
          >
            {opcionesMenuSelec === "informacionGeneral" && (
              <>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Personal
                  </div>

                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Personal"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="personalig" //'crearPersona'
                  />
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Preventistas"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="preventistaig"
                  />
                </div>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración General
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Empresas"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="empresasig"
                  />
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Marcas"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="marcasig"
                  />
                </div>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Productos
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Productos Terminados"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="administrarProductos"
                  />
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Productos de Producción"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="productosproduccionig"
                  />
                </div>

                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Insumos
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Insumos"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="insumosig"
                  />
                </div>
              </>
            )}
            {opcionesMenuSelec === "despachoDePedidos" && (
              <>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Pedidos
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Lista de Pedidos"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="despachoDePedidos"
                  />
                </div>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Ventas
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Registro de Ventas"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="registroDeVentas"
                  />
                </div>
                {rol === "SUPER ADMINISTRADOR" && (
                  <>
                    <div style={elementoOpStyle}>
                      <div
                        style={{
                          fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                          fontWeight: "bolder",
                        }}
                      >
                        Administración de Productos
                      </div>
                      <Boton
                        style={botonElementoStyle}
                        textoStyle={{
                          color: "white",
                          width: "100%",
                          fontSize: param.esComputadora ? "1vw" : "2.5vw",
                          color: "black",
                          fontWeight: "bolder",
                          fontSize: esComputadora ? "0.7vw" : "2vw",
                          lineHeight: "0.9",
                        }}
                        texto="Stock de Productos"
                        tipo="submit"
                        tonalidad="20"
                        estaProcesando={estaProcesando}
                        onClick={productosClick}
                        id="stockProductos"
                      />
                    </div>
                    <div style={elementoOpStyle}>
                      <div
                        style={{
                          fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                          fontWeight: "bolder",
                        }}
                      >
                        Boletas y Facturas
                      </div>
                      <Boton
                        style={botonElementoStyle}
                        textoStyle={{
                          color: "white",
                          width: "100%",
                          fontSize: param.esComputadora ? "1vw" : "2.5vw",
                          color: "black",
                          fontWeight: "bolder",
                          fontSize: esComputadora ? "0.7vw" : "2vw",
                          lineHeight: "0.9",
                        }}
                        texto="Registro de Comprobantes"
                        tipo="submit"
                        tonalidad="20"
                        estaProcesando={estaProcesando}
                        onClick={productosClick}
                        id="comprobantes"
                      />
                    </div>
                    <div style={elementoOpStyle}>
                      <div
                        style={{
                          fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                          fontWeight: "bolder",
                        }}
                      >
                        Guias de Remision
                      </div>
                      <Boton
                        style={botonElementoStyle}
                        textoStyle={{
                          color: "white",
                          width: "100%",
                          fontSize: param.esComputadora ? "1vw" : "2.5vw",
                          color: "black",
                          fontWeight: "bolder",
                          fontSize: esComputadora ? "0.7vw" : "2vw",
                          lineHeight: "0.9",
                        }}
                        texto="Registro de Guias de Remision"
                        tipo="submit"
                        tonalidad="20"
                        estaProcesando={estaProcesando}
                        onClick={productosClick}
                        id="guias"
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {opcionesMenuSelec === "registroDeVentas" && (
              <>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Ingresos
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Lista de Ingresos"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="listaingresosalmacen"
                  />
                  <Boton
                    style={{
                      ...botonElementoStyle,
                      display: !["ALMACENERO"].includes(rol) ? "none" : "",
                    }}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Generar Pedido Insumos"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="generarpedidoinsumo"
                  />
                </div>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Salidas
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Lista de Salidas"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="listasalidasalmacen"
                  />
                  <Boton
                    style={{
                      ...botonElementoStyle,
                      display: !["ALMACENERO"].includes(rol) ? "none" : "",
                    }}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Generar Salida Insumos"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="generarsalidainsumos"
                  />
                </div>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Stock
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Stock de Almacén"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="stockalmacen"
                  />
                </div>
              </>
            )}
            {opcionesMenuSelec === "produccion" && (
              <>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Producciones
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      margin: "0px",
                      padding: "0px",
                      lineHeight: "0.9",
                    }}
                    texto="Generar Pedido"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={subOpcionClick}
                    id="generarPedidoProduccion"
                  />
                  <Boton
                    style={{
                      ...botonElementoStyle,
                      //display: !['ALMACENERO'].includes(rol) ? 'none' : ''
                    }}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Lista de Producciones"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="listasProduccion"
                  />
                </div>
                <div style={elementoOpStyle}>
                  <div
                    style={{
                      fontSize: param.esComputadora ? "0.8vw" : "2.3vw",
                      fontWeight: "bolder",
                    }}
                  >
                    Administración de Productos
                  </div>
                  <Boton
                    style={botonElementoStyle}
                    textoStyle={{
                      color: "white",
                      width: "100%",
                      fontSize: param.esComputadora ? "1vw" : "2.5vw",
                      color: "black",
                      fontWeight: "bolder",
                      fontSize: esComputadora ? "0.7vw" : "2vw",
                      lineHeight: "0.9",
                    }}
                    texto="Stock de Productos"
                    tipo="submit"
                    tonalidad="20"
                    estaProcesando={estaProcesando}
                    onClick={productosClick}
                    id="stockProducciones"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuOpciones;
