import styled from 'styled-components'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .ayudanteMaestroClass {
    grid-area: 1/1/2/21;
  }
  .botonAgregar {
    grid-area: 2/1/3/11;
  }
  .botonCancelar {
    grid-area: 2/12/3/21;
  }
`