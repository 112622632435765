import { StyleSheet } from '@react-pdf/renderer'
import styled from 'styled-components'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .PDFContent {
    grid-area: 1/1/20/21;
  }
  iframe.pdf {
    width: 100%;
    height: 100%;
  }

  .PDFContentDownlaod {
    grid-area: 20/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
  }
  .PDFContentDownlaod > a {
    color: white;
    background-color: #2d8b0b;
    text-decoration: none;
    border-radius: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    padding: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    box-sizing: border-box;
    padding: ${props => (props.$escomputadora ? '0.5' : '1')}vw;
  }
  .PDFContentDownlaod > a:hover {
    background-color: rgb(36, 110, 9);
    box-shadow: 0.1vw 0.1vw 0.1vw rgb(116, 116, 116);
    
  }
`;

// ✅ تحسين تنسيق PDF

export const docpdf = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '5px',
    margin: '0',
    display: 'flex',
    flexDirection: 'column'
  },
  headerDocument: {
    flexDirection: 'row',
    margin: '5px 5px 0 5px',
    alignItems: 'center'
  },
  informationDocument: {
    width: '30%',
    fontSize: '1.2vw',
    flexDirection: 'column',
    color: 'grey'
  },
  titleDocument: {
    width: '40%',
    fontSize: '3vw',
    textAlign: 'center'
  },
  logo: {
    width: '30%',
    alignItems: 'flex-end'
  },
  bodyDocument: {
    flexDirection: 'row',
    marginTop: 10,
  },
  fechaHora: {
    fontSize: 10,
    color: '#555',
  },
  titulo: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    flexGrow: 1,
  },

  // ✅ تحسين تنسيق الجدول
  tabla: {
    width: '100%',
    marginTop: 10,
    borderWidth: 1, 
    borderColor: '#000',
  },

  filaEncabezado: {
    flexDirection: 'row',
    backgroundColor: '#A06028',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
  },

  celdaEncabezado: {
    flex: 1,
    borderRightWidth: 1, 
    borderColor: 'white',
    padding: 5,
    fontSize: 10,
  },

  fila: {
    flexDirection: 'row',
    borderBottomWidth: 1, 
    borderColor: '#ddd',
  },

  celda: {
    flex: 1,
    textAlign: 'center',
    padding: 5,
    fontSize: 10,
    borderWidth: 1, 
    borderColor: '#000',
  },

  footer: {
    marginTop: 10,
    fontSize: 10,
    textAlign: 'center',
    color: '#555',
  },
});

//export { docpdf };

  
 

  

