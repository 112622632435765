import styled from 'styled-components'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .cantProductoListClass {
    grid-area: 1/1/2/21;
  }
  .ProductoListClass {
    grid-area: 2/1/3/21;
  }
  .cantProdModalClass {
    grid-area: 3/1/4/21;
  }
  .botonAgregar {
    grid-area: 20/1/21/11;
  }
  .botonCancelar {
    grid-area: 20/12/21/21;
  }

  .cantProductoListClass,
  .ProductoListClass,
  .cantProdModalClass,
  .botonAgregar,
  .botonCancelar {
    padding: 0.3vw;
  }
`
