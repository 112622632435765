import { StyleSheet } from '@react-pdf/renderer'
import styled from 'styled-components'
import { Font } from '@react-pdf/renderer'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .mostrarDetallado {
    grid-area: 1/1/2/21;
  }
  .PDFContent {
    grid-area: 2/1/20/21;
  }
  iframe.pdf {
    width: 100%;
    height: 100%;
  }

  .PDFContentDownload {
    grid-area: 20/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
  }
  .PDFContentDownload > a {
    color: white;
    background-color: #2d8b0b;
    text-decoration: none;
    border-radius: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    padding: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    box-sizing: border-box;
  }
  .PDFContentDownload > a:hover {
    background-color: rgb(36, 110, 9);
    box-shadow: 0.1vw 0.1vw 0.1vw rgb(116, 116, 116);
  }
`
//DOCUMENTO PDF
let fontSizeG = '6px'
export const docpdf = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '15px'
  },
  columns: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerDocument: {
    flexDirection: 'row',
    margin: '5px 5px 0 5px',
    alignItems: 'center'
  },
  logo: {
    width: '30%',
    textAlign: 'right'
  },
  titleDocument: {
    width: '40%',
    fontSize: '3vw',
    textAlign: 'center'
  },
  informationDocument: {
    width: '30%',
    fontSize: '1.2vw',
    flexDirection: 'column',
    color: 'grey'
  },

  bodyDocument: {
    flexDirection: 'column'
  },
  bodyContent: {
    flexDirection: 'column'
  },
  cellContent: {
    width: '11.875%',
    fontSize: '10px',
    paddingLeft: '1px'
  },
  dayContent: {
    flexDirection: 'row',
    textAlign: 'center',
    backgroundColor: '#aaaaaa',
    borderBottom: '0.5px solid black',
    borderLeft: '0.5px solid black',
    borderRight: '0.5px solid black'
  },
  dateContent: {
    flexDirection: 'row',
    textAlign: 'center',
    backgroundColor: '#cccccc',
    borderBottom: '0.5px solid black',
    borderLeft: '0.5px solid black',
    borderRight: '0.5px solid black'
  },
  maestrosContent: {
    flexDirection: 'row',
    borderBottom: '0.5px solid black',
    borderLeft: '0.5px solid black',
    borderRight: '0.5px solid black',
    alignItems: 'center'
  },
  cellContentProduction: {
    width: '11.875%',
    fontSize: '10px',
    flexDirection: 'column',
    height: '100%',
    borderRight: '0.5px solid black',
    borderLeft: '0.5px solid black'
  },
  cellProductQuantity: {
    textAlign: 'center',
    fontSize: fontSizeG,
    width: '15.0%'
  },
  cellProduct: {
    fontSize: fontSizeG,
    width: '55%',
    borderRight: '0.5px solid black',
    borderLeft: '0.5px solid black',
    paddingLeft: '1px'
  },
  cellProductTotal: {
    textAlign: 'center',
    fontSize: fontSizeG,
    width: '30.0%'
  },
  cellContentProduct: {
    flexDirection: 'row',
    alignItems: 'center',
    border: '0.5px solid black'
  },
  cellContentMaster: {
    flexDirection: 'row',
    backgroundColor: '#eeeeee',
    alignItems: 'center',
    border: '0.5px solid black'
  },
  noRegister: {
    width: '100%',
    textAlign: 'center',
    fontSize: '5px'
  },
  noRegisterContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    position: 'absolute',
    top: '45%'
  },
  cellTotal: {
    width: '100%',
    textAlign: 'right'
  }
})
