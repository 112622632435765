import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as estilos from './ModalReporteCSS'
import Boton from '../../../../Elementos/Boton'
import Input from '../../../../Elementos/Input'
import Opciones from '../../../../Elementos/Opciones'
import * as api from './../../../../Api/Api'
import XLSX from 'xlsx-js-style';
import * as fg from './../../../../Api/FuncionesGlobales'
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  PDFDownloadLink
} from '@react-pdf/renderer'

import { hexToRGBA, registroPago } from '../../../../Api/Api'

function ModalGenerarPagoPedido (datos) {
  //const [verPdf2, setVerPdf] = useState(datos.verpdf)
  const [estaProcesando, setestaProcesando] = useState(false)

  const [fechaInicio, setfechaInicio] = useState('')
  const [fechaFin, setfechaFin] = useState('')
  const [formatoArc, setFormatoArc] = useState('')
  const [estProd, setEstProd] = useState('')

  const [envioForm, setEnvioForm] = useState(false)
  const [formatoArchivos, setFormArch] = useState([
    { codFA: 'pdf', descFA: 'pdf' },
    { codFA: 'xlsx', descFA: 'excel' }
  ])
  const [tiposProductos, setTiposProductos] = useState([
    { codTP: 1, descTP: 'activos' },
    { codTP: 2, descTP: 'inactivos' },
    { codTP: 3, descTP: 'todos' }
  ])
  const [reporte, setReporte] = useState(null)
  const [categorias, setCategorias] = useState(null)
  const [usrIngGuard, setUsrIngGuard] = useState(null)
  const [usrSalDistCom, setUsrSalDistCom] = useState(null)
  const [usrSalDistPen, setUsrSalDistPen] = useState(null)
  const [categoriasIF, setCategoriasIF] = useState([])
  const [categoriasSF, setCategoriasSF] = useState([])
  const [generoReporte, setGeneroReporte] = useState(false)
  const [descargarReporte, setDescargarReporte] = useState(false)
  const [recargoReporte, setRecargoReporte] = useState(false)
  const [habilitoPDF, setHabilitoPDF] = useState(false)
  const [habilitoXlsx, setHabilitoXlsx] = useState(false)
  const [descargarXlsx,setDescargarXlsx] = useState(null)

  useEffect(() => {
    const inicio = async () => {}
    inicio()
  }, [])

  useEffect(() => {
    console.log(categoriasIF)
  }, [categoriasIF])

  useEffect(() => {
    console.log(categoriasSF)
  }, [categoriasSF])

  const ReportePdf = useCallback(
    () => (
      <Document wrap='true'>
        {categoriasIF.length > 0 &&
          categoriasIF.map((categoriasp, indexcp) => {
            return (
              <Page
                key={indexcp}
                size='A4'
                orientation='landscape'
                style={estilos.docpdf.pagina}
              >
                <View style={estilos.docpdf.dcolumna}>
                  <View style={estilos.docpdf.dfila}>
                    <View
                      style={{
                        ...estilos.docpdf.celda,
                        ...estilos.docpdf.col1
                      }}
                    >
                      <Text>Producto</Text>
                    </View>
                    <View
                      style={{
                        ...estilos.docpdf.celda,
                        ...estilos.docpdf.col2
                      }}
                    >
                      <Text>Stock Inicial</Text>
                    </View>
                    <View
                      style={{
                        ...estilos.docpdf.celda,
                        ...estilos.docpdf.col3
                      }}
                    >
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f1,
                          ...estilos.docpdf.dcolumna
                        }}
                      >
                        <Text>Ingresos</Text>
                      </View>
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f2,
                          ...estilos.docpdf.dfila
                        }}
                      >
                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.col3f2c1,
                            ...estilos.docpdf.dcolumna
                          }}
                        >
                          <View
                            style={{
                              ...estilos.docpdf.celda,
                              ...estilos.docpdf.col3f2c1f1,
                              ...estilos.docpdf.dcolumna
                            }}
                          >
                            <Text>Guardados</Text>
                          </View>
                          <View
                            style={{
                              ...estilos.docpdf.celda,
                              ...estilos.docpdf.col3f2c1f2,
                              ...estilos.docpdf.dfila
                            }}
                          >
                            {categoriasp[0].productos[0]?.detalleIngresoUsuario.map(
                              (diu, index) => {
                                return (
                                  <View
                                    key={index}
                                    style={{
                                      ...estilos.docpdf.celda,
                                      ...estilos.docpdf.col3f2c1f2cn
                                    }}
                                  >
                                    <Text>{diu}</Text>
                                  </View>
                                )
                              }
                            )}
                          </View>
                        </View>
                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.col3f2c2,
                            ...estilos.docpdf.dfila
                          }}
                        >
                          <Text>Pendientes</Text>
                        </View>
                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.col3f2c3,
                            ...estilos.docpdf.dfila
                          }}
                        >
                          <Text>Total</Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        ...estilos.docpdf.celda,
                        ...estilos.docpdf.col5
                      }}
                    >
                      <Text>Stock Final</Text>
                    </View>
                  </View>

                  {categoriasp.map(catp => {
                    return (
                      <View
                        key={catp.codigo}
                        style={{
                          ...estilos.docpdf.dcolumna
                        }}
                      >
                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.categorias
                          }}
                        >
                          <Text>{catp.categoria}</Text>
                        </View>
                        {catp.productos.length > 0 &&
                          catp.productos.map(prodp => {
                            return (
                              <View
                                key={prodp.codigo}
                                style={{
                                  ...estilos.docpdf.dfila,
                                  ...estilos.docpdf.fProd,
                                  ...estilos.docpdf.fregistroProd
                                }}
                              >
                                <View
                                  style={{
                                    ...estilos.docpdf.nprod
                                  }}
                                >
                                  <Text>{prodp.producto}</Text>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.sini
                                  }}
                                >
                                  <Text>{prodp.stockInicial}</Text>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.ingUsr,
                                    ...estilos.docpdf.dfila
                                  }}
                                >
                                  <View
                                    style={{
                                      ...estilos.docpdf.usuarios,
                                      ...estilos.docpdf.dfila,
                                      width: '80%'
                                    }}
                                  >
                                    {prodp.detalleIngreso.length > 0 &&
                                      prodp.detalleIngreso.map((diu, index) => {
                                        return (
                                          <View
                                            key={index}
                                            style={{
                                              ...estilos.docpdf.usufec
                                            }}
                                          >
                                            <Text>{diu}</Text>
                                          </View>
                                        )
                                      })}
                                  </View>
                                  <View
                                    style={{
                                      ...estilos.docpdf.pendiente
                                    }}
                                  >
                                    <Text>{prodp.ingresoPendiente}</Text>
                                  </View>
                                  <View
                                    style={{
                                      ...estilos.docpdf.total
                                    }}
                                  >
                                    <Text>{prodp.ingresoTotal}</Text>
                                  </View>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.stkfinal
                                  }}
                                >
                                  <Text>{prodp.stockFinal}</Text>
                                </View>
                              </View>
                            )
                          })}
                      </View>
                    )
                  })}
                </View>
              </Page>
            )
          })}
        {categoriasSF.length > 0 &&
          categoriasSF.map((categoriasp, index) => {
            return (
              <Page
                key={index}
                size='A4'
                orientation='landscape'
                style={estilos.docpdf.pagina}
              >
                <View style={estilos.docpdf.dfila}>
                  <View
                    style={{
                      ...estilos.docpdf.celda,
                      ...estilos.docpdf.col1
                    }}
                  >
                    <Text>Producto</Text>
                  </View>
                  <View
                    style={{
                      ...estilos.docpdf.celda,
                      ...estilos.docpdf.scol2,
                      ...estilos.docpdf.dcolumna
                    }}
                  >
                    <View
                      style={{
                        ...estilos.docpdf.celda,
                        ...estilos.docpdf.col3f1
                      }}
                    >
                      <Text>Salidas</Text>
                    </View>
                    <View
                      style={{
                        ...estilos.docpdf.celda,
                        ...estilos.docpdf.col3f2,
                        ...estilos.docpdf.dfila
                      }}
                    >
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.scol2f2c1,
                          ...estilos.docpdf.dcolumna
                        }}
                      >
                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.scol2f2c1f1
                          }}
                        >
                          <Text>Distribuidor Completo</Text>
                        </View>

                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.scol3f2c1f2,
                            ...estilos.docpdf.dfila
                          }}
                        >
                          {categoriasp[0].productos[0]?.detalleSalidaUsuario.map(
                            (diu, index) => {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    ...estilos.docpdf.celda,
                                    ...estilos.docpdf.col3f2c1f2cn
                                  }}
                                >
                                  <Text>{diu}</Text>
                                </View>
                              )
                            }
                          )}
                        </View>
                      </View>

                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f2c2,
                          ...estilos.docpdf.dfila
                        }}
                      >
                        <Text>Tienda</Text>
                      </View>
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f2c3,
                          ...estilos.docpdf.dfila,
                          borderRight: 1
                        }}
                      >
                        <Text>Completa Total</Text>
                      </View>
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.scol2f2c1,
                          ...estilos.docpdf.dcolumna
                        }}
                      >
                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.scol2f2c1f1
                          }}
                        >
                          <Text>Distribuidor Pendiente</Text>
                        </View>

                        <View
                          style={{
                            ...estilos.docpdf.celda,
                            ...estilos.docpdf.scol3f2c1f2,
                            ...estilos.docpdf.dfila
                          }}
                        >
                          {categoriasp[0].productos[0]?.detalleSalidaPendienteUsuario.map(
                            (diu, index) => {
                              return (
                                <View
                                  key={index}
                                  style={{
                                    ...estilos.docpdf.celda,
                                    ...estilos.docpdf.col3f2c1f2cn
                                  }}
                                >
                                  <Text>{diu}</Text>
                                </View>
                              )
                            }
                          )}
                        </View>
                      </View>
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f2c2,
                          ...estilos.docpdf.dfila
                        }}
                      >
                        <Text>Tienda Pendiente</Text>
                      </View>
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f2c3,
                          ...estilos.docpdf.dfila,
                          borderRight: 1
                        }}
                      >
                        <Text>Pendiente Total</Text>
                      </View>
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.col3f2c3,
                          ...estilos.docpdf.dfila
                        }}
                      >
                        <Text>Salida Total</Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      ...estilos.docpdf.celda,
                      ...estilos.docpdf.col5
                    }}
                  >
                    <Text>Stock Final</Text>
                  </View>
                </View>
                {categoriasp.map(catp => {
                  return (
                    <View
                      key={catp.codigo}
                      style={{
                        ...estilos.docpdf.dcolumna
                      }}
                    >
                      <View
                        style={{
                          ...estilos.docpdf.celda,
                          ...estilos.docpdf.categorias
                        }}
                      >
                        <Text>{catp.categoria}</Text>
                      </View>
                      {catp.productos.length > 0 &&
                        catp.productos.map(prodp => {
                          return (
                            <View
                              key={prodp.codigo}
                              style={{
                                ...estilos.docpdf.dfila,
                                ...estilos.docpdf.fProd,
                                ...estilos.docpdf.fregistroProd
                              }}
                            >
                              <View
                                style={{
                                  ...estilos.docpdf.nprod
                                }}
                              >
                                <Text>{prodp.producto}</Text>
                              </View>

                              <View
                                style={{
                                  ...estilos.docpdf.salUsr,
                                  ...estilos.docpdf.dfila
                                  //backgroundColor: "green"
                                }}
                              >
                                <View
                                  style={{
                                    ...estilos.docpdf.usuarios,
                                    ...estilos.docpdf.dfila,
                                    width: '35%'
                                  }}
                                >
                                  {prodp.detalleSalida.length > 0 &&
                                    prodp.detalleSalida.map((diu, index) => {
                                      return (
                                        <View
                                          key={index}
                                          style={{
                                            ...estilos.docpdf.susufec
                                          }}
                                        >
                                          <Text>{diu}</Text>
                                        </View>
                                      )
                                    })}
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.stienda
                                  }}
                                >
                                  <Text>{prodp.salidaTienda}</Text>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.sctotal
                                  }}
                                >
                                  <Text>{prodp.salidaCompletaTotal}</Text>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.usuarios,
                                    ...estilos.docpdf.dfila,
                                    width: '35%'
                                  }}
                                >
                                  {prodp.detalleSalidaPendiente.length > 0 &&
                                    prodp.detalleSalidaPendiente.map(
                                      (diu, index) => {
                                        return (
                                          <View
                                            key={index}
                                            style={{
                                              ...estilos.docpdf.susufec
                                            }}
                                          >
                                            <Text>{diu}</Text>
                                          </View>
                                        )
                                      }
                                    )}
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.stienda
                                  }}
                                >
                                  <Text>{prodp.salidaPendienteTienda}</Text>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.sctotal
                                  }}
                                >
                                  <Text>{prodp.salidaPendienteTotal}</Text>
                                </View>
                                <View
                                  style={{
                                    ...estilos.docpdf.sctotal
                                  }}
                                >
                                  <Text>{prodp.salidaTotal}</Text>
                                </View>
                              </View>
                              <View
                                style={{
                                  ...estilos.docpdf.stkfinal
                                }}
                              >
                                <Text>{prodp.stockFinal}</Text>
                              </View>
                            </View>
                          )
                        })}
                    </View>
                  )
                })}
              </Page>
            )
          })}
      </Document>
    ),
    [recargoReporte]
  )
  return (
    <>
      {console.log(datos.esComputadora)}
      <div id='baseModal' style={estilos.baseModal(datos.esComputadora)}>
        {datos.mostrarModal && (
          <div
            id='contenedorModal'
            style={estilos.contenedorModal(datos.esComputadora)}
          >
            <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
              <Boton
                style={{
                  backgroundColor: '#E12121',
                  borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                  padding: datos.esComputadora ? '0.8vw' : '2vw'
                }}
                textoStyle={{
                  color: 'white',
                  width: '100%',
                  fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                }}
                texto='X'
                tipo='submit'
                tonalidad='20'
                estaProcesando={estaProcesando}
                onClick={datos.cerrarPdfModalClick}
              />
            </div>
            <div style={estilos.tituloCSS(datos.esComputadora)}>
              Reporte de Productos
            </div>
            <div
              id='contenidoModal'
              style={estilos.contenidoModal(datos.esComputadora)}
            >
              <div style={estilos.formularioCSS(datos.esComputadora)}>
                <div style={{ gridArea: '1/2/2/20' }}>
                  <p style={estilos.textRangoFechas(datos.esComputadora)}>
                    Seleccione la fecha de inicio y fin para generar el reporte.
                  </p>
                </div>
                <div
                  style={{
                    gridArea: '2/2/3/10',
                    ...estilos.centrarElementos(datos.esComputadora),
                    padding: '0 0 0 5vw'
                  }}
                >
                  <Input
                    style={{
                      margin: datos.esComputadora
                        ? '0 0.2vw 0 0.2vw'
                        : '0 1vw 0 1vw',
                      ...(envioForm && (fechaInicio === 0 || fechaInicio === '')
                        ? estilos.estiloVacio(datos.esComputadora, fechaInicio)
                        : {})
                    }}
                    textoStyle={{
                      fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                    }}
                    moderno='Fecha de Inicio' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                    tipo='date'
                    estilos={estilos.estiloVacio(
                      datos.esComputadora,
                      envioForm,
                      fechaInicio
                    )}
                    onBlur={() => {}}
                    onChange={event => {
                      setfechaInicio(event.currentTarget.value)
                      //setGeneroReporte(false)
                    }}
                    id={'montoPago'}
                    valorInicial={''}
                    esComputadora={datos.esComputadora}
                  />
                </div>
                <div
                  style={{
                    gridArea: '2/12/3/20',
                    ...estilos.centrarElementos(datos.esComputadora),
                    padding: '0 5vw 0 0'
                  }}
                >
                  <Input
                    style={{
                      //margin: datos.esComputadora ? "0 0.2vw 0 0.2vw":"0 1vw 0 1vw",
                      ...(envioForm && (fechaFin === 0 || fechaFin === '')
                        ? estilos.estiloVacio(datos.esComputadora, fechaFin)
                        : {})
                    }}
                    textoStyle={{
                      fontSize: datos.esComputadora ? '1vw' : '2.5vw'
                    }}
                    moderno='Fecha Fin' //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                    tipo='date'
                    ubicacion='1/11/2/20'
                    estilos={estilos.estiloVacio(
                      datos.esComputadora,
                      envioForm,
                      fechaFin
                    )}
                    onBlur={() => {}}
                    onChange={event => {
                      setfechaFin(event.currentTarget.value)
                    }}
                    id={'montoPago'}
                    valorInicial={''}
                    esComputadora={datos.esComputadora}
                  />
                </div>
                <div
                  style={{
                    gridArea: '3/2/4/4',
                    ...estilos.centrarElementos(datos.esComputadora)
                  }}
                >
                  <p style={estilos.textRangoFechas(datos.esComputadora)}>
                    Formato:
                  </p>
                </div>
                <div
                  style={{
                    gridArea: '3/4/4/8',
                    ...estilos.centrarElementos(datos.esComputadora)
                  }}
                >
                  <Opciones
                    style={{
                      padding: datos.esComputadora ? '0.8vw' : '0.5vw 0',
                      marginLeft: '1vw',
                      ...(envioForm && (formatoArc === 0 || formatoArc === '')
                        ? estilos.estiloVacio(datos.esComputadora, formatoArc)
                        : {})
                    }}
                    textoStyle={{
                      //color: 'white',
                      width: '100%',
                      fontSize: datos.esComputadora ? '1vw' : '2.5vw',
                      fontFamily: 'arial, verdana'
                    }}
                    moderno=''
                    opciones={formatoArchivos !== null ? formatoArchivos : []}
                    clave='codFA'
                    valor={['descFA']}
                    esComputadora={datos.esComputadora}
                    onClick={valor => {
                      setFormatoArc(valor)
                      setDescargarReporte(false)
                    }}
                  />
                </div>
                <div
                  style={{
                    gridArea: '3/10/4/14',
                    ...estilos.centrarElementos(datos.esComputadora)
                  }}
                >
                  <p style={estilos.textRangoFechas(datos.esComputadora)}>
                    Estado Producto:
                  </p>
                </div>
                <div
                  style={{
                    gridArea: '3/14/4/18',
                    ...estilos.centrarElementos(datos.esComputadora)
                  }}
                >
                  <Opciones
                    style={{
                      padding: datos.esComputadora ? '0.8vw' : '0.5vw 0',
                      marginLeft: '1vw',
                      ...(envioForm && (estProd === 0 || estProd === '')
                        ? estilos.estiloVacio(datos.esComputadora, estProd)
                        : {})
                    }}
                    textoStyle={{
                      //color: 'white',
                      width: '100%',
                      fontSize: datos.esComputadora ? '1vw' : '2.5vw',
                      fontFamily: 'arial, verdana'
                    }}
                    moderno=''
                    opciones={tiposProductos !== null ? tiposProductos : []}
                    clave='codTP'
                    valor={['descTP']}
                    esComputadora={datos.esComputadora}
                    onClick={valor => {
                      setEstProd(valor)
                    }}
                  />
                </div>
                <div
                  style={{
                    gridArea: '4/10/5/14',
                    ...estilos.centrarElementos(datos.esComputadora)
                  }}
                >
                  <Boton
                    style={{
                      backgroundColor: '#2D8B0B',
                      borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                      padding: datos.esComputadora ? '0.8vw' : '2vw 1vw',
                      margin: '1vw 0 1vw 0'
                    }}
                    textoStyle={{
                      color: 'white',
                      width: '100%',
                      fontSize: datos.esComputadora ? '1vw' : '2vw',
                      fontFamily: 'arial, verdana'
                    }}
                    texto='Generar Reporte'
                    tipo='submit'
                    tonalidad='20'
                    estaProcesando={estaProcesando}
                    onClick={async () => {
                      //console.log(fechaInicio, fechaFin, estProd)
                      setEnvioForm(true)
                      
                      if (
                        fechaInicio !== '' &&
                        fechaFin !== '' &&
                        formatoArc !== '' &&
                        estProd !== ''
                      ) {
                        setestaProcesando(true)
                        //console.log(fechaInicio, fechaFin, estProd)
                        const reporte = await api.reporteMovimientoProd(
                          fechaInicio, //'2024-11-14',
                          fechaFin, //'2024-11-14',
                          parseInt(estProd, 10) //1
                        )
                        if (reporte) {
                          if (formatoArc === 'pdf'){
                            console.log(reporte)
                            setReporte(reporte.reporte)
                            let categorias = reporte.reporte.data
                            setCategorias(categorias)
                            console.log(categorias)
                            let cantCat = reporte.reporte.data.length
                            //console.log(cantCat)
                            let maxUsrIng = 0
                            let maxUsrSalGuard = 0
                            let maxUsrSalPen = 0
                            for (let i = 0; i < cantCat; i++) {
                              //console.log(categorias[i])
                              for (
                                let j = 0;
                                j < categorias[i].productos.length;
                                j++
                              ) {
                                //console.log(data[i].productos[j])
                                if (
                                  maxUsrIng <
                                  categorias[i].productos[j].detalleIngresoUsuario
                                    .length
                                ) {
                                  maxUsrIng =
                                    categorias[i].productos[j]
                                      .detalleIngresoUsuario.length
                                }
                                if (
                                  maxUsrSalGuard <
                                  categorias[i].productos[j].detalleSalidaUsuario
                                    .length
                                ) {
                                  maxUsrSalGuard =
                                    categorias[i].productos[j]
                                      .detalleSalidaUsuario.length
                                }
                                if (
                                  maxUsrSalPen <
                                  categorias[i].productos[j]
                                    .detalleSalidaPendienteUsuario.length
                                ) {
                                  maxUsrSalPen =
                                    categorias[i].productos[j]
                                      .detalleSalidaUsuario.length
                                }
                              }
                            }
                            //console.log(maxUsrIng, maxUsrSalGuard, maxUsrSalPen)
                            /*console.log(
                              'cant bloques',
                              maxUsrIng % 8 !== 0
                                ? Math.floor(maxUsrIng / 8) + 1
                                : Math.floor(maxUsrIng / 8),
                              maxUsrSalGuard % 4 !== 0
                                ? Math.floor(maxUsrSalGuard / 4) + 1
                                : Math.floor(maxUsrSalGuard / 4),
                              maxUsrSalPen % 4 !== 0
                                ? Math.floor(maxUsrSalPen / 4) + 1
                                : Math.floor(maxUsrSalPen / 4)
                            )*/
                            let maxUsrIngtemp =
                              maxUsrIng % 8 !== 0
                                ? Math.floor(maxUsrIng / 8) + 1
                                : Math.floor(maxUsrIng / 8)

                            let maxUsrSalGuardtemp =
                              maxUsrSalGuard % 4 !== 0
                                ? Math.floor(maxUsrSalGuard / 4) + 1
                                : Math.floor(maxUsrSalGuard / 4)

                            let maxUsrSalPentemp =
                              maxUsrSalPen % 4 !== 0
                                ? Math.floor(maxUsrSalPen / 4) + 1
                                : Math.floor(maxUsrSalPen / 4)
                            console.log(maxUsrIngtemp)
                            setUsrIngGuard(maxUsrIngtemp)
                            setUsrSalDistCom(maxUsrSalGuardtemp)
                            setUsrSalDistPen(maxUsrSalPentemp)
                            setCategoriasIF([])
                            setCategoriasSF([])
                            let indice = 0
                            for (let i = 0; i < maxUsrIngtemp; i++) {
                              //console.log(categorias)
                              let categoriasFTemp = JSON.parse(
                                JSON.stringify(categorias)
                              ) //[...categorias]
                              //console.log(categoriasFTemp)
                              for (let j = 0; j < categoriasFTemp.length; j++) {
                                //console.log(categoriasFTemp[j].productos)
                                for (
                                  let k = 0;
                                  k < categoriasFTemp[j].productos.length;
                                  k++
                                ) {
                                  //console.log(categorias[j].productos[k].detalleIngresoUsuario)
                                  //console.log(categoriasFTemp[j].productos[k].detalleIngresoUsuario.slice(indice,indice+8))
                                  categoriasFTemp[j].productos[k].detalleIngreso =
                                    categoriasFTemp[j].productos[
                                      k
                                    ].detalleIngreso.slice(indice, indice + 8)
                                  categoriasFTemp[j].productos[
                                    k
                                  ].detalleIngresoUsuario = categoriasFTemp[
                                    j
                                  ].productos[k].detalleIngresoUsuario.slice(
                                    indice,
                                    indice + 8
                                  )
                                  if (
                                    categoriasFTemp[j].productos[k].detalleIngreso
                                      .length < 8
                                  ) {
                                    categoriasFTemp[j].productos[
                                      k
                                    ].detalleIngreso = categoriasFTemp[
                                      j
                                    ].productos[k].detalleIngreso.concat(
                                      Array(
                                        8 -
                                          categoriasFTemp[j].productos[k]
                                            .detalleIngreso.length
                                      ).fill('-')
                                    )
                                  }
                                  if (
                                    categoriasFTemp[j].productos[k]
                                      .detalleIngresoUsuario.length < 8
                                  ) {
                                    categoriasFTemp[j].productos[
                                      k
                                    ].detalleIngresoUsuario = categoriasFTemp[
                                      j
                                    ].productos[k].detalleIngresoUsuario.concat(
                                      Array(
                                        8 -
                                          categoriasFTemp[j].productos[k]
                                            .detalleIngresoUsuario.length
                                      ).fill('-')
                                    )
                                  }
                                }
                              }
                              console.log(categoriasFTemp)
                              setCategoriasIF(prev => [...prev, categoriasFTemp])
                              indice += 8
                            }

                            let maxUsrSaldPaginas =
                              maxUsrSalGuard > maxUsrSalPen
                                ? maxUsrSalGuard % 4 !== 0
                                  ? Math.floor(maxUsrSalGuard / 4) + 1
                                  : Math.floor(maxUsrSalGuard / 4)
                                : maxUsrSalPen % 4 !== 0
                                ? Math.floor(maxUsrSalPen / 4) + 1
                                : Math.floor(maxUsrSalPen / 4)

                            console.log(maxUsrSaldPaginas)

                            let indiceS = 0
                            for (let i = 0; i < maxUsrSaldPaginas; i++) {
                              console.log(categorias)
                              let categoriasSFTemp = JSON.parse(
                                JSON.stringify(categorias)
                              ) //[...categorias]
                              for (let j = 0; j < categoriasSFTemp.length; j++) {
                                //console.log(categoriasFTemp[j].productos)
                                for (
                                  let k = 0;
                                  k < categoriasSFTemp[j].productos.length;
                                  k++
                                ) {
                                  //console.log(categorias[j].productos[k].detalleIngresoUsuario)
                                  //console.log(categoriasFTemp[j].productos[k].detalleIngresoUsuario.slice(indice,indice+8))
                                  categoriasSFTemp[j].productos[k].detalleSalida =
                                    categoriasSFTemp[j].productos[
                                      k
                                    ].detalleSalida.slice(indiceS, indiceS + 4)
                                  categoriasSFTemp[j].productos[
                                    k
                                  ].detalleSalidaUsuario = categoriasSFTemp[
                                    j
                                  ].productos[k].detalleSalidaUsuario.slice(
                                    indiceS,
                                    indiceS + 4
                                  )
                                  if (
                                    categoriasSFTemp[j].productos[k].detalleSalida
                                      .length < 4
                                  ) {
                                    categoriasSFTemp[j].productos[
                                      k
                                    ].detalleSalida = categoriasSFTemp[
                                      j
                                    ].productos[k].detalleSalida.concat(
                                      Array(
                                        4 -
                                          categoriasSFTemp[j].productos[k]
                                            .detalleSalida.length
                                      ).fill('-')
                                    )
                                  }
                                  if (
                                    categoriasSFTemp[j].productos[k]
                                      .detalleSalidaUsuario.length < 4
                                  ) {
                                    categoriasSFTemp[j].productos[
                                      k
                                    ].detalleSalidaUsuario = categoriasSFTemp[
                                      j
                                    ].productos[k].detalleSalidaUsuario.concat(
                                      Array(
                                        4 -
                                          categoriasSFTemp[j].productos[k]
                                            .detalleSalidaUsuario.length
                                      ).fill('-')
                                    )
                                  }

                                  categoriasSFTemp[j].productos[
                                    k
                                  ].detalleSalidaPendiente = categoriasSFTemp[
                                    j
                                  ].productos[k].detalleSalidaPendiente.slice(
                                    indiceS,
                                    indiceS + 4
                                  )
                                  categoriasSFTemp[j].productos[
                                    k
                                  ].detalleSalidaPendienteUsuario = categoriasSFTemp[
                                    j
                                  ].productos[
                                    k
                                  ].detalleSalidaPendienteUsuario.slice(
                                    indiceS,
                                    indiceS + 4
                                  )
                                  if (
                                    categoriasSFTemp[j].productos[k]
                                      .detalleSalidaPendiente.length < 4
                                  ) {
                                    categoriasSFTemp[j].productos[
                                      k
                                    ].detalleSalidaPendiente = categoriasSFTemp[
                                      j
                                    ].productos[k].detalleSalidaPendiente.concat(
                                      Array(
                                        4 -
                                          categoriasSFTemp[j].productos[k]
                                            .detalleSalidaPendiente.length
                                      ).fill('-')
                                    )
                                  }
                                  if (
                                    categoriasSFTemp[j].productos[k]
                                      .detalleSalidaPendienteUsuario.length < 4
                                  ) {
                                    categoriasSFTemp[j].productos[
                                      k
                                    ].detalleSalidaPendienteUsuario = categoriasSFTemp[
                                      j
                                    ].productos[
                                      k
                                    ].detalleSalidaPendienteUsuario.concat(
                                      Array(
                                        4 -
                                          categoriasSFTemp[j].productos[k]
                                            .detalleSalidaPendienteUsuario.length
                                      ).fill('-')
                                    )
                                  }
                                }
                              }
                              setCategoriasSF(prev => [...prev, categoriasSFTemp])
                              indiceS += 4
                            }

                            setGeneroReporte(true)
                            setDescargarReporte(true)
                            setRecargoReporte(prev => !prev)
                            setHabilitoPDF(true)
                            setHabilitoXlsx(false)
                          }
                          else{

                            let maxUsrSalGuard = 0; let maxUsrSalGuardArray = []
                            let maxUsrSalPen =0; let maxUsrSalPendArray = []
                            let maxUsrIng = 0; let maxUsrIgnArray = []
                            
                            let indice = 0
                            let indiceFinCat = []
                            let contenidoXlsx = []
                            reporte.reporte.data.map(catp => {
                              contenidoXlsx.push([catp.categoria])
                              indiceFinCat.push(indice+3)
                              indice+=1
                              catp.productos.map(prodp => {
                                indice+=1
                                contenidoXlsx.push([
                                  prodp.producto,
                                  prodp.stockInicial,
                                  ...prodp.detalleIngreso,
                                  prodp.ingresoCompletoTotal,
                                  prodp.ingresoPendiente,
                                  prodp.ingresoTotal,
                                  ...prodp.detalleSalida,
                                  prodp.salidaDistribuidor,
                                  prodp.salidaTienda,
                                  prodp.salidaCompletaTotal,
                                  ...prodp.detalleSalidaPendiente,
                                  prodp.salidaPendienteDistribuidor,
                                  prodp.salidaPendienteTienda,
                                  prodp.salidaPendienteTotal,
                                  prodp.salidaTotal,
                                  prodp.stockFinal
                                ])
                                if(maxUsrSalGuard < prodp.detalleSalidaUsuario.length){
                                  maxUsrSalGuard = prodp.detalleSalidaUsuario.length
                                  maxUsrSalGuardArray = prodp.detalleSalidaUsuario
                                }
                                if(maxUsrSalPen < prodp.detalleSalidaPendienteUsuario.length){
                                  maxUsrSalPen = prodp.detalleSalidaPendienteUsuario.length
                                  maxUsrSalPendArray = prodp.detalleSalidaPendienteUsuario
                                }
                                if(maxUsrIng < prodp.detalleIngresoUsuario.length){
                                  maxUsrIng = prodp.detalleIngresoUsuario.length
                                  maxUsrIgnArray = prodp.detalleIngresoUsuario
                                }
                              })
                            })
                            console.log(indiceFinCat)


                            let celdasUnidas = indiceFinCat.map(i => {
                              return {s : {c: 0, r: i}, e: {c: (maxUsrIgnArray.length+5+(new Array(maxUsrSalGuard+maxUsrSalPen+6).fill('')).length+1), r: i}}
                            })
                            console.log(celdasUnidas)
                            // Datos
                            /*maxUsrSalGuard = 2
                            maxUsrSalPen = 2
                            maxUsrSalGuardArray=['uno','dos']
                            maxUsrSalPendArray = ['tres','cuatro']
                            */
                            let reporteCabeceraXlsx = [
                                ["Producto", "Stock Inicial", "Ingresos",...(new Array(maxUsrIgnArray.length+2).fill('')),"Salidas",...(new Array(maxUsrSalGuard+maxUsrSalPen+6).fill('')),"Stock Final"],
                                ["", "", "Guardados", ...(new Array(maxUsrIgnArray.length).fill('')), "Pendientes", "Total Ingresos","Distribuidor Completo",...(new Array(maxUsrSalGuard).fill('')),"Tienda", "Completa Total","Distribuidor Pendiente",...(new Array(maxUsrSalPen).fill('')),"Tienda Pendiente","Pendiente Total","Total"],
                                ["", "", ...maxUsrIgnArray, "Total","","",...maxUsrSalGuardArray,"Total","","",...maxUsrSalPendArray,"Total","","",""],
                                ...contenidoXlsx
                              ];
                            
                            
                            // Crear la hoja de cálculo
                            const ws = XLSX.utils.aoa_to_sheet(reporteCabeceraXlsx);

                            // Agregar merges
                            //console.log({ s: { c: (maxUsrIgnArray.length+5), r: 1}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuard), r: 1}})
                            ws["!merges"] = [

                                { s: { c: 0, r: 0 }, e: { c: 0, r: 2 } },  // A1:A3 Productos
                                { s: { c: 1, r: 0 }, e: { c: 1, r: 2 } },  // B1:B3 Stock Inicial
                                { s: { c: 2, r: 0 }, e: { c: maxUsrIgnArray.length + 4, r: 0 } },  // C1:+ Ingresos
                                { s: { c: 2, r: 1 }, e: { c: maxUsrIgnArray.length + 2, r: 1 } },  // C1:+2 Guardados
                                { s: { c: maxUsrIgnArray.length + 3, r: 1 }, e: { c: maxUsrIgnArray.length + 3, r: 2 } }, // +2:+2 Pendientes
                                { s: { c: maxUsrIgnArray.length + 4, r: 1 }, e: { c: maxUsrIgnArray.length + 4, r: 2 } }, // +2:+2 Total
                                
                                { s: { c: (maxUsrIgnArray.length+5), r: 1}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuard), r: 1}}, //salida distribuidor completo
                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuard+3), r: 1}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuard+3+maxUsrSalPen), r: 1}}, //salida distribuidor pendiente

                                { s: { c: (maxUsrIgnArray.length+5), r: 0}, e: {c:(maxUsrIgnArray.length+5+(new Array(maxUsrSalGuard+maxUsrSalPen+6).fill('')).length), r:0}}, //Salidas
                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+1), r: 1}, e: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+1), r: 2}}, //salida tienda
                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2), r: 1}, e: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2), r: 2}}, //salida completa total
                                

                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+2), r: 1}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+2),r:2}}, //salida tienda pendiente
                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+3), r: 1}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+3),r:2}}, //salida Pendiente total
                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+4), r: 1}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+4),r:2}}, //salida pendiente total
                                { s: { c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+5), r: 0}, e: {c: (maxUsrIgnArray.length+5+maxUsrSalGuardArray.length+2+maxUsrSalPendArray.length+5),r:2}}, //stock total

                                ...celdasUnidas

                                
                              ];

                            // Define el estilo de centrado
                            const centerStyle = {
                                alignment: {
                                    horizontal: 'center',
                                    vertical: 'center'
                                }
                            };

                            // Aplicar el estilo a todas las celdas
                            Object.keys(ws).forEach(cell => {
                                if (cell[0] === '!') return; // Ignorar propiedades internas de SheetJS
                                if (!ws[cell].s) ws[cell].s = {}; // Inicializar el objeto de estilos si no existe
                                ws[cell].s = centerStyle; // Aplicar el estilo de centrado
                            });
                            
                           const baseStyle = {
                              alignment: {
                                  horizontal: 'center',
                                  vertical: 'center',
                                  wrapText: true,
                              },
                              font: {
                                  bold: true
                              },
                              fill: {
                                  fgColor: { rgb: "CCCCCC" } // Fondo amarillo
                              },
                              border: {
                                  top: { style: "thin", color: { rgb: "000000" } },
                                  bottom: { style: "thin", color: { rgb: "000000" } },
                                  left: { style: "thin", color: { rgb: "000000" } },
                                  right: { style: "thin", color: { rgb: "000000" } }
                              }
                            };
                            
                            const categoriaStyle = JSON.parse(
                              JSON.stringify(baseStyle)
                            )
                            categoriaStyle.fill.fgColor.rgb = "AD6726"
                            // Función para aplicar estilo a un rango de celdas
                            const aplicarEstiloARango = (hoja, inicio, fin, estilo) => {
                              for (let row = inicio.r; row <= fin.r; row++) {
                                  for (let col = inicio.c; col <= fin.c; col++) {
                                      const celda = XLSX.utils.encode_cell({ r: row, c: col });
                                      if (!hoja[celda]) hoja[celda] = { t: 's', v: '' }; // Inicializar la celda si no existe
                                      hoja[celda].s = estilo;
                                  }
                              }
                            };

                            // Aplicar el borde al rango B2:C5
                            aplicarEstiloARango(ws, {  c: 0, r: 0}, { c: (maxUsrIgnArray.length+5+(new Array(maxUsrSalGuard+maxUsrSalPen+6).fill('')).length+1), r: 2 }, baseStyle);
                            
                            celdasUnidas.map(cu => {
                              aplicarEstiloARango(ws, cu.s, cu.e, categoriaStyle);

                            })

                            ws['!cols'] = [
                              { wch: 30 }, // Ancho para la primera columna
                            ];
                            
                            // Crear el libro y exportar el archivo
                            const wb = XLSX.utils.book_new();
                            setDescargarXlsx([wb,ws])
                            //XLSX.utils.book_append_sheet(wb, ws, "Reporte");
                            //XLSX.writeFile(wb, "SheetJSMergeCells.xlsx");
                            setDescargarReporte(true)
                            setHabilitoPDF(false)
                            setHabilitoXlsx(true)
                          }
                          
                        }
                      }
                      setestaProcesando(false)
                    }}
                  />
                </div>
                <div
                  style={{
                    gridArea: '4/15/5/19',
                    ...estilos.centrarElementos(datos.esComputadora)
                  }}
                >
                  {false && (
                    <Boton
                      style={{
                        backgroundColor: '#F52424',
                        borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                        padding: datos.esComputadora ? '0.8vw' : '2vw 1vw',
                        margin: '1vw 0 1vw 0'
                      }}
                      textoStyle={{
                        color: 'white',
                        width: '100%',
                        fontSize: datos.esComputadora ? '1vw' : '2vw',
                        fontFamily: 'arial, verdana'
                      }}
                      texto='.te'
                      ubicacion='2/12/3/20'
                      tipo='submit'
                      tonalidad='20'
                      estaProcesando={estaProcesando}
                      onClick={datos.cerrarPdfModalClick}
                    />
                  )}
                  {descargarReporte && (
                    <div
                      style={{
                        backgroundColor: habilitoXlsx ? 'white':'#8d0303',
                        borderRadius: datos.esComputadora ? '0.5vw':'1vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        fontSize: datos.esComputadora ? '1vw' : '2.2vw',
                        padding: datos.esComputadora ? '0.7vw':'0.5vw'
                      }}
                    >
                      {habilitoPDF &&
                        <PDFDownloadLink
                          style={{
                            color: 'white',
                            width: '100%',
                            textDecoration: 'none'
                          }}
                          document={<ReportePdf />}
                          fileName={`f_(${fechaInicio}-${fechaFin})_${fg.fecha_hora_actual()}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading
                              ? 'Cargando documento...'
                              : 'Descargar Reporte'
                          }
                        </PDFDownloadLink>
                      }
                      {habilitoXlsx &&
                        <Boton
                        style={{
                          backgroundColor: '#8d0303',
                          borderRadius: datos.esComputadora ? '0.5vw' : '1vw',
                          padding: datos.esComputadora ? '0.8vw' : '2vw 1vw',
                          margin: '1vw 0 1vw 0'
                        }}
                        textoStyle={{
                          color: 'white',
                          width: '100%',
                          fontSize: datos.esComputadora ? '1vw' : '2vw',
                          fontFamily: 'arial, verdana'
                        }}
                        texto='Descargar Excel'
                        tipo='submit'
                        tonalidad='20'
                        estaProcesando={estaProcesando}
                        onClick={async (event) => {
                          try{
                            XLSX.utils.book_append_sheet(descargarXlsx[0], descargarXlsx[1], `${fechaInicio}_${fechaFin}`);
                            XLSX.writeFile(descargarXlsx[0], `Reporte_${fg.obtenerHoraActual()}.xlsx`);
                          }catch{
                            setHabilitoXlsx(false)
                            setDescargarReporte(false)
                          }
                          //XLSX.utils.book_append_sheet(descargarXlsx[0], descargarXlsx[1], "Reporte");
                          //XLSX.writeFile(descargarXlsx[0], `${fechaInicio}_${fechaFin}.xlsx`);
                        }}
                        />
                      }
                    </div>
                  )}
                </div>
                {formatoArc === 'pdf' && (
                  <>
                    <div
                      style={{
                        gridArea: '5/2/6/20',
                        ...estilos.centrarElementos(datos.esComputadora),
                        fontSize: datos.esComputadora ? '1vw' : '3vw',
                        fontFamily: 'arial, verdana'
                      }}
                    >
                      Contenido de Visualización
                    </div>
                    <div
                      style={{
                        gridArea: '6/2/20/20',
                        ...estilos.prevVis(datos.esComputadora)
                      }}
                    >
                      {generoReporte && (
                        <PDFViewer style={{ width: '100%', height: '100%' }}>
                          <ReportePdf />
                        </PDFViewer>
                      )}
                    </div>
                  </>
                )}

                {formatoArc === 'xlsx' && (
                  <>
                    <div
                      style={{
                        gridArea: '5/2/6/20',
                        ...estilos.centrarElementos(datos.esComputadora),
                        fontSize: datos.esComputadora ? '1vw' : '3vw',
                        fontFamily: 'arial, verdana'
                      }}
                    >
                      Contenido de Visualización
                    </div>
                    <div
                      style={{
                        gridArea: '6/2/20/20',
                        ...estilos.prevVis(datos.esComputadora)
                      }}
                    >
                      {generoReporte && (
                        ''
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ModalGenerarPagoPedido
