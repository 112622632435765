import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../index'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as e from './REProductoCSS'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'

import * as api from './../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'
import { listaAyuDistStyle } from '../../Pedidos/ListaPedidosCSS'

function REProducto (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [listoriginal, setlistoriginal] = useState({
    productos: []
  })
  const [filtros, setfiltros] = useState({
    productos: []
  })
  const [seleccionados, setseleccionados] = useState({
    productos: []
  })
  const [datos, setdatos] = useState({
    codProd: props.param.codigoprod,
    prodsTerPor: ''
  })
  const [elementoaniadir, setelementoaniadir] = useState(null)
  const [elementoeliminar, setelementoeliminar] = useState(null)
  const [elementoselec, setelementoselec] = useState('')
  const [actualizoventana, setactualizoventana] = useState(false)
  const [porinfluencia, setporinfluencia] = useState(0)
  const [advertencia, setAdvertencia] = useState('')
  useEffect(() => {
    console.log(props)
    const ejecucion = async () => {
      const productos = await api.obtenerProductos(usuariosesion)
      if (productos) {
        if (productos.SMListaErrores.length === 0) {
          setlistoriginal(l => ({
            ...l,
            productos: productos.SMProductosPorCategoria
          }))
          setfiltros(l => ({
            ...l,
            productos: productos.SMProductosPorCategoria
          }))
        } else {
          alert('hubo un error cargando la data')
        }
      } else {
        alert('Hubo un error, comuniquese con soporte')
      }
    }
    ejecucion()
  }, [])

  const filtrar = async e => {
    let valorBusqueda = e.currentTarget.value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase()
      .trim()

    let listaTemp = []

    listoriginal.productos.filter(e => {
      let temp = e.SMListaProductos.filter(p =>
        valorBusqueda !== ''
          ? p.dscProducto
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase()
              .trim()
              .includes(valorBusqueda)
          : true
      )
      if (temp.length > 0) {
        let na = { ...e }
        na.SMListaProductos = [...temp]
        listaTemp = [...listaTemp, na]
      }
    })
    setfiltros({ productos: listaTemp })
  }
  const aniadirproducto = e => {
    //obtiene el elemento a añadir
    if (porinfluencia !== 0 && !isNaN(porinfluencia)) {
      let listaTemp = []
      listoriginal.productos.filter(e => {
        let temp = e.SMListaProductos.filter(
          p => parseInt(p.codProducto) === parseInt(elementoaniadir.id)
        )
        if (temp.length > 0) {
          let na = { ...e }
          na.SMListaProductos = [...temp]
          listaTemp = [...listaTemp, na]
        }
      })
      let existeCategoria = false
      listaTemp.map(f => {
        f.SMListaProductos.map(g => {
          g.porinfluencia = porinfluencia
        })
      })
      console.log(listaTemp)
      //se ingresa el elemento a seleccionados
      seleccionados.productos.map(e => {
        const [{ categoria, SMListaProductos }] = listaTemp
        if (e.categoria === categoria) {
          const [prod] = SMListaProductos
          let existeprod = false
          existeCategoria = true
          e.SMListaProductos.map(p => {
            if (parseInt(p.codProducto) === parseInt(prod.codProducto)) {
              existeprod = true
            }
          })
          if (!existeprod) {
            e.SMListaProductos.push(prod)
            setelementoaniadir(null)
          }
        }
      })
      if (existeCategoria) {
      } else {
        setseleccionados({
          productos: [...seleccionados.productos, ...listaTemp]
        })
      }

      //se elimina de la lista original
      let indicecatelimin = -1
      let marcadoeliminar = false

      listoriginal.productos.map((e, indicee) => {
        let indiceprodelimin = -1
        let encontrado = false
        e.SMListaProductos.map((p, indicep) => {
          if (!marcadoeliminar) indicecatelimin = indicep
          if (parseInt(p.codProducto) === parseInt(elementoaniadir.id)) {
            indiceprodelimin = indicep
            encontrado = true
          }
        })

        if (indiceprodelimin !== -1) {
          e.SMListaProductos.splice(indiceprodelimin, 1)
          setelementoeliminar(null)
        }
        if (!marcadoeliminar && indicecatelimin === 0 && encontrado) {
          marcadoeliminar = true
          indicecatelimin = indicee
        }
      })
      filtros.productos.map((e, indicee) => {
        let indiceprodelimin = -1
        let encontrado = false
        e.SMListaProductos.map((p, indicep) => {
          if (!marcadoeliminar) indicecatelimin = indicep
          if (parseInt(p.codProducto) === parseInt(elementoaniadir.id)) {
            indiceprodelimin = indicep
            encontrado = true
          }
        })

        if (indiceprodelimin !== -1) {
          e.SMListaProductos.splice(indiceprodelimin, 1)
          setelementoeliminar(null)
        }
        if (!marcadoeliminar && indicecatelimin === 0 && encontrado) {
          marcadoeliminar = true
          indicecatelimin = indicee
        }
      })
      if (marcadoeliminar) {
        listoriginal.productos.splice(indicecatelimin, 1)
        filtros.productos.splice(indicecatelimin, 1)
        setactualizoventana(a => !a)
      }
      setporinfluencia(0)
      setAdvertencia('')
      setelementoaniadir(null)
    } else {
      setAdvertencia('novalido')
    }
  }
  const eliminarProducto = e => {
    //se añade el elemento seleccionado a la lista original
    //obtiene el elemento a añadir

    let listaTemp = []
    seleccionados.productos.filter(e => {
      let temp = e.SMListaProductos.filter(
        p => parseInt(p.codProducto) === parseInt(elementoeliminar.id)
      )
      if (temp.length > 0) {
        let na = { ...e }
        na.SMListaProductos = [...temp]
        listaTemp = [...listaTemp, na]
      }
    })
    let existeCategoria = false
    //se ingresa el elemento a seleccionados
    listoriginal.productos.map(e => {
      const [{ categoria, SMListaProductos }] = listaTemp
      if (e.categoria === categoria) {
        const [prod] = SMListaProductos
        let existeprod = false
        existeCategoria = true
        e.SMListaProductos.map(p => {
          if (parseInt(p.codProducto) === parseInt(prod.codProducto)) {
            existeprod = true
          }
        })
        if (!existeprod) {
          e.SMListaProductos.push(prod)
        }
      }
    })
    if (existeCategoria) {
    } else {
      setlistoriginal({
        productos: [...listoriginal.productos, ...listaTemp]
      })
      setfiltros({
        productos: [...listoriginal.productos, ...listaTemp]
      })
    }
    //se elimina el elemento seleccionado
    let indicecatelimin = -1
    let marcadoeliminar = false

    seleccionados.productos.map((e, indicee) => {
      let indiceprodelimin = -1
      let encontrado = false
      e.SMListaProductos.map((p, indicep) => {
        if (!marcadoeliminar) indicecatelimin = indicep
        if (parseInt(p.codProducto) === parseInt(elementoeliminar.id)) {
          indiceprodelimin = indicep
          encontrado = true
        }
      })

      if (indiceprodelimin !== -1) {
        e.SMListaProductos.splice(indiceprodelimin, 1)
        setelementoeliminar(null)
      }
      if (!marcadoeliminar && indicecatelimin === 0 && encontrado) {
        marcadoeliminar = true
        indicecatelimin = indicee
      }
    })
    if (marcadoeliminar) {
      seleccionados.productos.splice(indicecatelimin, 1)
    }
  }
  useEffect(() => {}, [porinfluencia])
  return (
    <>
      <e.Contenido $escomputadora={esComputadora}>
        <div className='nomprodparam'>
          {['mmr'].includes(props.modo) &&
            'Editando - ' + props.param.nombreprod}
          {['rpp'].includes(props.modo) &&
            'Relacionar con ' + props.param.nombreprod}
          {['mpr'].includes(props.modo) &&
            'Editando Producto - ' + props.param.nombreprod}
        </div>
        <div className='prodsProdclass input'>
          <Input
            id='prodsProd'
            moderno={'Filtrar un producto'}
            tipo='text'
            onChange={e => {
              filtrar(e)
            }}
            onClick={e => {}}
            valorInicial={
              fg.capitalizar_palabra(props.param.nombreprodrel || '') || ''
            }
            esComputadora={esComputadora}
            deshabilitado={['mpr'].includes(props.modo) ? true : false}
          />
        </div>
        <div className={'porinflu input ' + advertencia}>
          <Input
            id='porinflu'
            moderno={'% de influencia'}
            tipo='number'
            onChange={e => {
              //asignarvalor(e)
              props.param.porinfluprel=e.currentTarget.value
              //console.log(e.currentTarget.value)
              setporinfluencia(parseInt(e.currentTarget.value))
            }}
            valorInicial={props.param.porinfluprel || ''}
            esComputadora={esComputadora}
            limpiar={[0, ''].includes(porinfluencia) ? true : false}
          />
        </div>
        {['rpp'].includes(props.modo) && (
          <>
            <div className='listaProductos'>
              <div>Escoger</div>
              <div className='contenidoCuerpo'>
                {filtros.productos.length > 0 &&
                  filtros.productos.map(m => (
                    <div id={m.codigoCategoria} key={m.codigoCategoria}>
                      <span>{fg.capitalizar_palabra(m.categoria || '')}</span>
                      <div>
                        {m.SMListaProductos.length > 0 &&
                          m.SMListaProductos.map(prod => (
                            <p
                              id={prod.codProducto}
                              origen='escoger'
                              key={prod.codProducto}
                              className={
                                prod.codProducto === elementoselec
                                  ? 'activado'
                                  : 'none'
                              }
                              onClick={e => {
                                setelementoaniadir(e.currentTarget)
                                setelementoselec(parseInt(e.currentTarget.id))
                              }}
                            >
                              {fg.capitalizar_palabra(
                                '➤' + prod.dscProducto || ''
                              )}
                            </p>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <button
              className='aniadir'
              onClick={elementoaniadir !== null ? aniadirproducto : () => {}}
            >
              {'>>'}
            </button>
            <button
              className='eliminar'
              onClick={elementoeliminar !== null ? eliminarProducto : () => {}}
            >
              {'<<'}
            </button>
            <div className='listaProductosSeleccionados'>
              <div>Seleccionados</div>
              <div className='contenidoCuerpo'>
                {seleccionados.productos.length > 0 &&
                  seleccionados.productos.map(m => (
                    <div id={m.codigoCategoria} key={m.codigoCategoria}>
                      <span>{fg.capitalizar_palabra(m.categoria || '')}</span>
                      <div>
                        {m.SMListaProductos.length > 0 &&
                          m.SMListaProductos.map(prod => (
                            <p
                              id={prod.codProducto}
                              key={prod.codProducto}
                              origen='seleccionar'
                              className={
                                prod.codProducto === elementoselec
                                  ? 'activado'
                                  : 'none'
                              }
                              onClick={e => {
                                setelementoeliminar(e.currentTarget)
                                setelementoselec(parseInt(e.currentTarget.id))
                                setelementoaniadir(null)
                                //setelementoselec(parseInt(e.currentTarget.id))
                              }}
                            >
                              {'➤ ' +
                                prod.porinfluencia +
                                '% -' +
                                fg.capitalizar_palabra(prod.dscProducto || '')}
                            </p>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
        <div className='aceptarboton input'>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Aceptar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={async e => {
              //[1],[[codproducto, porcentaje]]
              //RelacionarPProduccionPTerminado
              let request = []
              if (!['mpr'].includes(props.modo)) {
                seleccionados.productos.map(f => {
                  f.SMListaProductos.map(g => {
                    request.push([g.codProducto, g.porinfluencia])
                  })
                })
                console.log(datos.codProd, request)
              } else {
                request.push([props.param.productorel,props.param.porinfluprel])
              }
              console.log(request)
              const relacionar = await api.RelacionarPProduccionPTerminado(
                [datos.codProd],
                request,
                'N'
              )
              if (relacionar) {
                props.cerrarmodal()
              }
            }}
          />
        </div>
        <div className='cancelarboton input'>
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Cancelar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={e => {
              //setTipoAccion(e.currentTarget.id)
              props.cerrarmodal()
            }}
          />
        </div>
      </e.Contenido>
    </>
  )
}

export default REProducto
