const g_moneda_localidad = 'es-PE'
const g_moneda_tipoMoneda = 'PEN' //'PEN'
const g_color = '' //#CCCCCC
const g_boton_color = ''
const g_input_maxDigitos = 40

export const hex_rgb = (hex, opacidad) => {
  hex = g_color !== '' ? g_color.replace('#', '') : hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}

export const formatear_moneda = (valor, tipoMoneda) => {
  return valor.toLocaleString(`${g_moneda_localidad}`, {
    style: 'currency',
    currency: `${g_moneda_tipoMoneda !== '' ? g_moneda_tipoMoneda : tipoMoneda}`
  })
}

export const capitalizar_palabra = texto => {
  let palabras = texto.toLowerCase().split(' ')
  let capitalizarPalabras = palabras.map(palabra => {
    return palabra.charAt(0).toUpperCase() + palabra.slice(1)
  })
  return capitalizarPalabras.join(' ')
}

export const formatear_fecha_ddmmyyyy = (fechaApi, modo = 'dd/mm/yyyy') => {
  if (modo === 'dd/mm/yyyy') {
    const partes = fechaApi.split('-')
    if (partes.length !== 3)
      throw new Error('Formato de fecha incorrecto. Debe ser yyyy-mm-dd.')
    const anio = partes[0]
    const mes = partes[1]
    const dia = partes[2]
    return `${dia}/${mes}/${anio}`
  } else if ((modo = 'yyyy-mm-dd')) {
    const partes = fechaApi.split('/')
    if (partes.length !== 3)
      throw new Error('Formato de fecha incorrecto. Debe ser dd/mm/yyyy.')
    const dia = partes[0]
    const mes = partes[1]
    const anio = partes[2]
    return `${anio}-${mes}-${dia}`
  }
}

export const formatear_fecha_ddmmyyyy_hora12 = (dateTimeStr, modo) => {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.\d{3})?Z$/
  const match = dateTimeStr.match(regex)
  if (!match) {
    console.error('Formato de fecha y hora no válido:', dateTimeStr)
    return null
  }
  const [, year, month, day, hour, minute] = match

  let formattedHour = parseInt(hour, 10)
  const ampm = formattedHour >= 12 ? 'pm' : 'am'
  formattedHour = formattedHour % 12
  formattedHour = formattedHour ? formattedHour : 12 // Hora '0' debería ser '12'

  const formattedTime = `${formattedHour}:${minute} ${ampm}`
  const formattedDate = `${day}/${month}/${year}`

  if (modo === 'H') {
    const formattedDateTime = `${formattedTime}`
    return formattedDateTime
  } else if (modo === 'AH') {
    const formattedDateTime = `${formattedDate} ${formattedTime}`
    return formattedDateTime
  } else if (modo === 'A') {
    const formattedDateTime = `${formattedDate}`
    return formattedDateTime
  }
  return ''
}

export const rbga_tonalidad = (
  color = 'rgba(255,255,255,1)',
  reduccion = 40
) => {
  let match = color.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/
  )
  if (!match) {
    color = hex_rgb(color, 1)
    //return color
    match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/)
  }
  if (match) {
    let [_, r, g, b, a] = match.map(Number)
    if (isNaN(a)) a = 1

    r = Math.max(0, r - reduccion)
    g = Math.max(0, g - reduccion)
    b = Math.max(0, b - reduccion)

    return `rgba(${r},${g},${b},${a})`
  }
  return 'transparent'
}

export const colorBotonGenerico = () => {
  return g_boton_color
}

export const maxDigitosInput = () => {
  return g_input_maxDigitos
}

export const fecha_hora_actual = () => {
  const now = new Date()

  // Obtener cada componente de la fecha y hora
  const day = String(now.getDate()).padStart(2, '0') // Día (01-31)
  const month = String(now.getMonth() + 1).padStart(2, '0') // Mes (01-12)
  const year = now.getFullYear() // Año (yyyy)
  const hours = String(now.getHours()).padStart(2, '0') // Horas (00-23)
  const minutes = String(now.getMinutes()).padStart(2, '0') // Minutos (00-59)
  const seconds = String(now.getSeconds()).padStart(2, '0') // Segundos (00-59)

  // Formatear la cadena en el formato deseado
  const formattedDateTime = `${day}_${month}_${year}_${hours}_${minutes}_${seconds}`

  return formattedDateTime
}

export const obtenerHoraActual = () => {
  const ahora = new Date()
  const horas = ahora.getHours().toString().padStart(2, '0')
  const minutos = ahora.getMinutes().toString().padStart(2, '0')
  const segundos = ahora.getSeconds().toString().padStart(2, '0')
  return `${horas}:${minutos}:${segundos}`
}

export const obtenerFechaActual = () => {
  const ahora = new Date()

  const opcionesFecha = { year: 'numeric', month: '2-digit', day: '2-digit' }
  const fecha = ahora.toLocaleDateString('es-ES', opcionesFecha)

  return `${fecha}`
}
//Parte de Carlos

export const concatenarPalabras = (p1, p2) => {
  return p1 + '' + p2
}

export const fechayhoraactualStr = () => {
  const ahora = new Date()

  const opcionesFecha = { year: 'numeric', month: '2-digit', day: '2-digit' }
  const opcionesHora = { hour: '2-digit', minute: '2-digit', second: '2-digit' }

  let fecha = ahora.toLocaleDateString('es-ES', opcionesFecha)
  let hora = ahora.toLocaleTimeString('es-ES', opcionesHora)

  // Reemplazar caracteres no válidos para nombres de archivo
  fecha = fecha.replace(/\//g, '-')
  hora = hora.replace(/:/g, '-')

  return `Fecha ${fecha} Hora_${hora}`
}

export const formatearDecimal = (numero, cantDecimal) => {
  return parseFloat(numero).toFixed(cantDecimal)
}

export const hexToRGBA = (hex, opacidad) => {
  hex = hex.replace('#', '')
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${opacidad})`
}

export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

function componentToHex (c) {
  var hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}
