import styled, { css } from 'styled-components'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .nomprodparam {
    grid-area: 1/1/1/21;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => (props.$escomputadora ? '1.2' : '3')}vw;
    font-family: Arial;
  }
  --pad-interno: 0.5vw;
  .input {
    padding: var(--pad-interno);
  }
  .prodsProdclass {
    grid-area: 2/1/3/10;
  }
  .porinflu {
    grid-area: 2/12/3/21;
  }
  .listaProductos,
  .listaProductosSeleccionados {
    grid-area: 3/1/20/10;
    background-color: white;
    border: 0.1vw solid #cccccc;
    border-radius: 0.5vw;
    //overflow: auto;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    //position: relative;
  }
  .contenidoCuerpo {
    box-sizing: border-box;
  }
  .listaProductos > .contenidoCuerpo > div,
  .listaProductosSeleccionados > .contenidoCuerpo > div {
    font-size: ${props => (props.$escomputadora ? '0.7' : '2.5')}vw;
    padding-left: ${props => (props.$escomputadora ? '1.2' : '2')}vw;
    font-weight: 500;
    margin-bottom: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    width: 120%;
    background-color: rgb(255, 255, 255);
    padding: ${props => (props.$escomputadora ? '0.1vw 0.1vw' : '2')};
  }
  .listaProductos > .contenidoCuerpo > div > div > p,
  .listaProductosSeleccionados > .contenidoCuerpo > div > div > p {
    font-weight: 400;
    padding: 0;
    margin: 0;
    padding-left: ${props => (props.$escomputadora ? '1.2' : '2')}vw;
    font-size: ${props => (props.$escomputadora ? '0.7' : '2')}vw;
  }
  div > div > p:hover {
    background-color: green;
    cursor: pointer;
    background-color: #cccccc;
  }
  .aceptarboton {
    grid-area: 20/2/21/10;
  }
  .cancelarboton {
    grid-area: 20/12/21/20;
  }

  input[vacio='vacio'],
  select[vacio='vacio'] {
    outline: 0.1vw solid rgb(255, 0, 0);
  }

  .listaProductosSeleccionados {
    grid-area: 3/12/20/21;
  }
  .listaProductos > div:first-child,
  .listaProductosSeleccionados > div:first-child {
    background-color: rgb(105, 105, 105);
    color: white;
    height: 10%;
    width: 100%;
    font-size: ${props => (props.$escomputadora ? '1' : '2.8')}vw;
    font-family: Verdana;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listaProductos > .contenidoCuerpo,
  .listaProductosSeleccionados > .contenidoCuerpo {
    width: 100%;
    height: 90%;
    overflow: auto;
  }
  .aniadir {
    grid-area: 9/10/11/12;
    background-color: #2d8b0b;
  }
  .eliminar {
    grid-area: 12/10/14/12;
    background-color: #e12121;
  }
  .aniadir,
  .eliminar {
    border: none;
    color: white;
    font-weight: 900;
    font-family: Arial;
    margin: 0.2vw;
  }
  .aniadir:hover,
  .eliminar:hover {
    cursor: pointer;
    box-shadow: 0.1vw 0.1vw 0.5vw black;
  }

  div > div > .activado {
    background-color: rgb(72, 228, 66) !important;
  }
  .novalido > div > input[type='number']#porinflu {
    border: solid red 1px ;
    background-color:rgb(255, 233, 233);
   
  }
`
