import {
  BlobProvider,
  Page,
  Text,
  View,
  Document,
  Image
} from '@react-pdf/renderer'
import React, { useState, useEffect, useContext } from 'react'
import XLSX from 'xlsx-js-style'
import * as e from './PedidoPdfCSS'
import { Contexto } from '../../../../../Componentes/Principal/index'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import LogoSanMiguel from './../../../../../Imagenes/LogoSanMiguel.png'

function PedidoPdf (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [primeracarga, setprimeracarga] = useState(null)
  const [listadoMaestros, setListadoMaestros] = useState(null)
  const [produccionMaestros, setProduccionMaestros] = useState(null)
  const [columnaspdf, setcolumnaspdf] = useState([])
  const numbers = Array.from({ length: 80 }, (_, index) => index)
  const [finalizo, setFinalizo] = useState(false)
  useEffect(() => {
    const llamado = async () => {
      const maestros = await api.listarMaestros(null, 'S')
      if (maestros) {
        if (maestros.SMListaErrores.length === 0) {
          setListadoMaestros(maestros.maestro)
          let produccionesTemp = {}
          const promesas = maestros.maestro.map(async e => {
            const llamado = await api.listaIngresoProductoMaestro(
              null,
              e.usuario,
              'N'
            )
            if (llamado) {
              if (llamado.SMListaErrores.length === 0) {
                produccionesTemp = {
                  ...produccionesTemp,
                  [llamado.produccion.maestro.usuario]: llamado
                }
              } else {
                alert('Hubo un error. Se recargara la página.')
                window.location.reload()
              }
            } else {
              alert(
                'Hubo un problema con la conección. Se recargara la página.'
              )
              window.location.reload()
            }
          })
          await Promise.all(promesas)

          setprimeracarga(false)
          setProduccionMaestros(produccionesTemp)
        } else {
          alert('Hubo un error. Se recargara la página.')
          window.location.reload()
        }
      } else {
        alert('Hubo un problema con la conección. Se recargara la página.')
        window.location.reload()
      }
    }
    llamado()
  }, [])

  useEffect(() => {
    if (!primeracarga && produccionMaestros) {
      const data = []
      Object.keys(produccionMaestros).map(e => {
        let count = produccionMaestros[e].productosJson.length
        data.push({ [count]: { [e]: produccionMaestros[e] } })
        //console.log(e, produccionMaestros[e].productosJson.length + 2)
      })
      const getKey = obj => Object.keys(obj)[0]
      data.sort((a, b) => getKey(a) - getKey(b))

      let orderData = dividir(data)
      let dataFinal = []
      orderData.map(e => {
        let temp = {}
        e.map(f => {
          const [dm = {}] = listadoMaestros.filter(
            lm => lm.usuario === Object.keys(f[Object.keys(f)])[0]
          )
          delete f[Object.keys(f)][Object.keys(f[Object.keys(f)])]
            .SMListaErrores
          delete f[Object.keys(f)][Object.keys(f[Object.keys(f)])].SMServInfo
          f[Object.keys(f)][Object.keys(f[Object.keys(f)])].datosMaestro = dm

          temp = { ...temp, ...f[Object.keys(f)] }
          //console.log(f[Object.keys(f)][Object.keys(f[Object.keys(f)])])
        })
        dataFinal.push(temp)
      })
      setcolumnaspdf(dataFinal)
      setFinalizo(true)
      //console.log(listadoMaestros)
    }
  }, [produccionMaestros])

  function dividir (data) {
    let lon = data.length
    let mid = Math.floor(lon / 2 - 1)
    let arrayDivided = [[], []]
    //console.log('importantes', lon, mid)
    if (lon > 2) {
      for (let i = 0; i <= mid; i++) {
        if (i % 2 === 0) {
          arrayDivided[0].push(data[i], data[lon - 1 - i])
        } else {
          arrayDivided[1].push(data[i], data[lon - 1 - i])
        }
      }
    }
    if (lon % 2 !== 0) {
      arrayDivided[lon === 1 ? 0 : 1].push(data[mid + 1])
    } else if (lon === 2) {
      arrayDivided[0].push(data[0])
      arrayDivided[1].push(data[1])
    }

    return arrayDivided
  }
  const pdfDocument = () => (
    <Document
      title='San Miguel Reporte Generar Pedido de Producción'
      author='San Miguel'
      subject='Reporte'
      keywords='Reporte San Miguel'
      pdfVersion='1.5'
      wrap='true'
    >
      <Page size='A4' style={e.docpdf.page}>
        <View style={e.docpdf.headerDocument}>
          <View style={e.docpdf.informationDocument}>
            <Text>
              {'Fecha: ' +
                fg.obtenerFechaActual() +
                '    Hora: ' +
                fg.obtenerHoraActual()}
            </Text>
            <Text>{usuariosesion}</Text>
          </View>
          <View style={e.docpdf.titleDocument}>
            <Text>Pedidos de Producción</Text>
          </View>
          <Text style={e.docpdf.logo}>
            <Image
              style={{
                width: '10cm',
                marginVertical: 15,
                marginHorizontal: 100
              }}
              src={LogoSanMiguel}
            />
          </Text>
        </View>
        <View style={e.docpdf.bodyDocument}>
          {columnaspdf.length > 0 &&
            columnaspdf.map((cpdf, kpdf) => {
              //console.log(cpdf)
              return (
                <View key={kpdf} style={e.docpdf.columnsPage}>
                  <View style={e.docpdf.containCard}>
                    {Object.keys(cpdf).map(ma => {
                      let datosMaestro = cpdf[ma].datosMaestro
                      return (
                        <View key={ma} style={e.docpdf.card}>
                          <View style={e.docpdf.headerCard}>
                            <Text>
                              {fg.capitalizar_palabra(
                                datosMaestro.nombre +
                                  ' ' +
                                  datosMaestro.apellido_paterno +
                                  ' ' +
                                  datosMaestro.apellido_materno || ''
                              )}
                            </Text>
                          </View>
                          <View style={e.docpdf.containTitleCellCard}>
                            <Text style={e.docpdf.tittleCellCard}>
                              Nombre del Producto
                            </Text>
                            <Text style={e.docpdf.tittleCellCard}>
                              Cantidad Solicitada
                            </Text>
                            <Text style={e.docpdf.tittleCellLastCard}>
                              Cantidad Producida
                            </Text>
                          </View>
                          <View style={e.docpdf.bodyCard}>
                            {cpdf[ma].productosJson.map(prd => {
                              return (
                                <View
                                  key={prd.producto.producto}
                                  style={e.docpdf.containTitleCellCard}
                                >
                                  <Text style={e.docpdf.dataCellCard}>
                                    {fg.capitalizar_palabra(
                                      prd.producto.descripcion
                                    )}
                                  </Text>
                                  <Text style={e.docpdf.dataCellCard}>
                                    {prd.detalle.solicitado}
                                  </Text>
                                  <Text
                                    style={e.docpdf.dataCellLastCard}
                                  ></Text>
                                </View>
                              )
                            })}
                          </View>
                        </View>
                      )
                    })}
                  </View>
                </View>
              )
            })}
        </View>
        <Text
          style={e.docpdf.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )

  return (
    <>
      <BlobProvider document={pdfDocument()}>
        {({ blob, url, loading, error }) => {
          if (loading) return <div>Cargando...</div>
          if (error) return <div>Error al cargar el PDF</div>

          return (
            finalizo && (
              <e.Contenido $escomputadora={esComputadora}>
                <div className='PDFContent'>
                  {/* Mostrar el PDF en el iframe */}
                  <iframe className='pdf' src={url} title='PDF Viewer' />

                  {/* Enlace de descarga con nombre personalizado */}
                </div>
                <div className='PDFContentDownlaod'>
                  <a
                    href={url}
                    download={
                      'Pedidos Producción (' + fg.fechayhoraactualStr() + ')'
                    }
                  >
                    Descargar PDF
                  </a>
                </div>
              </e.Contenido>
            )
          )
        }}
      </BlobProvider>
    </>
  )
}

export default PedidoPdf
