import { StyleSheet } from '@react-pdf/renderer'
import styled from 'styled-components'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .PDFContent {
    grid-area: 1/1/20/21;
  }
  iframe.pdf {
    width: 100%;
    height: 100%;
  }

  .PDFContentDownlaod {
    grid-area: 20/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
  }
  .PDFContentDownlaod > a {
    color: white;
    background-color: #2d8b0b;
    text-decoration: none;
    border-radius: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    padding: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    box-sizing: border-box;
    padding: ${props => (props.$escomputadora ? '0.5' : '1')}vw;
  }
  .PDFContentDownlaod > a:hover {
    background-color: rgb(36, 110, 9);
    box-shadow: 0.1vw 0.1vw 0.1vw rgb(116, 116, 116);
    
  }
`
const widthColumnPage = '50%'
//DOCUMENTO PDF
export const docpdf = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '5px',
    margin: '0',
    display: 'flex',
    flexDirection: 'column'
  },
  headerDocument: {
    flexDirection: 'row',
    margin: '5px 5px 0 5px',
    alignItems: 'center'
  },
  logo: {
    width: '30%',
    textAlign: 'right'
  },
  titleDocument: {
    width: '40%',
    fontSize: '3vw',
    textAlign: 'center'
  },
  informationDocument: {
    width: '30%',
    fontSize: '1.2vw',
    flexDirection: 'column',
    color: 'grey'
  },
  bodyDocument: {
    flexDirection: 'row'
  },
  columnsPage: {
    flexDirection: 'row',
    width: widthColumnPage,
    padding: '5px'
  },
  containCard: {
    flexDirection: 'column',
    width: '100%'
    //border: '1px solid black'
  },
  card: {
    flexDirection: 'column',
    fontSize: '1.2vw',
    width: '100%',
    border: '1px solid black',
    height: 'auto',
    marginTop: '5px'
  },
  headerCard: {
    //borderBottom: '1px solid black',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#AD6726',
    color: 'white',
    fontSize: '10px'
  },
  bodyCard: {
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  containTitleCellCard: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'white',
    height: '10px',
    borderTop: '0.7px solid black'
  },
  tittleCellCard: {
    width: '33.3%',
    backgroundColor: '#fdf9e2',
    textAlign: 'center',
    borderRight: '0.7px solid black'
  },
  tittleCellLastCard: {
    width: '33.3%',
    backgroundColor: '#fdf9e2',
    textAlign: 'center'
  },
  dataCellCard: {
    width: '33.3%',
    backgroundColor: 'white',
    textAlign: 'center',
    borderRight: '0.7px solid black'
  },
  dataCellLastCard: {
    width: '33.3%',
    backgroundColor: '#eeeeee',
    textAlign: 'center'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: '1.5vw',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey'
  }
})
//3508
