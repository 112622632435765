import { useContext, useEffect, useState } from "react";
import { Contexto } from "../../../../../Componentes/Principal/index";

import Boton from "../../../../../Elementos/Boton";
import Input from "../../../../../Elementos/Input";
import ModalGenerico from "./../../../../../Elementos/ModalGenerico";
import * as api from "./../../../../../Api/Api";
import * as fg from "../../../../../Api/FuncionesGlobales";
import * as e from "./VerPedidoDetalleCSS";
import AgregarAyudante from "./AgregarAyudante";
import DetallePDF from "./DetallePDF";
import AgregarProductoProd from "../GenerarPedidoProduccion/AgregarProductoProd";

function VerProduccionDetalle(props) {
  const { esComputadora, rol } = useContext(Contexto);
  const { pedido } = props.param;

  const [estaProcesando, setestaProcesando] = useState(false);
  const [solicitud, setsolicitud] = useState(true);
  const [produccion, setproduccion] = useState(false);
  const [productos, setproductos] = useState([]);
  const [ayudantes, setAyudantes] = useState([]);

  //MODAL
  const [vermodal, setvermodal] = useState(false);
  const [dispotivo, setdispositivo] = useState([]); //ayuda a identificar si es wc, hc o wm, hm
  const [opclick, setopclick] = useState("");
  const [primeracarga, setprimeracarga] = useState(true);
  const [param, setparam] = useState({
    pedido: null,
    ayudantes: null,
  });
  const [opmodal, setopmodal] = useState({
    app: { wc: 40, hc: 30, wm: 70, hm: 25, t: "Agregar Ayudante" },
    agp: { wc: 40, hc: 60, wm: 70, hm: 50, t: "Agregar Producto Producción" },
    pdf: { wc: 50, hc: 90, wm: 90, hm: 70, t: "Ver pdf" },
  });
  //FIN MODAL

  useEffect(() => {
    //if (pedido) console.log(pedido)
    setproductos(pedido.productosJson);
    setAyudantes(pedido.produccion.ayudantes);
    setprimeracarga(false);
  }, []);

  const salgodeinputTerminado = async (e) => {
    if (pedido.produccion.estado === 2) {
      setestaProcesando(true);
    }
    let codProduccion = parseInt(pedido.produccion.ingreso);
    let codProducto = parseInt(e.currentTarget.parentNode.parentNode.id);

    let valor =
      e.currentTarget.value === "" ? 0 : parseFloat(e.currentTarget.value);
    const editarInput = await api.editarIngresoProductoMaestro(
      parseInt(codProduccion),
      parseFloat(valor),
      2,
      parseInt(codProducto)
    );
    console.log(editarInput);
    if (editarInput) {
      if (editarInput.SMListaErrores.length === 0) {
        let subtotal = document.getElementById(codProducto + "T");
        if (subtotal) {
          subtotal.innerHTML = fg.formatear_moneda(
            editarInput.productoJson.subtt_entr
          );
        }
        let paquetesU = document.getElementById(codProducto + "PU");
        let paquetes = document.getElementById(codProducto + "P");
        if (paquetesU && paquetes) {
          paquetes.innerHTML = parseInt(paquetesU.innerHTML) * valor;
        }

        let total = document.getElementById("total");
        if(total){
          total.innerHTML =
          "TOTAL: " +
          fg.formatear_moneda(editarInput.produccion.total_terminado);
        }
       
        if (pedido.produccion.estado === 2) {
          let totalM = document.getElementById("pagoM");
          if (totalM){
            totalM.innerHTML = fg.formatear_moneda(
            editarInput.produccion.pago_maestro
          );
          setAyudantes(editarInput.produccion.ayudantes);}
        }
      } else {
        alert("Hubo un error. Se recargara la página.");
        window.location.reload();
      }
    } else {
      alert("Hubo un problema con la conección. Se recargara la página.");
      window.location.reload();
    }
    setestaProcesando(false);
  };
  const salgodeinputSolicitud = async (e) => {
    let codProduccion = parseInt(pedido.produccion.ingreso);
    let codProducto = parseInt(e.currentTarget.parentNode.parentNode.id);
    let valor =
      e.currentTarget.value === "" ? 0 : parseFloat(e.currentTarget.value);
    const editarInput = await api.editarIngresoProductoMaestro(
      parseInt(codProduccion),
      parseFloat(valor),
      1,
      parseInt(codProducto)
    );
    if (editarInput) {
      if (editarInput.SMListaErrores.length === 0) {
      } else {
        alert("Hubo un error. Se recargara la página.");
        window.location.reload();
      }
    } else {
      alert("Hubo un problema con la conección. Se recargara la página.");
      window.location.reload();
    }
  };
  const editarSolicitud = async (event) => {
    //editar solicitud
    let obtenerPedido = [];
    if (solicitud) {
      {
        pedido.produccion.estado === 1 && setproduccion(true);
      }
      setsolicitud(false);
      obtenerPedido = await api.listaIngresoProductoMaestro(
        parseInt(pedido.produccion.ingreso),
        pedido.produccion.maestro.usuario,
        "S"
      );
    }
    // guardar la edicion de la solicitud
    else {
      setproduccion(false);
      setsolicitud(true);
      obtenerPedido = await api.listaIngresoProductoMaestro(
        parseInt(pedido.produccion.ingreso),
        pedido.produccion.maestro.usuario,
        "N"
      );
    }
    if (obtenerPedido && obtenerPedido.SMListaErrores.length === 0) {
      setproductos(obtenerPedido.productosJson);
    } else {
      alert("Hubo un problema con la conección. Se recargara la página.");
      window.location.reload();
    }
  };
  const editarProduccion = async (event) => {
    //editar solicitud
    console.log(produccion);
    if (!produccion && pedido.produccion.estado === 2) {
      setproduccion(true);
    }

    // guardar la edicion de la solicitud
    else {
      setproduccion(false);
      setestaProcesando(true);
      if (pedido.produccion.estado === 1) {
        const guardar = await api.guardarIngresoProductoMaestro(
          pedido.produccion.ingreso,
          2
        );
        if (guardar && guardar.SMListaErrores.length === 0) {
        } else {
          alert("Hubo un problema con la conección. Se recargara la página.");
          window.location.reload();
        }
      }
      props.cerrarmodal();
    }
  };
  useEffect(() => {
    console.log("entre en primera carga", !primeracarga, opclick !== "");
    if (!primeracarga && opclick !== "") {
      console.log("entre despues de la primera carga");
      setdispositivo(esComputadora ? ["wc", "hc"] : ["wm", "hm"]);
      setvermodal(true);
    }
  }, [opclick, esComputadora]);
  const cerrarModal = async () => {
    setestaProcesando(true);
    setvermodal(false);
    if (opclick === "app") {
      const ayudantesActualizados = await api.listaAyudanteProduccion(
        parseInt(param.pedido)
      );
      if (
        ayudantesActualizados &&
        ayudantesActualizados.SMListaErrores.length === 0
      ) {
        setAyudantes(ayudantesActualizados.ayudantes);
        let total = document.getElementById("pagoM");
        if (total){
          total.innerHTML = fg.formatear_moneda(
          ayudantesActualizados.maestro.pago_maestro
        );}
      } else {
        alert("Hubo un error. Se recargara la página.");
        window.location.reload();
      }
    } else if (opclick === "agp") {
      const usuario = param.pedido.maestro.usuario;
      const llamado = await api.listaIngresoProductoMaestro(
        param.pedido.ingreso,
        usuario,
        "S"
      );
      if (llamado) {
        if (llamado.SMListaErrores.length === 0) {
          console.log(llamado);
          console.log(productos);
          setproductos(llamado.productosJson);
        } else {
          alert("Hubo un error. Se recargara la página.");
          window.location.reload();
        }
      } else {
        alert("Hubo un problema con la conección. Se recargara la página.");
        window.location.reload();
      }
    }
    setestaProcesando(false);
  };
  const clickAgregarAyudante = (event) => {
    setvermodal(true);
    setopclick(!primeracarga ? "app" : "");
  };
  const clickVerPDF = (event) => {
    setparam(
      !primeracarga && {
        ...param,
        pedido: pedido.produccion.ingreso,
      }
    );

    setvermodal(true);
    setopclick(!primeracarga ? "pdf" : "");
  };
  const clickEliminarAyudante = async (event) => {
    setestaProcesando(true);
    console.log("eliminar ayudante");
    console.log(event.currentTarget.parentNode.parentNode.parentNode.id);
    const ayudanteEliminado = await api.actualizarAyudanteProduccion(
      parseInt(pedido.produccion.ingreso),
      event.currentTarget.parentNode.parentNode.parentNode.id,
      null,
      "S"
    );
    console.log(ayudanteEliminado);
    if (ayudanteEliminado && ayudanteEliminado.SMListaErrores.length === 0) {
      const ayudantesActualizados = await api.listaAyudanteProduccion(
        parseInt(pedido.produccion.ingreso)
      );
      console.log(ayudantesActualizados);
      if (
        ayudantesActualizados &&
        ayudantesActualizados.SMListaErrores.length === 0
      ) {
        setAyudantes(ayudantesActualizados.ayudantes);
        let total = document.getElementById("pagoM");
        if (total){
          total.innerHTML = fg.formatear_moneda(
          ayudantesActualizados.maestro.pago_maestro
        );}
      } else {
        alert("Hubo un error. Se recargara la página.");
        window.location.reload();
      }
    } else {
      alert("Hubo un error. Se recargara la página.");
      window.location.reload();
    }
    setestaProcesando(false);
  };
  const salgoInputPagoAyudante = async (e) => {
    setestaProcesando(true);
    console.log("actualizar pago ayudante");
    console.log(e.currentTarget.parentNode.parentNode.parentNode.id);
    const ayudanteActualizado = await api.actualizarAyudanteProduccion(
      parseInt(pedido.produccion.ingreso),
      e.currentTarget.parentNode.parentNode.parentNode.id,
      e.currentTarget.value === "" ? 0 : parseFloat(e.currentTarget.value),
      "N"
    );
    console.log(ayudanteActualizado);
    if (
      ayudanteActualizado &&
      ayudanteActualizado.SMListaErrores.length === 0
    ) {
      const ayudantesActualizados = await api.listaAyudanteProduccion(
        parseInt(pedido.produccion.ingreso)
      );
      console.log(ayudantesActualizados);
      if (
        ayudantesActualizados &&
        ayudantesActualizados.SMListaErrores.length === 0
      ) {
        setAyudantes(ayudantesActualizados.ayudantes);
        let total = document.getElementById("pagoM");
        if (total){
          total.innerHTML = fg.formatear_moneda(
          ayudantesActualizados.maestro.pago_maestro
        );}
      } else {
        alert("Hubo un error. Se recargara la página.");
        //window.location.reload()
      }
    } else {
      alert("Hubo un error. Se recargara la página.");
      //window.location.reload()
    }
    setestaProcesando(false);
  };
  const clickAgregarProductoEvento = (event) => {
    setparam(
      !primeracarga && {
        ...param,
        pedido: pedido.produccion,
      }
    );
    console.log(event);
    setvermodal(true);
    setopclick(!primeracarga ? "agp" : "");
  };

  return (
    <>
      {vermodal && dispotivo.length > 0 && (
        <ModalGenerico
          capa={1}
          width={opmodal[opclick][dispotivo[0]]}
          height={opmodal[opclick][dispotivo[1]]}
          titulo={opmodal[opclick].t}
          cerrarmodal={cerrarModal}
        >
          {["app"].includes(opclick) && (
            <AgregarAyudante cerrarmodal={cerrarModal} param={param} />
          )}
          {["agp"].includes(opclick) && (
            <AgregarProductoProd cerrarmodal={cerrarModal} param={param} />
          )}
          {["pdf"].includes(opclick) && (
            <DetallePDF cerrarmodal={cerrarModal} param={param} />
          )}
        </ModalGenerico>
      )}
      <e.Contenido $escomputadora={esComputadora} $rol={rol}>
        <div className="pedido">
          <div className="cabecera">
            <div>
              {fg.capitalizar_palabra(
                pedido &&
                  pedido.produccion.maestro.nombre +
                    " " +
                    pedido.produccion.maestro.apellido_paterno
              )}
              {rol === "SUPER ADMINISTRADOR" && (
                <div id="pagoM" key="pagoM">
                  {pedido.produccion.estado === 2
                    ? fg.formatear_moneda(pedido.produccion.pago_maestro)
                    : null}
                </div>
              )}
            </div>
            <div>Nro. {String(pedido.produccion.ingreso).padStart(8, "0")}</div>
          </div>
          <div className="areaayudantes">
            <div className="ayudantes">
              {ayudantes &&
                ayudantes.map((e) => (
                  <div
                    className="ayudante"
                    id={e.ayudante.usuario}
                    key={e.ayudante.usuario}
                  >
                    <div>
                    {(rol === "SUPER ADMINISTRADOR"||pedido.produccion.estado===1)&&<Boton
                        style={{
                          backgroundColor: "white",
                          border: "0.2vw solid rgb(143, 143, 143)",
                          borderRadius: "50%",
                          width: esComputadora ? "1.85vw" : "4.5vw",
                          height: "90%",
                          justifyContent: "center",
                          //padding: esComputadora ? '0.5vw' : '1vw'
                        }}
                        Promise
                        textoStyle={{
                          color: "rgb(155, 153, 153)",
                          width: "100%",
                          fontSize: esComputadora ? "1.3vw" : "2.2vw",
                        }}
                        texto="X"
                        tipo="submit"
                        estaProcesando={estaProcesando}
                        onClick={(event) => {
                          clickEliminarAyudante(event);
                        }}
                      />}
                    </div>
                    <div>
                      {fg.capitalizar_palabra(
                        e.ayudante.nombre + " " + e.ayudante.apellido_paterno
                      )}
                    </div>
                    <div>
                      {pedido.produccion.estado === 2 &&
                      rol === "SUPER ADMINISTRADOR" ? (
                        <Input
                          tipo="number"
                          maxDigitos="8"
                          onChange={(e) => {
                            if (
                              e.currentTarget.value === "" &&
                              !estaProcesando
                            ) {
                              salgoInputPagoAyudante(e);
                            }
                          }}
                          id={e.ayudante.usuario+"style"}
                          moderno=''
                          onBlur={!estaProcesando && salgoInputPagoAyudante}
                          valorInicial={e.detalle.pago}
                          esComputadora={esComputadora}
                          deshabilitado={estaProcesando}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
            {(rol === "SUPER ADMINISTRADOR" || pedido.produccion.estado === 1) && (
              <div className="agregarAyudante">
                <Boton
                  style={{
                    backgroundColor: "rgb(220, 173, 47)",
                    borderRadius: esComputadora ? "0.5vw" : "1vw",
                    padding: esComputadora ? "0.5vw" : "1vw",
                  }}
                  Promise
                  textoStyle={{
                    color: "black",
                    width: "100%",
                    fontSize: esComputadora ? "1.3vw" : "2.75vw",
                    padding: "0",
                  }}
                  texto="Agregar Ayudante"
                  tipo="submit"
                  estaProcesando={estaProcesando}
                  onClick={(event) => {
                    setparam(
                      !primeracarga &&
                        ayudantes && {
                          ...param,
                          pedido: pedido.produccion.ingreso,
                          ayudantes: ayudantes,
                          agregarP: null,
                        }
                    );
                    clickAgregarAyudante(event);
                  }}
                />
              </div>
            )}
          </div>
          <div className="cuerpo">
            <div className="descripcion">
              <div> Producto</div>
              <div> Paq. x Prod.</div>
              <div> Precio Unit.</div>
              <div> Solicitado</div>
              <div> Terminado</div>
              {rol === "SUPER ADMINISTRADOR" && <div> Sub Total</div>}
              <div> Paq. Aprox.</div>
            </div>
            <div className="detallePedido">
              {productos &&
                productos.map((e) => (
                  <div
                    className="productos"
                    id={e.detalle.producto}
                    key={e.detalle.producto}
                  >
                    <div>{fg.capitalizar_palabra(e.producto.descripcion)}</div>
                    <div id={e.detalle.producto + "PU"}>
                      {e.producto.paquetes}
                    </div>
                    <div>{e.detalle.precio_unit}</div>
                    {!solicitud ? (
                      <Input
                        tipo="number"
                        maxDigitos="4"
                        onChange={(e) => {
                          if (e.currentTarget.value === "" && !estaProcesando) {
                            salgodeinputSolicitud(e);
                          }
                        }}
                        onBlur={!estaProcesando && salgodeinputSolicitud}
                        valorInicial={e.detalle.solicitado !== 0 ? e.detalle.solicitado : ''}
                        esComputadora={esComputadora}
                        deshabilitado={estaProcesando}
                      />
                    ) : (
                      <div>{e.detalle.solicitado}</div>
                    )}

                    {pedido.produccion.estado === 1 || produccion ? (
                      <Input
                        tipo="number"
                        maxDigitos="4"
                        onChange={(e) => {
                          if (e.currentTarget.value === "" && !estaProcesando) {
                            salgodeinputTerminado(e);
                          }
                        }}
                        onBlur={!estaProcesando && salgodeinputTerminado}
                        valorInicial={e.detalle.ingreso !== 0 ? e.detalle.ingreso : ''}
                        esComputadora={esComputadora}
                        deshabilitado={!solicitud || estaProcesando}
                      />
                    ) : (
                      <div>{e.detalle.ingreso}</div>
                    )}

                    {rol === "SUPER ADMINISTRADOR" && (
                      <div id={e.detalle.producto + "T"}>
                        {fg.formatear_moneda(e.detalle.subtt_entr)}
                      </div>
                    )}
                    <div id={e.detalle.producto + "P"}>
                      {e.producto.paquetes * e.detalle.ingreso}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="opciones">
            {rol === "SUPER ADMINISTRADOR" && (
              <div id={"total"}>
                TOTAL: {fg.formatear_moneda(pedido.produccion.total_terminado)}
              </div>
            )}
            <div>
              <Boton
                style={{
                  backgroundColor: solicitud ? "#E12121" : "rgb(220, 173, 47)",
                  borderRadius: esComputadora ? "0.5vw" : "1vw",
                  padding: esComputadora ? "0.8vw" : "1vw",
                }}
                Promise
                textoStyle={{
                  color: solicitud ? "white" : "black",
                  width: "100%",
                  fontSize: esComputadora ? "1.3vw" : "2.75vw",
                  padding: "0",
                }}
                texto={solicitud ? "PDF" : "AgregarProducto"}
                tipo="submit"
                estaProcesando={estaProcesando}
                onClick={solicitud ? clickVerPDF : clickAgregarProductoEvento}
              />
            </div>
            <div>
              <Boton
                style={{
                  backgroundColor: "rgb(220, 173, 47)",
                  borderRadius: esComputadora ? "0.5vw" : "1vw",
                  padding: esComputadora ? "0.8vw" : "1vw",
                }}
                Promise
                textoStyle={{
                  color: "black",
                  width: "100%",
                  fontSize: esComputadora ? "1.3vw" : "2.75vw",
                  padding: "0",
                }}
                texto={solicitud ? "Editar Solicitud" : "Guardar Solicitud"}
                tipo="submit"
                estaProcesando={estaProcesando}
                onClick={(event) => {
                  editarSolicitud(event);
                }}
              />
            </div>
            {(rol === "SUPER ADMINISTRADOR" || pedido.produccion.estado===1)&&<div>
              <Boton
                style={{
                  backgroundColor: "rgb(45, 139, 11)",
                  borderRadius: esComputadora ? "0.5vw" : "1vw",
                  padding: esComputadora ? "0.8vw" : "1vw",
                }}
                Promise
                textoStyle={{
                  color: "white",
                  width: "100%",
                  fontSize: esComputadora ? "1.3vw" : "2.75vw",
                  padding: "0",
                }}
                texto={
                  pedido.produccion.estado === 1 || produccion
                    ? "Terminar Produccion"
                    : "Editar Produccion"
                }
                tipo="submit"
                estaProcesando={estaProcesando}
                onClick={(event) => {
                  editarProduccion(event);
                }}
              />
            </div>}
          </div>
        </div>
      </e.Contenido>
    </>
  );
}

export default VerProduccionDetalle;
