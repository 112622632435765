import styled, { css } from 'styled-components'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .nomprodparam {
    grid-area: 1/1/1/21;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => (props.$escomputadora ? '1.2' : '3')}vw;
    font-family: Arial;
  }
  --pad-interno: 0.5vw;
  .input {
    padding: var(--pad-interno);
  }
  .codMaestroclass {
    grid-area: 2/1/3/11;
  }
  .pagoMaestroclass {
    grid-area: 2/11/3/21;
  }
  .listaMaestros {
    grid-area: 3/1/20/11;
    background-color: white;
    border: 0.1vw solid #cccccc;
    border-radius: 0.5vw;
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    
  }
 
  .aceptarboton {
    grid-area: 20/2/21/10;
  }
  .cancelarboton {
    grid-area: 20/12/21/20;
  }

  input[vacio='vacio'],
  select[vacio='vacio'] {
    outline: 0.1vw solid rgb(255, 0, 0);
  }
`
