import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../index'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as e from './EliminarRegistroCSS'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'

import * as api from './../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'

function EliminarRegistro (props) {
  const { esComputadora } = useContext(Contexto)
  useEffect(() => {
    console.log(props)
  })

  return (
    <>
      <e.Contenido $escomputadora={esComputadora}>
        <div className='nomprodparam'>
          {['epp'].includes(props.modo) &&
            '¿Seguro que desea eliminar el producto "' +
              props.param.nombreprod +
              '"?'}
          {['emr'].includes(props.modo) &&
            '¿Seguro que desea eliminar la relación con el maestro "' +
              fg.capitalizar_palabra(props.param.nombrema) +
              '"?'}
          {['epr'].includes(props.modo) &&
            '¿Seguro que desea eliminar la relación con el producto "' +
              fg.capitalizar_palabra(props.param.nombreprod) +
              '"?'}
        </div>
        <div className='aceptarboton'>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Aceptar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={async e => {
              let eliminar
              switch (props.modo) {
                case 'epp':
                  console.log('Se elimina el producto producción')
                  //eliminar=api.
                  break
                case 'emr':
                  console.log('Se elimina el maestro relacionado')
                  //console.log([props.param.codigoprod,props.param.pagomaestro],props.param.maestrorel,'S')
                  eliminar = await api.registroProductoMaestro(
                    [[props.param.codigoprod, props.param.pagomaestro]],
                    props.param.maestrorel,
                    'S'
                  )
                  break
                case 'epr':
                  console.log('Se elimina el producto relacionado')
                  //console.log([props.param.codigoprod],[[props.param.productorel,props.param.porinfluprel]],'S')
                  eliminar = api.RelacionarPProduccionPTerminado([props.param.codigoprod],[[props.param.productorel,props.param.porinfluprel]],'S')
                  break
                default:
                  console.log('El valor no es una fruta conocida')

                //setTipoAccion(e.currentTarget.id)
              }
              if (eliminar) {
                console.log(eliminar)
                console.log('eliminado con éxitgo')
                props.cerrarmodal()
              }
            }}
          />
        </div>
        <div className='cancelarboton'>
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Cancelar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={e => {
              //setTipoAccion(e.currentTarget.id)
              props.cerrarmodal()
            }}
          />
        </div>
      </e.Contenido>
    </>
  )
}

export default EliminarRegistro
