import { StyleSheet } from '@react-pdf/renderer'
import styled from 'styled-components'
import { Font } from '@react-pdf/renderer'

export const Contenido = styled.div`
  background-color: #eeeeee;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 90%;
  height: 90%;
  box-sizing: border-box;

  .cabecera {
    grid-area: 1/1/2/21;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(173, 103, 38);
    font-size: ${props => (props.$escomputadora ? '2' : '4')}vw;
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;
  }

  .detalle {
    grid-area: ${props => (props.$escomputadora ? '2/1/20/10' : '2/1/20/20')};
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
    color: rgb(139, 139, 139);
    border-radius: 0vw 0vw 0vw 1vw;
    background-color: white;
    border: 0.15vw solid rgb(207, 205, 205);
    display: grid;
    grid-template-rows: repeat(20, 1fr);
    grid-template-columns: repeat(20, 1fr);
    height: 100%;
    width: 100%;
    gap: 0.25vw;
  }
  .agregar {
    grid-area: 1/2/2/20;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 48% 48%;
    gap: 1vw;
    height: 100%;
    width: 100%;
    padding: 0.5vw;
  }
  .botonagregar {
    grid-area: 2/2/3/20;
    padding: 0.5vw;
  }
  .usuariosselec {
    grid-area: 3/2/12/20;
    border-radius: 1vw 1vw 1vw 1vw;
    border: 0.15vw solid rgb(207, 205, 205);
    box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
    display: grid;
    grid-template-rows: 17% 83%;
    grid-template-columns: 100%;
    gap: 0.25vw;
    padding: 0.5vw;
    box-sizing: border-box;
  }
  .areausuariostam {
    display: grid;
    overflow: auto;
    box-sizing: border-box;
    gap: 0.75vw;
    height: 100%;
    width: 100%;
  }
  .areausuarios {
    display: grid;
    overflow: auto;
    grid-template-rows: ${props =>
      '34% '.repeat((props.$usuarios + 1) / 2).trim()};
    grid-template-columns: 47% 47%;
    gap: 0.75vw;
  }
  .usuario {
    border: 0.15vw solid rgb(207, 205, 205);
    border-radius: ${props =>
      props.$escomputadora ? '1vw 1vw 1vw 1vw' : '2vw 2vw 2vw 2vw'};
    color: black;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 15% 80%;
    padding: 0.2vw;
    //height: 47%;
    width: 100%;
    background-color: rgb(253, 249, 226);
  }
  .usuario > div {
    display: flex;
    //justify-content: center;
    align-items: center;
  }
  .fechaOpcion {
    grid-area: 12/2/13/20;
  }
  .generarPedido {
    grid-area: 13/2/15/20;
  }
  .PDFContent {
    grid-area: 2/10/20/21;
  }
  iframe.pdf {
    width: 100%;
    height: 100%;
  }

  .PDFContentDownload {
    grid-area: 20/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
  }
  .PDFContentDownload > a {
    color: white;
    background-color: #2d8b0b;
    text-decoration: none;
    border-radius: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    padding: ${props => (props.$escomputadora ? '0.2' : '1')}vw;
    box-sizing: border-box;
  }
  .PDFContentDownload > a:hover {
    background-color: rgb(36, 110, 9);
    box-shadow: 0.1vw 0.1vw 0.1vw rgb(116, 116, 116);
  }
`
//DOCUMENTO PDF

export const docpdf = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: '15px'
  },
  columns: {
    display: 'flex',
    flexDirection: 'row'
  },
  headerDocument: {
    flexDirection: 'row',
    margin: '5px 5px 0 5px',
    alignItems: 'center'
  },
  logo: {
    width: '30%',
    textAlign: 'right'
  },
  titleDocument: {
    width: '40%',
    fontSize: '3vw',
    textAlign: 'center'
  },
  informationDocument: {
    width: '30%',
    fontSize: '1.2vw',
    flexDirection: 'column',
    color: 'grey'
  },

  bodyDocument: {
    flexDirection: 'column',
    fontSize: '10px'
  },
  headerCategory: {
    backgroundColor: '#ab6726',
    color: 'white',

    textAlign: 'center'
  },
  bodyCategory: {
    flexDirection: 'column',
    backgroundColor: 'white'
  },
  headerBody: {
    color: 'black',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#fdf9e2'
  },
  cellHeaderBody: {
    border: '1px solid black',
    height: '100%'
  },
  bodyBody: {
    backgroundColor: 'white',
    color: 'black',
    flexDirection: 'column'
  },
  cellBodyBody: {
    //backgroundColor: 'skyblue',
    height: '100%',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    borderBottom: '1px solid black'
  }
})
