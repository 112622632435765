import styled, { css } from "styled-components";

// export const contenedorLista = esComputadora => ({
//   padding: '0',
//   margin: '0',
//   position: 'relative',
//   width: '100%'
// })

// export const listastyle = (esComputadora, estilos, deshabilitado) => ({
//   padding: esComputadora ? '2vw 1.5vw 0.25vw 1vw' : '4vw 3vw 0.5vw 2vw',
//   height: 'auto',
//   width: '100%' /* Utiliza el ancho completo menos el espacio para el label */,
//   border: esComputadora ? '0.1vw solid #ccc' : '0.3vw solid #ccc',
//   borderRadius: esComputadora ? '0.5vw' : '1vw',
//   fontSize: esComputadora ? '1.3vw' : '3vw',
//   boxSizing: 'border-box',
//   backgroundColor: 'white',
//   ...estilos,
//   ...(deshabilitado
//     ? {
//         backgroundColor: '#BBBBBB',
//         color: 'black'
//       }
//     : {})
// })

// export const opcionStyle = esComputadora => ({
//   overflowY: 'scroll'
// })

// export const inputLabelCSS = (esComputadora, textoStyle) => ({
//   position: 'absolute',
//   top: '50%',
//   left: esComputadora
//     ? '1vw'
//     : '2.5vw' /* Espacio para el label como un porcentaje del ancho del contenedor */,
//   transform: 'translateY(-50%)',
//   color: '#999',
//   pointerEvents: 'none',
//   transition: '0.2s',
//   fontSize: esComputadora ? '1vw' : '2.5vw',
//   ...textoStyle
// })

// export const inputLabelActivoCSS = esComputadora => ({
//   top: '20%',
//   fontSize: esComputadora ? '0.8vw' : '2.0vw',
//   color: '#333'
// })

export const Contenido = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  select {
    border: none !important;
    border: 0.1vw solid #cccccc !important;
  }

  option {
    overflow-y: scroll;
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.5vw;
      left: 1.5vw;
    }
  }
  select:focus {
    outline: none ;
  }

  select {
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      height: 5vw !important;
    }
  }

  #textosinvalor {
    position: absolute;
    top: 50%;
    left: ${(props) => (props.$escomputadora ? "1vw" : "2.5vw")};
    transform: translateY(-50%);
    color: #999;
    pointer-events: none;
    transition: 0.2s;
    font-size: ${(props) => (props.$escomputadora ? "1vw" : "2.5vw")};

    ${(props) =>
      props.$iscontent &&
      `top: 20%; font-size: ${(props) =>
        props.$escomputadora ? "0.8vw" : "2.0vw"};
    color: #333;`};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.5vw;
      left: 1.5vw;

      ${(props) => props.$iscontent && `font-size: 1.2vw; left: 1.5vw;`}
    }
  }

  ${(props) =>
    props.$id &&
    css`
      #${props.$id} {
        background-color: ${(props) =>
          props.$deshabilitado ? "#CCCCCC" : "#ffffff"};
        padding: ${(props) =>
          props.$escomputadora ? "2vw 1.5vw 0.25vw 1vw" : "4vw 3vw 0.5vw 2vw"};
        height: auto;
        width: 100%;
        border: ${(props) =>
          props.$escomputadora ? "0.1vw solid #ccc" : "0.3vw solid #ccc"};
        border-radius: ${(props) => (props.$escomputadora ? "0.5vw" : "1vw")};
        font-size: ${(props) => (props.$escomputadora ? "1.3vw" : "3vw")};
        box-sizing: border-box;

        @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
          (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
          font-size: 2vw;
          padding: 2vw 3vw 0.5vw 2vw;
        }
      }
      ${(props) =>
        props.$id &&
        css`
      #${props.$id}:focus {
        border: ${(props) =>
          props.$escomputadora
            ? "0.1vw solid rgb(74, 151, 74)"
            : "0.1vw solid rgb(74, 151, 74)"} ;
        
    `}
    `}
`;
