import styled from 'styled-components'
import * as fg from './../../Api/FuncionesGlobales'
import imagenEmpresarial from './../../Imagenes/imagen_empresarial.jpg'

export const Contenido = styled.div`
  box-sizing: border-box;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
  background-image: url(${imagenEmpresarial});
  display: flex;
  align-items: center;
  justify-content: center;

  #contenedor {
    display: flex;
    align-items: center;
    font-size: 18px;
    width: 70%;

    flex-direction: ${props =>
      props.$escomputadora ? 'row' : 'column-reverse'};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      width: 50%;
      height: 45%;
    }

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3) {
      width: 70%;
      height: 65%;
    }
  }

  #contenedorLogin {
    background: ${fg.hex_rgb('#DCAD2F', 0.9)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${props => (props.$escomputadora ? '50vh' : '50vw')};
    padding: 5vw;
    box-sizing: border-box;
    border-radius: ${props =>
      props.$escomputadora ? '10px 0px 0px 10px' : '0px 0px 10px 10px'};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      width: 100%;
      height: 60%;
      padding: 5vw;
    }
  }

  #estiloCabecera {
    color: ${fg.hex_rgb('#603711', 1.0)};
    font-size: ${props => (props.$escomputadora ? '3.5vw' : '6vw')};
    font-family: Pridi, serif;
    font-weight: bold;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 3vw;
    }
  }

  #formIngreso {
    padding: 0;
    margin: 0;
    width: 100%;
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 2vw;
    }
  }

  #errorParrafo {
    color: black;
    font-size: ${props => (props.$escomputadora ? '1vw' : '2.5vw')};
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${props => (props.$escomputadora ? '1vw' : '2.5vw')};
    box-sizing: border-box;
    margin: 0px;
    background-color: #f96969;
    font-family: Arial, serif;

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.3vw;
      height: 1vw;
    }
  }

  #contenedorLogo {
    background: ${fg.hex_rgb('#908A78', 0.75)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${props => (props.$escomputadora ? '50vh' : '40vw')};
    padding: 10px;
    box-sizing: border-box;
    box-shadow: ${'4px 4px 46.6px ' + fg.hex_rgb('#000000', 0.75)};
    border-radius: ${props =>
      props.$escomputadora ? '0px 10px 10px 0px' : '10px 10px 0px 0px'};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
       {
      width: 90%;
      height: 60%;
    }
      @(min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1){
      width: 90%;
      height: 40%;
      }
  }

  #imagenLogo {
    width: ${props => (props.$escomputadora ? '100%' : '90%')};
    height: auto;
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      width: 70%;
    }
  }

  #eslogan {
    display: flex;
    color: ${fg.hex_rgb('#EE1D23', 1.0)};
    font-family: 'Carattere', cursive;
    font-weight: 600;
    font-style: normal;
    font-size: ${props => (props.$escomputadora ? '2.9vw' : '6vw')};
    text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff,
      -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    margin-top: ${props => (props.$escomputadora ? '-2vw' : '-4vw')};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 2.9vw;
      margin-top: -1vw;
    }
  }

  #botonIngresar {
    background-color: #2d8b0b !important;
    border-radius: ${props => (props.$escomputadora ? '0.5vw' : '1vw')};
    padding: ${props => (props.$escomputadora ? '0.8vw' : '2vw')};
    margin-top: 0.5vw;
  }

  #botonIngresar:hover {
    background-color: ${fg.rbga_tonalidad('#2d8b0b', 20)} !important;
  }

  #botonIngresar > div {
    color: white;
    font-size: ${props => (props.$escomputadora ? '1vw' : '2.5vw')};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.5vw;
    }
  }
  div:has(> #contrasenia, > #codigoUsuario) {
    padding: 0.3vw;
    box-sizing: border-box;
    
  }

  input ~ label {
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.5vw !important;
    }
  }
    input{
      @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.8vw !important;
    }
    }
`
