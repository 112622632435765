import React, { useState, useEffect } from "react";
import {
  actualizarEntrega,
  listarAyudantesDistribuidor,
  actualizarStock,
  obtenerCookie,
  listarPersonal,
  pedidoTienda,
  obtenerProductos,
  guardarStock,
} from "./../../../../Api/Api";
import Opciones from "./../../../../Elementos/Opciones";
import Input from "./../../../../Elementos/Input";
import Boton from "./../../../../Elementos/Boton";
import ModalReporte from "./ModalReporte";
import "./StockProductos.css";
import * as fg from "./../../../../Api/FuncionesGlobales";

function StockProductos(datos) {
  const [distribuidorSeleccionado, setDistribuidorSeleccionado] =
    useState(null);
  const [ayudantes, setAyudantes] = useState(null);
  const [ayuDistSelec, setayuDistSelec] = useState(null);
  const [fecRecojo, setfecRecojo] = useState(null);
  const [actualizo, setactualizo] = useState(false);
  const [codPedido, setCodPedido] = useState(null);
  const [subTotales, setSubTotales] = useState([]);
  const [estaProcesando, setestaProcesando] = useState(false);
  const [rol, setRol] = useState(
    obtenerCookie("descRol") !== null
      ? obtenerCookie("descRol").toUpperCase().trim()
      : ""
  );
  const [codUsuarioSesion, setcodUsuarioSesion] = useState(
    obtenerCookie("codUsuario") !== null
      ? obtenerCookie("codUsuario").toUpperCase().trim()
      : ""
  );
  const [reajustarStock, setreajustarStock] = useState(false);
  const [editarStock, seteditarStock] = useState(false);
  const [guardeCambios, setGuardeCambios] = useState(true);
  const [limpiar, setLimpiar] = useState(false);
  const [modalReportes, setModalReportes] = useState(false);

  const [stock, setstock] = useState(null);
  const [stockoriginal, setstockoriginal] = useState([]);
  const [fproducto, setfproducto] = useState("");
  const [fcategoria, setfcategoria] = useState("");
  const [fprecio, setfprecio] = useState("");
  useEffect(() => {
    const obtenerProductosF = async () => {
      const respuesta2 = await obtenerProductos(codUsuarioSesion);
      if (respuesta2) {
        if (respuesta2.SMListaErrores.length === 0) {
          setstock(respuesta2.SMProductosPorCategoria);
          setstockoriginal(respuesta2.SMProductosPorCategoria);
        }
      }
    };
    obtenerProductosF();
  }, []);

  useEffect(() => {
    const obtenerProductosF = async () => {
      const respuesta2 = await obtenerProductos(codUsuarioSesion);

      if (respuesta2) {
        if (respuesta2.SMListaErrores.length === 0) {
          setstock(respuesta2.SMProductosPorCategoria);
          setstockoriginal(respuesta2.SMProductosPorCategoria);
        }
      }
    };
    obtenerProductosF();
  }, [estaProcesando, actualizo]);

  const distribuidorSeleccionar = async (valor) => {
    setDistribuidorSeleccionado(valor);
  };

  useEffect(() => {
    if (distribuidorSeleccionado !== null) {
      async function fetchData() {
        const respuesta = await listarAyudantesDistribuidor(
          distribuidorSeleccionado
        );
        const respuesta2 = await pedidoTienda(distribuidorSeleccionado);
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            setAyudantes(null);
          }
        }
        if (respuesta2) {
          if (respuesta2.SMListaErrores.length === 0) {
            setstock(respuesta2.SMProductosPorCategoria);
            setCodPedido(respuesta2.codPedido);
          } else {
            setstock(null);
            setayuDistSelec(null);
          }
        }
      }
      fetchData();
    } else {
      setayuDistSelec(null);
    }
  }, [distribuidorSeleccionado]);

  const ayudanteDistribuidor = async (valor) => {
    setayuDistSelec(valor);
  };

  useEffect(() => {
    if (ayuDistSelec !== null) {
      async function fetchData() {
        const respuesta = await pedidoTienda(ayuDistSelec);
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            setstock(respuesta.SMProductosPorCategoria);
            //const subTotalesTemp = respuesta.pedidos.
            //setSubTotales([...subTotales,prod.subtotalSolicitada])
            setCodPedido(respuesta.codPedido);
            //setAyudantes(respuesta.ayudanteDistribuidores);
          } else {
            const respuesta2 = await pedidoTienda(distribuidorSeleccionado);
            if (respuesta2) {
              if (respuesta2.SMListaErrores.length === 0) {
                setstock(respuesta2.SMProductosPorCategoria);
                setCodPedido(respuesta2.codPedido);
                setayuDistSelec(null);
              } else {
                setstock(null);
              }
            }
          }
        }
      }
      fetchData();
    }
  }, [ayuDistSelec]);

  useEffect(() => {
    if (limpiar) setLimpiar(false);
  }, [limpiar]);

  useEffect(() => {
    const resultado = stockoriginal
      .filter((e) =>
        fcategoria === ""
          ? true
          : e.categoria
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(fcategoria)
      ) // Filtro a nivel de stockoriginal
      .map((e) => ({
        ...e,
        SMListaProductos: e.SMListaProductos.filter(
          (m) =>
            (fproducto === ""
              ? true
              : m.dscProducto
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
                  .includes(fproducto)) &&
            (fprecio === ""
              ? true
              : parseFloat(m.registroStock) >= parseFloat(fprecio))
        ),
      }))
      .filter((e) => e.SMListaProductos.length > 0);
    setstock(resultado);
  }, [fproducto, fcategoria, fprecio]);

  const botonReajStockStyle = {
    display:
      rol === "SUPER ADMINISTRADOR"
        ? reajustarStock || editarStock
          ? "none"
          : "flex"
        : "none",
    boxSizing: "border-box",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    gridArea: datos.esComputadora ? "1 / 11 / 2 / 15" : "1 / 10 / 2 / 15",
  };
  const botonEditarStockStyle = {
    display: reajustarStock || editarStock ? "none" : "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    width: "100%",
    gridArea: datos.esComputadora ? "1 / 16 / 2 / 20" : "1 / 16 / 2 / 21",
  };
  const filtros = {
    gridArea: datos.esComputadora ? "2 / 1 / 3 / 21" : "2 / 1 / 3 / 21",

    display: "grid",
    gridTemplateColumns: datos.esComputadora
      ? "40% 26% 26% 8%"
      : "37% 26% 23% 14%",
    gridTemplateRows: "100%",
    backgroundColor: "rgb(250, 250, 250)",
    boxShadow: datos.esComputadora
      ? "inset rgb(129, 129, 129) 0px 0px 0.3vw"
      : "inset rgb(129, 129, 129) 0px 0px 1.0vw",
    padding: "0.3vw",
    margin: "0.2vw 0",
    boxSizing: "border-box",
    borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
  };
  const contenedorstock = {
    gridArea: datos.esComputadora ? "3 / 1 / 19 / 21" : "3 / 1 / 19 / 21",
    backgroundColor: "#F4F4F4",
    overflow: "auto",
    boxSizing: "border-box",
    marginTop: "0.1vw",
    border: datos.esComputadora ? "0.05vw solid #ccc" : "0.1vw solid #ccc",
    padding: datos.esComputadora ? "0 0 0 1vw" : "0 0.5vw 0 1vw",
    borderRadius: datos.esComputadora ? "1vw" : "2vw",
  };
  const contenedorTotalesStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    gridTemplateRows: "100%",
    gridArea: datos.esComputadora ? "19 / 1 / 21 / 21" : "19 / 1 / 21 / 21",
    backgroundColor: "#F4F4F4",
    boxSizing: "border-box",
    marginTop: datos.esComputadora ? "0.5vw" : "1vw",
    boxSizing: "border-box",
    border: "0.1vw solid #ccc",
    borderRadius: datos.esComputadora ? "0.5vw" : "2vw",
    textAlign: "center",
  };
  const cardPedidostyle = {
    backgroundColor: "white", //"#FCFCFC",
    display: "inline-block",
    borderRadius: datos.esComputadora ? "1vw" : "1vw",
    margin: datos.esComputadora ? "1vw 2vw 0 0" : "1vw 2vw 0 0",
    overflow: datos.esComputadora ? "auto" : "none",
    height: datos.esComputadora ? "18vw" : "",
    boxSizing: "border-box",
    width: datos.esComputadora ? "calc(100%/2.12)" : "100%",
    border: "1px solid #ccc",
    boxShadow: "0 2px 2px rgba(0, 0, 0, 0.1)",
  };
  const nomCatProdStyle = {
    display: "block",
    backgroundColor: "#AD6726",
    color: "white",
    fontSize: datos.esComputadora ? "1vw" : "1.7vh",
    //color: "#363636",
    fontWeight: "bolder",
    //marginBottom: "5px",
    textAlign: "Center",
    borderRadius: "1vw 1vw 0px 0px",
    padding: "5px",
  };
  const contProdStyle = {
    padding: "0 10px 5px 10px ",
    backgroundColor: "#FFFFFF",
    borderRadius: "0px 0px 18px 18px",
  };
  const contCabProductoStyle = {
    display: "grid",
    gridTemplateColumns: "30% repeat(3,1fr)", //"repeat(5,1fr)",
    gridTemplateRows: "100%",
    fontWeight: "800",
    //marginTop: "10px",
    //backgroundColor: "yellow",
    fontSize: datos.esComputadora ? "0.9vw" : "2.6vw",
    color: "#868686",
    textAlign: "center",
  };
  const contProductoStyle = {
    display: "grid",
    gridTemplateColumns: "30% 23.3% 23.3% 23.3%",
    gridTemplateRows: "100%",
    marginTop: datos.esComputadora ? "0.25vw" : "0.5vw",
    fontSize: datos.esComputadora ? "0.8vw" : "2.5vw",
    color: "#868686",
    marginTop: datos.esComputadora ? "1vw" : "2vw",
  };

  const descProdStyle = {
    gridArea: "1/1/1/2",
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",
    textAlign: "left",
    //justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    whiteSpace: "pre-wrap",
    overflow: "auto",
    fontWeight: "500",
    fontSize: datos.esComputadora ? "0.8vw" : "2.5vw",
    color: "#868686",
    //marginTop: '1vw'
  };
  const precProdStyle = {
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    gridArea: "1/2/2/3",
  };
  const ultPedProdStyle = {
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
  };
  const pedProdStyle = {
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    gridArea: "1/3/2/4",
  };
  const subToPedStyle = {
    display: "flex",
    paddingRight: "5px",
    boxSizing: "border-box",
    textAlign: "center",
    justifySelf: "center",
    alignItems: "center",
    color: "#737373",
    fontWeight: "900",
  };
  const guardarPedidoStyle = {
    display: editarStock || reajustarStock ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
    gridArea: datos.esComputadora ? "1 / 8 / 2 / 10" : "1 / 7 / 2 / 10",
    fontSize: datos.esComputadora ? "1vw" : "2vw",
    color: "#737373",
  };

  const contFecPedStyle = {
    gridArea: datos.esComputadora ? "1 / 1 / 2 / 4" : "1 / 1 / 2 / 9",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const contFecRecStyle = {
    display: rol === "DESPACHADOR" ? "none" : "",

    gridArea: datos.esComputadora ? "1 / 17 / 2 / 21" : "2 / 12 / 2 / 21",
    padding: "0",
    margin: datos.esComputadora ? "0" : "10px 0px 0px 0px",
    //backgroundColor: "green",
    position: datos.esComputadora ? "none" : "relative",
    left: datos.esComputadora ? "none" : "8%",
    boxSizing: "border-box",
    height: "40px",
    width: datos.esComputadora ? "100%" : "100%",
  };
  const inputFecStyle = {
    height: "1px",
    padding: "15px 10px 5px",
    height: "100%",
    marginTop: "-5px",
    marginLeft: "-10%",
    width: "90%",
    borderRadius: "10px",
    border: "1px solid #ccc",
    boxShadow: "0 1px 1px rgba(0, 0, 0, 0.1)",
  };
  const labelFecStyle = {
    fontSize: datos.esComputadora ? "0.9em" : "1.6vh",
    marginLeft: "-10%",
  };
  const pedido = {
    color: "red",
    padding: "0",
    margin: "0",
    fontSize: datos.esComputadora ? "1vw" : "2vw",
    textAlign: "center",
    border: datos.esComputadora
      ? "0.1vw solid rgb(151, 151, 151)"
      : "0.2vw solid rgb(151, 151, 151)",
    borderRadius: datos.esComputadora ? "0.4vw" : "0.6vw",
    height: datos.esComputadora ? "1.6vw" : "3.5vw",
  };
  const totalStyle = {
    display: "inline-block",
    gridArea: datos.esComputadora ? "1 / 1 / 2 / 2" : "1 / 1 / 2 / 2",
    marginRight: "10px",
    fontSize: datos.esComputadora ? "1.5vw" : "1.8vh",
    fontWeight: "500",
    textAlign: "right",
  };
  const cantTotalStyle = {
    display: "inline-block",
    gridArea: datos.esComputadora ? "1 / 2 / 2 / 3" : "1 / 2 / 2 / 3",
    fontWeight: "500",
    fontSize: datos.esComputadora ? "2vw" : "2vh",
    textAlign: "left",
  };

  const reporteCSS = {
    display: editarStock || reajustarStock ? "none" : "flex",
    gridArea: "1/2/2/5",
    fontSize: datos.esComputadora ? "1vw" : "2vw",
    justifyContent: "center",
    alignItems: "center",
  };

  const cambioFecPedido = (event) => {
    event.target.value = obtenerFechaActual();
  };

  const cambioCantPedido = async (event) => {
    if (editarStock) {
      const respuesta = await actualizarStock(
        parseInt(event.target.id),
        isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value),
        parseInt(document.getElementById(event.target.id + "sa").innerHTML),
        1
      );
      if (respuesta) {
        if (respuesta.SMListaErrores.length === 0) {
          const subTotaltemp = document.getElementById(
            respuesta.codProducto + "A"
          );
          if (subTotaltemp) subTotaltemp.textContent = respuesta.stockDespues;

          const actualizarStockOriginal = (stockoriginal, respuesta, event) => {
            // Recorrer stockoriginal y actualizar productos según el criterio fproducto
            stockoriginal.forEach((e) => {
              e.SMListaProductos.forEach((m) => {
                if (
                  /*m.dscProducto
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toUpperCase()
                    .includes(
                      respuesta.producto
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .toUpperCase()
                    )*/
                  m.codProducto === respuesta.codProducto
                ) {
                  m.registroStock = parseInt(event.target.value);
                  m.stock = respuesta.stockDespues;
                }
              });
            });

            return stockoriginal;
          };

          // Llamada a la función
          const stockOriginalActualizado = actualizarStockOriginal(
            stockoriginal,
            respuesta,
            event
          );

          setstockoriginal(stockOriginalActualizado);
        } else {
          alert(
            "¡Advertencia! Se generó un error editando el stock, SE RECARGARÁ LA PÁGINA"
          );
          window.location.reload();
        }
      } else {
        alert(
          "¡Advertencia! Se generó un erro en el stock, SE RECARGARÁ LA PÁGINA"
        );
        window.location.reload();
      }
    } else {
      if (reajustarStock) {
        const respuesta = await actualizarStock(
          parseInt(event.target.id),
          isNaN(parseInt(event.target.value))
            ? 0
            : parseInt(event.target.value),
          parseInt(document.getElementById(event.target.id + "sa").innerHTML),
          2
        );
        if (respuesta) {
          if (respuesta.SMListaErrores.length === 0) {
            const subTotaltemp = document.getElementById(
              respuesta.codProducto + "A"
            );
            subTotaltemp.textContent = respuesta.stockDespues;

            //const limpiarValor=document.getElementById(respuesta.codProducto)
            //limpiarValor.value=""
          } else {
            alert(
              "¡Advertencia! Se generó un error reajustando el stock, SE RECARGARÁ LA PÁGINA"
            );
            window.location.reload();
          }
        } else {
          alert(
            "¡Advertencia! Se generó un error en el stock, SE RECARGARÁ LA PÁGINA"
          );
          window.location.reload();
        }
      }
    }
  };
  const cambioFecRecojo = (event) => {
    setfecRecojo(event.target.value);
  };
  const reajustarStockClick = async (event) => {
    setreajustarStock(true);
  };
  const editarStockClick = async (event) => {
    seteditarStock(true);
  };
  const guardarCambiosClick = async (event) => {
    if (editarStock) {
      setestaProcesando(true);
      setLimpiar(true);
      const valoresAntiguos = document.querySelectorAll(".actualizadoantiguo");
      const stockAntiguo = document.querySelectorAll(".stockantiguo");

      const respuesta = await guardarStock();
      if (respuesta && respuesta.SMListaErrores.length === 0) {
        setestaProcesando(false);
        alert("Se guardaron los cambios exitosamente. Se recargará la página.");
      } else {
        alert("Hubo un error. Se recargará la página.");
      }
      window.location.reload();
      seteditarStock(false);

      //window.location.reload()
      setGuardeCambios((prevValor) => !prevValor);
    } else {
      if (reajustarStock) {
        setestaProcesando(true);
        setLimpiar(true);
        const valoresAntiguos = document.querySelectorAll(
          ".actualizadoantiguo"
        );
        const stockAntiguo = document.querySelectorAll(".stockantiguo");

        setreajustarStock(false);
        setestaProcesando(false);
        alert("Se guardaron los cambios exitosamente. Se recargará la página.");
        window.location.reload();
        setGuardeCambios((prevValor) => !prevValor);
      }
    }
  };
  return (
    <>
      {modalReportes && (
        <ModalReporte
          esComputadora={datos.esComputadora}
          cerrarPdfModalClick={() => {
            setModalReportes((prevState) => !prevState);
          }}
          mostrarModal={true}
          //codigoPedidoEliminar={codigoPedidoEliminar}
          //nombreUsuarioPedido={nombreUsuarioPedido}
        />
      )}
      <div id="contenedorFechaPedido" style={contFecPedStyle}>
        <Input
          style={{
            fontWeight: "bolder",
            fontSize: datos.esComputadora ? "1.2vw" : "2.5vw",
          }}
          textoStyle={{
            fontWeight: "bolder",
            fontSize: datos.esComputadora ? "1.2vw" : "2.5vw",
          }}
          id="fechaPedido"
          moderno="Fecha Actual"
          tipo="date"
          autoComplete
          onChange={cambioFecPedido}
          valorInicial={obtenerFechaActual()}
          deshabilitado
          esComputadora={datos.esComputadora}
        />
      </div>

      <div id="contenedorListaDist" style={botonReajStockStyle}>
        <Boton
          style={{
            backgroundColor: "#DCAD2F",
            borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
            padding: datos.esComputadora ? "0.8vw" : "2vw",
          }}
          textoStyle={{
            color: "black",
            width: "100%",
            fontSize: datos.esComputadora ? "1vw" : "2.5vw",
          }}
          texto="Reajustar Stock"
          tipo="submit"
          tonalidad="20"
          estaProcesando={estaProcesando}
          onClick={reajustarStockClick}
        />
      </div>
      <div id="contenedorListaAyuDist" style={botonEditarStockStyle}>
        <Boton
          style={{
            backgroundColor: "#DCAD2F",
            borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
            padding: datos.esComputadora ? "0.8vw" : "2vw",
          }}
          textoStyle={{
            color: "black",
            width: "100%",
            fontSize: datos.esComputadora ? "1vw" : "2.5vw",
          }}
          texto="Editar Stock"
          tipo="submit"
          tonalidad="20"
          estaProcesando={estaProcesando}
          onClick={editarStockClick}
        />
      </div>
      <div style={filtros}>
        <div style={{ padding: "0.1vw" }}>
          <Input
            style={{}}
            textoStyle={{}}
            id="fproducto"
            moderno={"Producto"}
            tipo="text"
            onChange={(e) => {
              setfproducto(e.currentTarget.value.toUpperCase().trim());
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={""}
            esComputadora={datos.esComputadora}
            limpiar={fproducto === "" ? true : false}
          />
        </div>
        <div style={{ padding: "0.1vw" }}>
          <Input
            style={{}}
            textoStyle={{}}
            id="fcategoria"
            moderno={"Categoría"}
            tipo="text"
            onChange={(e) => {
              setfcategoria(e.currentTarget.value.toUpperCase().trim());
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={""}
            esComputadora={datos.esComputadora}
            limpiar={fcategoria === "" ? true : false}
          />
        </div>
        <div style={{ padding: "0.1vw" }}>
          <Input
            style={{}}
            textoStyle={{}}
            id="fprecio"
            moderno={"Stock actualizado ≥"}
            tipo="number"
            onChange={(e) => {
              setfprecio(e.currentTarget.value.toUpperCase().trim());
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={""}
            esComputadora={datos.esComputadora}
            limpiar={fprecio === "" ? true : false}
          />
        </div>
        <div
          style={{
            padding: "0.1vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Boton
            style={{
              backgroundColor: "#E12121",
              borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
              padding: datos.esComputadora ? "0.8vw" : "1vw",
            }}
            textoStyle={{
              color: "white",
              width: "100%",
              fontSize: datos.esComputadora ? "1vw" : "2.3vw",
              padding: "0",
            }}
            texto="Limpiar Filtros"
            tipo="submit"
            estaProcesando={false}
            onClick={(event) => {
              event.preventDefault();
              setactualizo((e) => !e);
              setfproducto("");
              setfcategoria("");
              setfprecio("");
            }}
          />
        </div>
      </div>
      <div id="contenedorstock" style={contenedorstock}>
        {stock !== null &&
          stock.map((pppc) => {
            return (
              <div
                key={pppc.codigoCategoria}
                id={pppc.categoria}
                style={cardPedidostyle}
              >
                <div style={nomCatProdStyle}> {pppc.categoria} </div>
                <div style={contProdStyle}>
                  <div style={contCabProductoStyle}>
                    <div>Producto</div>
                    <div>Precio x Unidad</div>
                    <div>Stock</div>
                    <div>Actualiza Stock</div>
                  </div>
                  {pppc.SMListaProductos.map((prod) => {
                    return (
                      <div
                        className="listaProductos"
                        key={prod.codProducto}
                        style={contProductoStyle}
                      >
                        <div style={descProdStyle}>
                          {prod.dscProducto
                            ? fg.capitalizar_palabra(prod.dscProducto)
                            : ""}
                        </div>
                        <div style={precProdStyle}>
                          {formatearMonedaSoles(
                            prod.precioUnitario ? prod.precioUnitario : 0
                          )}
                        </div>
                        <div
                          className="stockantiguo"
                          id={prod.codProducto + "" + "A"}
                          style={pedProdStyle}
                        >
                          {prod.stock}{" "}
                        </div>
                        <div style={{ gridArea: "1/4/2/5", width: "90%" }}>
                          <Input
                            className = {'inputStockProductos'}
                            maxDigitos={4}
                            antiguo="" //{prod.canSolicitada !==0 ? prod.canSolicitada:null}
                            tipo="number"
                            onBlur={cambioCantPedido}
                            onChange={(event) => {
                              if (event.target.value === "") {
                                cambioCantPedido(event);
                              }
                            }}
                            id={prod.codProducto}
                            valorInicial={
                              !guardeCambios
                                ? ""
                                : prod.registroStock !== 0 &&
                                  prod.registroStock !== null
                                ? prod.registroStock
                                : ""
                              //prod.registroStock !== 0 ? prod.registroStock : ''
                            }
                            deshabilitado={
                              editarStock || reajustarStock ? false : true
                            }
                            limpiar={limpiar}
                          />
                        </div>
                        <div
                          style={{
                            visibility: "hidden",
                            fontSize: "0px",
                            width: "0",
                            height: "0",
                          }}
                          id={prod.codProducto + "sa"}
                          className="actualizadoantiguo"
                        >
                          {prod.stock}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      <div id="contenedorTotales" style={contenedorTotalesStyle}>
        {rol === "SUPER ADMINISTRADOR" && (
          <div style={reporteCSS}>
            <Boton
              style={{
                backgroundColor: "#2D8B0B",
                borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
                padding: datos.esComputadora ? "0.5vw" : "1vw",
              }}
              textoStyle={{
                color: "white",
                width: "100%",
                fontSize: datos.esComputadora ? "1vw" : "2.5vw",
              }}
              texto="Reporte de Productos"
              tipo="submit"
              tonalidad="20"
              estaProcesando={estaProcesando}
              onClick={() => {
                setModalReportes(true);
              }}
            />
          </div>
        )}
        <div id="guardarPedido" style={guardarPedidoStyle}>
          <Boton
            style={{
              backgroundColor: "#2D8B0B",
              borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
              padding: datos.esComputadora ? "0.5vw" : "1vw",
            }}
            textoStyle={{
              color: "white",
              width: "100%",
              fontSize: datos.esComputadora ? "1vw" : "2.5vw",
            }}
            texto="Guardar Cambios"
            tipo="submit"
            tonalidad="20"
            estaProcesando={estaProcesando}
            onClick={guardarCambiosClick}
          />
        </div>
      </div>
    </>
  );
}

function obtenerFechaActual() {
  let fechaActual = new Date();
  let año = fechaActual.getFullYear();
  let mes = ("0" + (fechaActual.getMonth() + 1)).slice(-2); // Asegura 2 dígitos
  let dia = ("0" + fechaActual.getDate()).slice(-2); // Asegura 2 dígitos
  return `${año}-${mes}-${dia}`;
}
function formatearMonedaSoles(valor) {
  return valor.toLocaleString("es-PE", {
    style: "currency",
    currency: "PEN",
  });
}

export default StockProductos;
