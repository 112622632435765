import React, { useState, useEffect } from "react";
import Boton from "./../../../../../Elementos/Boton";
import Opciones from "../../../../../Elementos/Opciones";
import agregar from "./../../../../../Imagenes/Iconos/agregar.png";
import editar from "./../../../../../Imagenes/Iconos/editar.png";
import borrar from "./../../../../../Imagenes/Iconos/borrar.png";
import AgregarEditarProductos from "./AgregarEditarProductos";
import Input from "../../../../../Elementos/Input";

import {
  listarProductos,
  registrarProducto,
  listaDistribuidores,
  actualizarDetalleProductos,
  eliminarProductos,
  obtenerCookie,
} from "../../../../../Api/Api";

import * as estilos from "./AdministrarProductosCSS";
import * as fg from "./../../../../../Api/FuncionesGlobales";

function AdministrarProductos(datos) {
  const [procesandoProductos, setProcesandoProductos] = useState(true);
  const [codUsuario, setCodUsuario] = useState(obtenerCookie("codUsuario"));
  const [productos, setProductos] = useState(null);
  const [productosoriginal, setproductosoriginal] = useState([]);
  const [modal, setModal] = useState(false);
  const [estaProcesando, setestaProcesando] = useState(false);
  const [distribuidores, setDistribuidores] = useState(null);

  const [modoModal, setModoModal] = useState("");
  const [agregarProducto, setAgregarProducto] = useState(false);
  const [editarProducto, setEditarProducto] = useState(false);
  const [eliminarProducto, setEliminarProducto] = useState(false);
  const [paramEditProd, setParamEditProd] = useState({});
  const [paramAgregarProd, setParamAgregarProd] = useState({});
  const [paramEliminarProd, setParamEliminarProd] = useState({});
  const [seleccion, setSeleccion] = useState(false);
  const [seleccionados, setSeleccionados] = useState({});

  const [preConfirmacionEliminar, setpreConfirmacionEliminar] = useState(false);
  const [preConfirmacionEditar, setpreConfirmacionEditar] = useState(false);
  const [preConfirmacionAgregar, setpreConfirmacionAgregar] = useState(false);

  const [catSelec, setCatSelec] = useState(0);
  const [nomProd, setNomProd] = useState("");
  const [unixpaq, setUniXPaq] = useState(0);
  const [precDist, setPrecDist] = useState(0);
  const [precPers, setPrecPers] = useState(0);
  const [precPubl, setPrecPubl] = useState(0);
  const [estActi, setEstActi] = useState("");
  const [opAreaProd, setOpAreaProd] = useState("");
  const [distSelec, setDistSelec] = useState("");
  const [origenEvento, setOrigenEvento] = useState("");

  const [coloresFondoAnteriores, setCFA] = useState({});
  const [procesandoDetalle, setProcesandoDetalle] = useState(false);

  const [fproducto, setfproducto] = useState("");
  const [fcategoria, setfcategoria] = useState("");
  const [fprecio, setfprecio] = useState("");
  const [habestprod, sethabetprod] = useState(2);
  const actualizarSeleccionadosDevolucion = () => {
    Object.keys(seleccionados).forEach(function (elemento) {
      seleccionados[
        elemento
      ].firstElementChild.firstElementChild.checked = false;
      setSeleccion(false);
      seleccionados[elemento].style.background =
        coloresFondoAnteriores[elemento];
    });

    setSeleccionados({});
  };

  useEffect(() => {
    const ejecucion = async () => {
      const respuesta = await listarProductos(codUsuario); // Esta función debe retornar el resultado que necesitas para la validación
      if (respuesta.SMListaErrores.length === 0) {
        setProductos(respuesta.SMProductosPorCategoria);
        setproductosoriginal(respuesta.SMProductosPorCategoria);
      }
      const respuestaDist = await listaDistribuidores();
      if (respuestaDist.SMListaErrores.length === 0) {
        setDistribuidores(respuestaDist.Distribuidores);
      }
      setProcesandoProductos(false);
    };
    ejecucion();
  }, []);

  useEffect(() => {
    setProcesandoProductos(true);
    const ejecucion = async () => {
      const respuesta = await listarProductos(
        distSelec ? distSelec : codUsuario
      ); // Esta función debe retornar el resultado que necesitas para la validación
      if (respuesta.SMListaErrores.length === 0) {
        setProductos(respuesta.SMProductosPorCategoria);
        setproductosoriginal(respuesta.SMProductosPorCategoria);
        setProcesandoProductos(false);
      }
    };
    ejecucion();

    actualizarSeleccionadosDevolucion();
  }, [modal]);

  useEffect(() => {
    const ejecucion = async () => {
      setProcesandoProductos(true);
      const respuesta = await listarProductos(
        distSelec ? distSelec : codUsuario
      ); // Esta función debe retornar el resultado que necesitas para la validación
      if (respuesta.SMListaErrores.length === 0) {
        setProductos(respuesta.SMProductosPorCategoria);
        setproductosoriginal(respuesta.SMProductosPorCategoria);
      }
      setProcesandoProductos(false);
    };
    ejecucion();
  }, [distSelec]);

  const cambioEstadoEvento = (event) => {
    const elementoPadreSuperior = event.target.parentNode.parentNode;
    const clave = elementoPadreSuperior.id.toString();
    const color = event.target.parentNode.parentNode.style.backgroundColor;

    if (event.target.checked) {
      elementoPadreSuperior.style.backgroundColor = "lightblue";
      const elemento = { [clave]: elementoPadreSuperior };
      setSeleccionados({ ...seleccionados, ...elemento });
      const elementoColor = { [clave]: color };
      setCFA({ ...coloresFondoAnteriores, ...elementoColor });
      setSeleccion(true);
    } else {
      elementoPadreSuperior.style.backgroundColor =
        coloresFondoAnteriores[clave]; //'white';
      //const nuevoArreglo = seleccionados.filter(elemento => elemento.id !== clave);
      let nuevoArreglo = seleccionados;
      delete nuevoArreglo[clave];
      setSeleccionados(nuevoArreglo);
      if (Object.keys(seleccionados).length <= 0) {
        setSeleccion(false);
      }
    }
  };
  const seleccionarTodosEvento = (event) => {
    const elementoLanzardorEvento = event.target;
    const elementosCategoria = document.querySelectorAll(
      ".categoria" + elementoLanzardorEvento.id
    );
    let temp = {};
    let temp2 = {};
    elementosCategoria.forEach(function (elemento) {
      const clave = elemento.parentNode.parentNode.id.toString();

      if (elementoLanzardorEvento.checked) {
        const color = elemento.parentNode.parentNode.style.backgroundColor;
        let elemento2 = { [clave]: color };
        temp2 = { ...temp2, ...elemento2, ...coloresFondoAnteriores };
        //const elementoColor = {[clave]: color};
        setCFA(temp2);

        elemento.checked = true;
        elemento.parentNode.parentNode.style.backgroundColor = "lightblue";

        let elemento1 = { [clave]: elemento.parentNode.parentNode };
        temp = { ...temp, ...elemento1, ...seleccionados };
        setSeleccionados(temp);
        setSeleccion(true);
      } else {
        elemento.checked = false;
        elemento.parentNode.parentNode.style.backgroundColor =
          coloresFondoAnteriores[clave]; //'white';
        let nuevoArreglo = seleccionados;
        delete nuevoArreglo[clave];
        setSeleccionados(nuevoArreglo);
        temp = seleccionados;
        setSeleccion(false);
      }
    });
  };

  const clickAgregarProductoEvento = (event) => {
    setOrigenEvento("r1");
    const codCategoria = event.currentTarget.parentNode.getAttribute("catprod");
    setModal(true);
    setModoModal("agregar");
    setEditarProducto(false);
    setEliminarProducto(false);
    setAgregarProducto(true);
    setParamAgregarProd({
      ["codCategoria"]: codCategoria,
      productos: paramEditProd,
      codUsuario: distSelec,
    });
    setParamEliminarProd({});
    setParamEditProd({});
  };
  const clickEditarProductoEvento = (event) => {
    setOrigenEvento("a1");
    const prodSeleccionado = event.currentTarget.parentNode;
    //const codCategoria=prodSeleccionado.getAttribute("catprod");
    //["codCategoria"]:codCategoria
    let parametroEditar = { [prodSeleccionado.id]: prodSeleccionado };
    setModal(true);
    setModoModal("editar");
    setEditarProducto(true);
    setAgregarProducto(false);
    setEliminarProducto(false);
    setParamEliminarProd({});
    setParamAgregarProd({});
    setParamEditProd(parametroEditar);
  };
  const clickBorrarProdEvento = (event) => {
    setOrigenEvento("e1");
    const prodSeleccionado = event.currentTarget.parentNode;
    //prodSeleccionado.getAttribute('dsc')
    let parametroEliminar = { [prodSeleccionado.id]: prodSeleccionado };

    setModal(true);
    setModoModal("eliminar");
    setEditarProducto(false);
    setAgregarProducto(false);
    setEliminarProducto(true);
    setParamEliminarProd(parametroEliminar);
    setParamAgregarProd({});
    setParamEditProd({});
  };
  const clickBorrarProdsEvento = (event) => {
    //const prodSeleccionado=event.currentTarget.parentNode;
    //prodSeleccionado.getAttribute('dsc')
    setOrigenEvento("ev");

    setModal(true);
    setModoModal("eliminar");
    setEditarProducto(false);
    setAgregarProducto(false);
    setEliminarProducto(true);
    setParamEliminarProd(seleccionados);
    setParamAgregarProd({});
    setParamEditProd({});
  };
  const cerrarModalEvento = () => {
    setModal(false);
    setpreConfirmacionEditar(false);
    setpreConfirmacionEliminar(false);
    setpreConfirmacionAgregar(false);
    reiniciarVariables();
  };
  const clickAgregarProdsEvento = () => {
    setOrigenEvento("rv");
    setModal(true);
    setModoModal("agregar");
    setEditarProducto(false);
    setAgregarProducto(true);
    setEliminarProducto(false);
    setParamEliminarProd(seleccionados);
    setParamAgregarProd({
      ["codCategoria"]: 0,
      productos: paramEditProd,
      codUsuario: distSelec,
    });
    setParamEliminarProd({});
    setParamEditProd({});
  };
  const clickEditarProdsEvento = () => {
    setOrigenEvento("av");
    setModal(true);
    setModoModal("editar");
    setEditarProducto(true);
    setAgregarProducto(false);
    setEliminarProducto(false);
    setParamEliminarProd({});
    setParamAgregarProd({});
    setParamEditProd(seleccionados);
  };

  const ClickConfirmarEliminar = (event) => {
    setpreConfirmacionEliminar(true);
  };
  const ClickConfirmarEditar = (event) => {
    setpreConfirmacionEditar(true);
  };
  const ClickConfirmarAgregar = (event) => {
    setpreConfirmacionAgregar(true);
    console.log("==========================");
    console.log("elementos ", Object.keys(paramAgregarProd));
    console.log("catSelec ", catSelec);
    console.log("nomProd ", nomProd);
    console.log("unixpaq ", unixpaq);
    console.log("precDist ", precDist);
    console.log("precPers ", precPers);
    console.log("precPubl ", precPubl);
    console.log("estActi ", estActi);
    console.log("codUsuario ", codUsuario);
    console.log("habestprod", habestprod);
    console.log("==========================");
    console.log(document.getElementById("catProductoList").value);
    console.log(document.getElementById("nombres").value);
    console.log(document.getElementById("unidadesxpaquete").value);
    console.log(document.getElementById("precioDistribuidor").value);
    console.log(document.getElementById("precioPersonal").value);
    console.log(document.getElementById("precioPublico").value);

    const selectedRadio = document.querySelector(
      'input[name="opciones"]:checked'
    );
    const selectedRadio2 = document.querySelector('input[name="area"]:checked');
  };
  const clickPreConfirmarEliminarSi = async (event) => {
    setpreConfirmacionEliminar(false);
    setProcesandoDetalle(true);
    const respuesta = await eliminarProductos(
      distSelec,
      Object.keys(paramEliminarProd)
    );
    if (respuesta) {
      if (Object.keys(seleccionados).length > 0) {
        setSeleccionados({});
      }
      setModal(false);
    }
    setProcesandoDetalle(false);
  };
  const clickPreConfimarEliminarNo = (event) => {
    setpreConfirmacionEliminar(false);
  };
  const clickPreConfirmarEditarSi = async (event) => {
    const selectedRadio = document.querySelector(
      'input[name="opciones"]:checked'
    );
    const selectedRadio2 = document.querySelector('input[name="area"]:checked');

    setProcesandoDetalle(true);
    if (origenEvento === "a1" || Object.keys(seleccionados).length === 1) {
      setpreConfirmacionEditar(false);
      //setModal(false);
      const respuesta = await registrarProducto(
        Object.keys(paramEditProd)[0],
        distSelec, //codUsuario.toUpperCase(),
        document.getElementById("catProductoList").value,
        document.getElementById("nombres").value.toUpperCase(),
        document.getElementById("unidadesxpaquete").value === ""
          ? 0
          : document.getElementById("unidadesxpaquete").value,
        document.getElementById("precioPublico").value === ""
          ? 0
          : document.getElementById("precioPublico").value,
        document.getElementById("precioPersonal").value === ""
          ? 0
          : document.getElementById("precioPersonal").value,
        document.getElementById("precioDistribuidor").value === ""
          ? 0
          : document.getElementById("precioDistribuidor").value,
        ///*selectedRadio2.value*/ 3,
        selectedRadio.value,
        habestprod
      );
      if (respuesta) {
        console.log(respuesta);
        setModal(false);
      }
      setProcesandoDetalle(false);
    } else {
      setpreConfirmacionEditar(false);
      const respuesta = await actualizarDetalleProductos(
        distSelec,
        Object.keys(paramEditProd),
        precPubl,
        precPers,
        precDist,
        selectedRadio.value === "NULL" || selectedRadio.value === ""
          ? ""
          : selectedRadio.value,
        precPubl !== "" && precPubl > 0 ? "S" : "N",
        precPers !== "" && precPers > 0 ? "S" : "N",
        precDist !== "" && precDist > 0 ? "S" : "N",
        selectedRadio.value === "NULL" || selectedRadio.value === ""
          ? "N"
          : "S",
        habestprod
      );
      if (respuesta) {
        setModal(false);
      } else {
      }
      setProcesandoDetalle(false);
    }

    reiniciarVariables();
  };
  const clickPreConfirmarEditarNo = (event) => {
    setpreConfirmacionEditar(false);
  };
  const clickPreConfirmarAgregarSi = async (event) => {
    setpreConfirmacionAgregar(false);
    console.log(
      catSelec === "" || catSelec === 0
        ? document.getElementById("catProductoList").value
        : catSelec
    );
    setProcesandoDetalle(true);
    const respuesta = await registrarProducto(
      0,
      "",
      catSelec === "" || catSelec === 0
        ? document.getElementById("catProductoList").value
        : catSelec,
      nomProd.toUpperCase(),
      unixpaq !== "" && unixpaq > 0 ? unixpaq : 0,
      precPubl !== "" && precPubl > 0 ? precPubl : 0,
      precPers !== "" && precPers > 0 ? precPers : 0,
      precDist !== "" && precDist > 0 ? precDist : 0,
      //opAreaProd,
      estActi,
      parseInt(habestprod)
    );
    if (respuesta) {
      console.log(respuesta);
    }
    setProcesandoDetalle(false);
    reiniciarVariables();
    setModal(false);
  };
  const clickPreConfirmarAgregarNo = (event) => {
    setpreConfirmacionAgregar(false);
  };

  const cambiarValores = (event) => {
    switch (event.currentTarget.id) {
      case "nombres":
        setNomProd(event.currentTarget.value);
        break;
      case "unidadesxpaquete":
        setUniXPaq(event.currentTarget.value);
        break;
      case "precioDistribuidor":
        setPrecDist(event.currentTarget.value);
        break;
      case "precioPersonal":
        setPrecPers(event.currentTarget.value);
        break;
      case "precioPublico":
        setPrecPubl(event.currentTarget.value);
        break;
      default:
        console.log("ninguno");
    }
  };
  const cambiarCategoria = (event) => {
    setCatSelec(event);
  };
  const cambiarEstadoOpcion = (event) => {
    setEstActi(event);
  };
  const cambiarEstadoOpcion2 = (event) => {
    setOpAreaProd(event);
  };
  const cambiarHabilitarProd = (event) => {
    sethabetprod(event);
  };
  const reiniciarVariables = () => {
    setCatSelec(0);
    setNomProd("");
    setUniXPaq(0);
    setPrecDist(0);
    setPrecPers(0);
    setPrecPubl(0);
    setEstActi("");
    sethabetprod(2);
  };

  useEffect(() => {
    let temp = [];

    const resultado = productosoriginal
      .filter((e) =>
        fcategoria === ""
          ? true
          : e.categoria
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toUpperCase()
              .includes(fcategoria)
      ) // Filtro a nivel de stockoriginal
      .map((e) => ({
        ...e,
        SMListaProductos: e.SMListaProductos.filter(
          (m) =>
            (fproducto === ""
              ? true
              : m.dscProducto
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
                  .includes(fproducto)) &&
            (fprecio === ""
              ? true
              : parseFloat(m.precioUnitario) >= parseFloat(fprecio))
        ),
      }))
      .filter((e) => e.SMListaProductos.length > 0);
    //console.log(resultado)
    setProductos(resultado);
  }, [fproducto, fcategoria, fprecio]);

  return (
    <>
      {modal ? (
        <div id="modalStyle" style={estilos.modalStyle(datos.esComputadora)}>
          <div
            id="contContenidoModalStyle"
            style={estilos.contContenidoModalStyle(datos.esComputadora)}
          >
            <div
              id="contenidoModalStyle"
              style={estilos.contenidoModalStyle(
                datos.esComputadora,
                modoModal
              )}
            >
              <div
                style={{
                  display: "flex",
                  gridArea: datos.esComputadora
                    ? " 1 / 1 / 2 / 21"
                    : " 1 / 1 / 2 / 21",
                  justifySelf: "center",
                  alignItems: "center",
                }}
              >
                {(() => {
                  if (agregarProducto) return "Agregar Producto(s)";
                  else if (editarProducto) return "Editar Producto(s)";
                  else if (eliminarProducto) return "Eliminar Producto(s)";
                })()}
              </div>
              <div style={estilos.botonCerrarModalStyle(datos.esComputadora)}>
                <Boton
                  style={{
                    backgroundColor: "#E12121",
                    borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
                    padding: datos.esComputadora ? "0.8vw" : "2vw",
                  }}
                  textoStyle={{
                    color: "white",
                    width: "100%",
                    fontSize: datos.esComputadora ? "1vw" : "2.5vw",
                  }}
                  texto="X"
                  tipo="submit"
                  estaProcesando={estaProcesando}
                  onClick={cerrarModalEvento}
                />
              </div>
              {agregarProducto && (
                <>
                  {preConfirmacionAgregar && (
                    <div
                      style={{
                        color: "black",
                        background:
                          "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
                        //background: "linear-gradient(90deg, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 48%, rgba(173,103,38,1) 100%)",
                        padding: "5px 5px 5px 5px",
                        boxSizing: "border-box",
                        borderTop: "0.1px solid #d9d9d9",
                        borderBottom: "0.1px solid #d9d9d9",
                        margin: "5px 0px 5px 0px",
                        gridArea: datos.esComputadora
                          ? " 2 / 1/ 3 / 21"
                          : " 2 / 1/ 3 / 21",
                        display: "flex",
                        alignItems: "center",
                        fontSize: datos.esComputadora ? "1vw" : "2.7vw",
                      }}
                    >
                      ¿Seguro que deseas registrar el producto?
                      <button
                        style={{ margin: "0px 10px 0px 10px" }}
                        onClick={clickPreConfirmarAgregarSi}
                      >
                        Si
                      </button>
                      <button onClick={clickPreConfirmarAgregarNo}>No</button>
                    </div>
                  )}
                  <div
                    style={estilos.agregarProductoStyle(datos.esComputadora)}
                  >
                    <AgregarEditarProductos
                      esComputadora={datos.esComputadora}
                      cerrarModal={cerrarModalEvento}
                      param={paramAgregarProd}
                      tipo="agregar"
                      preConfirmarClickSi={clickPreConfirmarAgregarSi}
                      preConfirmarClickNo={clickPreConfirmarAgregarNo}
                      confirmarClick={ClickConfirmarAgregar}
                      cambiaCampoClick={cambiarValores}
                      cambiarCategoria={cambiarCategoria}
                      cambiarEstadoOpcion={cambiarEstadoOpcion}
                      cambiarEstadoOpcion2={cambiarEstadoOpcion2}
                      cambiarHabilitarProd={cambiarHabilitarProd}
                      procesandoDetalle={procesandoDetalle}
                    />
                  </div>
                </>
              )}
              {editarProducto && (
                <>
                  <div style={estilos.editarProductoStyle(datos.esComputadora)}>
                    {preConfirmacionEditar && (
                      <div
                        style={{
                          color: "black",
                          background:
                            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
                          //background: "linear-gradient(90deg, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 48%, rgba(173,103,38,1) 100%)",
                          padding: "5px 5px 5px 5px",
                          boxSizing: "border-box",
                          borderTop: "0.1px solid #d9d9d9",
                          borderBottom: "0.1px solid #d9d9d9",
                          margin: "5px 0px 5px 0px",
                          gridArea: datos.esComputadora
                            ? " 1 / 1/ 2 / 21"
                            : " 1 / 1/ 2 / 21",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ¿Seguro que deseas confirmar los cambios?
                        <button
                          tipo="a1"
                          style={{ margin: "0px 10px 0px 10px" }}
                          onClick={clickPreConfirmarEditarSi}
                        >
                          Si
                        </button>
                        <button onClick={clickPreConfirmarEditarNo}>No</button>
                      </div>
                    )}
                    <div
                      style={{
                        gridArea: datos.esComputadora
                          ? " 2 / 11 / 15 / 21"
                          : " 2 / 1/ 4 / 21",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <estilos.ScrollableContainer>
                        <ul>
                          {Object.keys(paramEditProd).map((elemento) => {
                            return (
                              <li key={paramEditProd[elemento].id}>
                                {paramEditProd[elemento].getAttribute("dsc")}
                              </li>
                            );
                          })}
                        </ul>
                      </estilos.ScrollableContainer>
                    </div>
                    <div
                      style={{
                        gridArea: datos.esComputadora
                          ? " 2/ 1/ 16 / 10"
                          : " 4/ 1/ 16 / 21",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5px",
                      }}
                    >
                      <AgregarEditarProductos
                        esComputadora={datos.esComputadora}
                        cerrarModal={cerrarModalEvento}
                        //param={paramEditProd}
                        param={{
                          ["codCategoria"]: (() => {
                            if (Object.keys(paramEditProd).length === 1)
                              return paramEditProd[
                                Object.keys(paramEditProd)[0]
                              ].getAttribute("catprod");
                            else {
                              return "";
                            }
                          })(),
                          codProducto: (() => {
                            if (Object.keys(paramEditProd).length === 1)
                              return paramEditProd[
                                Object.keys(paramEditProd)[0]
                              ].id;
                            else {
                              return "";
                            }
                          })(),
                          productos: paramEditProd,
                          codUsuario: distSelec ? distSelec : "",
                        }}
                        //param={{}}
                        tipo="editar"
                        preConfirmarClickSi={clickPreConfirmarEditarSi}
                        preConfirmarClickNo={clickPreConfirmarEditarNo}
                        confirmarClick={ClickConfirmarEditar}
                        cambiaCampoClick={cambiarValores}
                        cambiarCategoria={cambiarCategoria}
                        cambiarEstadoOpcion={cambiarEstadoOpcion}
                        cambiarEstadoOpcion2={cambiarEstadoOpcion2}
                        cambiarHabilitarProd={cambiarHabilitarProd}
                        procesandoDetalle={procesandoDetalle}
                      />
                    </div>
                  </div>
                </>
              )}
              {eliminarProducto && (
                <>
                  <div
                    style={estilos.eliminarProductoStyle(datos.esComputadora)}
                  >
                    {preConfirmacionEliminar && (
                      <div
                        style={{
                          color: "black",
                          background:
                            "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 100%)",
                          //background: "linear-gradient(90deg, rgba(173,103,38,1) 0%, rgba(255,255,255,1) 48%, rgba(173,103,38,1) 100%)",
                          padding: "5px 5px 5px 5px",
                          boxSizing: "border-box",
                          borderTop: "0.1px solid #d9d9d9",
                          borderBottom: "0.1px solid #d9d9d9",
                          marginTop: "5px",
                          gridArea: datos.esComputadora
                            ? " 1 / 1/ 2 / 11"
                            : " 1 / 1/ 2 / 11",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        ¿Seguro que deseas continuar?
                        <button
                          style={{ margin: "0px 10px 0px 10px" }}
                          onClick={clickPreConfirmarEliminarSi}
                        >
                          Si
                        </button>
                        <button onClick={clickPreConfimarEliminarNo}>No</button>
                      </div>
                    )}
                    <div
                      style={{
                        gridArea: datos.esComputadora
                          ? " 2 / 1/ 3 / 11"
                          : " 2 / 1/ 3 / 11",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Se elmininaran los siguientes productos:
                    </div>
                    <div
                      style={{
                        gridArea: datos.esComputadora
                          ? " 3 / 1/ 9 / 11"
                          : " 3 / 1/ 9 / 11",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {procesandoDetalle && (
                        <div
                          style={estilos.contProcesandoDetalle(
                            datos.esComputadora
                          )}
                        >
                          <div
                            style={estilos.centrarProcesandoDetalle(
                              datos.esComputadora
                            )}
                          >
                            {procesandoDetalle ? (
                              <>
                                <estilos.LoadingSpinner
                                  dimensiones="50px"
                                  spinnercolor="#AD6726" // Color del spinner
                                  backgroundcolor="#ccc" // Color de fondo del spinne
                                  mensaje={
                                    datos.procesandoDetalle
                                      ? "Eliminando producto, espera un momento"
                                      : ""
                                  }
                                />
                              </>
                            ) : (
                              <div>Contenido cargado</div>
                            )}
                          </div>
                        </div>
                      )}
                      {!procesandoDetalle && (
                        <estilos.ScrollableContainer>
                          <ul>
                            {Object.keys(paramEliminarProd).map((elemento) => {
                              return (
                                <li key={paramEliminarProd[elemento].id}>
                                  {paramEliminarProd[elemento].getAttribute(
                                    "dsc"
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </estilos.ScrollableContainer>
                      )}
                    </div>
                    <div
                      style={{
                        gridArea: datos.esComputadora
                          ? " 9 / 1/ 11 / 5"
                          : " 9 / 1/ 11 / 5",
                        paddingTop: "1vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Boton
                        style={{
                          backgroundColor: "#2D8B0B",
                          borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
                          padding: datos.esComputadora ? "0.8vw" : "2vw",
                        }}
                        textoStyle={{
                          color: "white",
                          width: "100%",
                          fontSize: datos.esComputadora ? "1vw" : "2.5vw",
                        }}
                        texto="Confirmar"
                        tipo="submit"
                        estaProcesando={estaProcesando}
                        onClick={ClickConfirmarEliminar}
                      />
                    </div>
                    <div
                      style={{
                        gridArea: datos.esComputadora
                          ? " 9 / 7/ 11 / 11"
                          : " 9 / 7/ 11 / 11",
                        paddingTop: "1vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Boton
                        style={{
                          backgroundColor: "#E12121",
                          borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
                          padding: datos.esComputadora ? "0.8vw" : "2vw",
                        }}
                        textoStyle={{
                          color: "white",
                          width: "100%",
                          fontSize: datos.esComputadora ? "1vw" : "2.5vw",
                        }}
                        texto="Cancelar"
                        tipo="submit"
                        tonalidad="20"
                        borde="5px"
                        onClick={() => {
                          setModal(false);
                          setpreConfirmacionEliminar(false);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {procesandoProductos && (
        <div style={estilos.procesandoProductosStyle(datos.esComputadora)}>
          <div style={estilos.centrarProcesandoDetalle(datos.esComputadora)}>
            {procesandoProductos ? (
              <>
                <estilos.LoadingSpinner
                  dimensiones="50px"
                  spinnercolor="#AD6726" // Color del spinner
                  backgroundcolor="#ccc" // Color de fondo del spinne
                  mensaje="Cargando los productos, espera un momento"
                />
              </>
            ) : (
              <div>Contenido cargado</div>
            )}
          </div>
        </div>
      )}
      <div id="titulo" style={estilos.titulo(datos.esComputadora)}>
        Producto Terminado
      </div>
      <div style={estilos.filtros(datos.esComputadora)}>
        <div style={{ padding: "0.1vw" }}>
          <Input
            style={{}}
            textoStyle={{}}
            id="fproducto"
            moderno={"Producto"}
            tipo="text"
            onChange={(e) => {
              setfproducto(e.currentTarget.value.toUpperCase().trim());
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={""}
            esComputadora={datos.esComputadora}
            limpiar={fproducto === "" ? true : false}
          />
        </div>
        <div style={{ padding: "0.1vw" }}>
          <Input
            style={{}}
            textoStyle={{}}
            id="fcategoria"
            moderno={"Categoría"}
            tipo="text"
            onChange={(e) => {
              setfcategoria(e.currentTarget.value.toUpperCase().trim());
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={""}
            esComputadora={datos.esComputadora}
            limpiar={fcategoria === "" ? true : false}
          />
        </div>
        <div style={{ padding: "0.1vw" }}>
          <Input
            style={{}}
            textoStyle={{}}
            id="fprecio"
            moderno={"precio ≥ a"}
            tipo="number"
            onChange={(e) => {
              setfprecio(e.currentTarget.value.toUpperCase().trim());
              //filtrosNomApe(e.currentTarget.value)
            }}
            valorInicial={""}
            esComputadora={datos.esComputadora}
            limpiar={fprecio === "" ? true : false}
          />
        </div>
        <div
          style={{
            padding: "0.1vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Boton
            style={{
              backgroundColor: "#E12121",
              borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
              padding: datos.esComputadora ? "0.8vw" : "1vw",
            }}
            textoStyle={{
              color: "white",
              width: "100%",
              fontSize: datos.esComputadora ? "1vw" : "2.3vw",
              padding: "0",
            }}
            texto="Limpiar Filtros"
            tipo="submit"
            estaProcesando={false}
            onClick={(event) => {
              event.preventDefault();
              //setactualizo(e => !e)
              setfproducto("");
              setfcategoria("");
              setfprecio("");
            }}
          />
        </div>
      </div>
      <div style={estilos.opcionDistCabecera(datos.esComputadora)}>
        <Opciones
          style={{}}
          valorInicial={""}
          id="listaDistProductos"
          formPersona="entra"
          moderno="Distribuidores"
          opciones={distribuidores !== null ? distribuidores : []}
          clave="codUsuario"
          valor={["Nombre", "ApellidoPaterno"]}
          esComputadora={datos.esComputadora}
          onClick={(e) => {
            setDistSelec(e);
          }}
        />
      </div>
      {!procesandoProductos && (
        <div
          id="contenedorPrincipal"
          style={estilos.contenedorPrincipalListaStyle(datos.esComputadora)}
        >
          <div
            id="contenedorListado"
            style={estilos.contenedorLista(datos.esComputadora)}
          >
            <div
              id="contenido"
              style={estilos.contenidoStyle(datos.esComputadora)}
            >
              {productos !== null &&
                productos.map((catProducto) => {
                  return (
                    <div
                      key={"contenedor" + catProducto.codigoCategoria}
                      id={"contenedor" + catProducto.codigoCategoria}
                      style={estilos.cardCategoria(
                        datos.esComputadora,
                        catProducto.activo
                      )}
                    >
                      <div
                        id={"categoria" + catProducto.codigoCategoria}
                        catprod={catProducto.codigoCategoria}
                        style={estilos.nomCatProdStyle(
                          datos.esComputadora,
                          catProducto.activo
                        )}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: datos.esComputadora ? '1.5vw':'20vw', 
                          }}
                        >
                          <input
                            id={catProducto.codigoCategoria}
                            type="checkbox"
                            
                            onChange={seleccionarTodosEvento}
                          />
                          <span
                            style={{
                              fontWeight: "100",
                              fontSize: datos.esComputadora ? "1vw" : "2vw",
                            }}
                          >
                            Seleccionar Todos
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: datos.esComputadora ? "1vw" : "2.5vw",
                          }}
                        >
                          {catProducto.categoria}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: distSelec
                              ? "#ccc"
                              : "rgb(45, 139, 11)",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "15px",
                            cursor: "pointer",
                            filter:
                              "drop-shadow(0.5px 1px 2px rgba(1, 1, 1, 0.5))",
                          }}
                          onClick={
                            distSelec ? undefined : clickAgregarProductoEvento
                          }
                        >
                          <img
                            src={agregar}
                            style={{
                              ...estilos.imagenBotonAgregarStyle(
                                datos.esComputadora
                              ),
                              cursor: distSelec ? "not-allowed" : "pointer",
                            }}
                          />
                          {datos.esComputadora ? "Agregar" : ""}
                        </div>
                      </div>
                      <div
                        id="contenidoProductosCategoria"
                        style={estilos.contenidoProductosCategoriaStyle(
                          datos.esComputadora
                        )}
                      >
                        <div
                          id="contenidoInternoProdCategoria"
                          style={estilos.contIntCat(datos.esComputadora)}
                        >
                          <div
                            id="cabecera"
                            style={estilos.cabProductosStyle(
                              datos.esComputadora
                            )}
                          >
                            <div
                              style={estilos.checkboxStyle(datos.esComputadora)}
                            ></div>
                            <div
                              style={estilos.nombreProductoStyle(
                                datos.esComputadora
                              )}
                            >
                              Nombre Producto
                            </div>
                            <div
                              style={estilos.unidadPaqueteStyle(
                                datos.esComputadora
                              )}
                            >
                              Unidades X paquete
                            </div>
                            <div
                              style={estilos.precioDistribuidorStyle(
                                datos.esComputadora
                              )}
                            >
                              Precio para Distribuidores
                            </div>
                            <div
                              style={estilos.precioEmpleadosStyle(
                                datos.esComputadora
                              )}
                            >
                              {distSelec
                                ? "Precio del Distribuidor " + distSelec
                                : "Precio para Personal"}
                            </div>
                            <div
                              style={estilos.precioPublicoStyle(
                                datos.esComputadora
                              )}
                            >
                              Precio para Público
                            </div>
                            <div
                              style={estilos.stockActualStyle(
                                datos.esComputadora
                              )}
                            >
                              Stock Actual
                            </div>
                            <div
                              style={estilos.contenedorBotonEditarStyle(
                                datos.esComputadora
                              )}
                            >
                              Editar
                            </div>
                            <div
                              style={estilos.contenedorBotonEliminarStyle(
                                datos.esComputadora
                              )}
                            >
                              Eliminar
                            </div>
                          </div>
                          {catProducto.SMListaProductos.map((producto) => {
                            return (
                              <div
                                id={"" + producto.codProducto}
                                key={"producto" + producto.codProducto}
                                catprod={catProducto.codigoCategoria}
                                dsc={fg.capitalizar_palabra(
                                  producto.dscProducto
                                )}
                                style={estilos.contProductoStyle(
                                  datos.esComputadora,
                                  producto.activo
                                )}
                              >
                                <div
                                  style={estilos.contenedorCheckboxStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  <input
                                    id={
                                      catProducto.codigoCategoria +
                                      "-" +
                                      producto.codProducto
                                    }
                                    className={
                                      "categoria" +
                                      catProducto.codigoCategoria +
                                      " " +
                                      "producto"
                                    }
                                    type="checkbox"
                                    style={estilos.checkboxStyle(
                                      datos.esComputadora
                                    )}
                                    // Estado controlado del checkbox
                                    onChange={cambioEstadoEvento}
                                  ></input>
                                </div>
                                <div
                                  style={estilos.nombreProductoStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  {producto.dscProducto !== null
                                    ? fg.capitalizar_palabra(
                                        producto.dscProducto
                                      )
                                    : "-"}
                                </div>
                                <div
                                  style={estilos.unidadPaqueteStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  {producto.unidadesPaquete !== null
                                    ? producto.unidadesPaquete
                                    : "-"}
                                </div>
                                <div
                                  style={estilos.precioDistribuidorStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  {producto.precioDistribuidor !== null
                                    ? fg.formatear_moneda(
                                        producto.precioDistribuidor
                                      )
                                    : "-"}
                                </div>
                                <div
                                  style={estilos.precioEmpleadosStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  {producto.precioPersonal !== null
                                    ? fg.formatear_moneda(
                                        producto.precioPersonal
                                      )
                                    : producto.precioUnitario !== null
                                    ? fg.formatear_moneda(
                                        producto.precioUnitario
                                      )
                                    : "-"}
                                </div>
                                <div
                                  style={estilos.precioPublicoStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  {producto.precioPublico !== null
                                    ? fg.formatear_moneda(
                                        producto.precioPublico
                                      )
                                    : "-"}
                                </div>
                                <div
                                  style={estilos.stockActualStyle(
                                    datos.esComputadora
                                  )}
                                >
                                  {producto.stock !== null
                                    ? producto.stock
                                    : "-"}
                                </div>
                                <div
                                  style={estilos.contenedorBotonEditarStyle(
                                    datos.esComputadora
                                  )}
                                  onClick={clickEditarProductoEvento}
                                >
                                  <button
                                    style={estilos.botonEditarStyle(
                                      datos.esComputadora
                                    )}
                                  >
                                    <img
                                      src={editar}
                                      style={estilos.imagenBotonEditarStyle(
                                        datos.esComputadora
                                      )}
                                    />
                                  </button>
                                </div>
                                <div
                                  style={estilos.contenedorBotonEliminarStyle(
                                    datos.esComputadora
                                  )}
                                  onClick={
                                    distSelec
                                      ? undefined
                                      : clickBorrarProdEvento
                                  }
                                >
                                  <button
                                    style={estilos.botonEliminarStyle(
                                      datos.esComputadora
                                    )}
                                  >
                                    <img
                                      src={borrar}
                                      style={{
                                        ...estilos.imagenBotonEliminarStyle(
                                          datos.esComputadora
                                        ),
                                        filter: distSelec
                                          ? "invert(50%) brightness(1.5) drop-shadow(1px 1px 0.5px rgba(1, 1, 1, 0.5))"
                                          : "none",
                                        cursor: distSelec
                                          ? "not-allowed"
                                          : "pointer",
                                      }}
                                    />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <div
        id="opcionesSeleccionados"
        style={estilos.opcionesSeleccionados(datos.esComputadora)}
      >
        <div
          style={estilos.opcion(datos.esComputadora)}
          onClick={clickAgregarProdsEvento}
        >
          <Boton
            style={{
              backgroundColor: "#2D8B0B",
              borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
              padding: datos.esComputadora ? "0.8vw" : "1vh 2vw",
            }}
            textoStyle={{
              color: "white",
              width: "100%",
              fontSize: datos.esComputadora ? "1vw" : "2.5vw",
            }}
            texto="Agregar Producto"
            tipo="submit"
            estaProcesando={estaProcesando}
            onClick={(event) => {
              event.preventDefault();
            }}
            deshabilitado={distSelec ? true : false}
          />
        </div>
        <div
          style={estilos.opcion(datos.esComputadora)}
          onClick={clickEditarProdsEvento}
        >
          <Boton
            style={{
              backgroundColor: "#AD6726",
              borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
              padding: datos.esComputadora ? "0.8vw" : "1vh 2vw",
              ...estilos.botonOpcion(datos.esComputadora),
            }}
            textoStyle={{
              color: "white",
              width: "100%",
              fontSize: datos.esComputadora ? "1vw" : "2.5vw",
            }}
            texto="Editar"
            estaProcesando={estaProcesando}
            onClick={(event) => {
              event.preventDefault();
            }}
            deshabilitado={!seleccion}
            className="editarVarios"
          />
        </div>
        <div onClick={clickBorrarProdsEvento}>
          <Boton
            style={{
              backgroundColor: "#E12121",
              borderRadius: datos.esComputadora ? "0.5vw" : "1vw",
              padding: datos.esComputadora ? "0.8vw" : "1vh 2vw",
              ...estilos.botonOpcion(datos.esComputadora),
            }}
            textoStyle={{
              color: "white",
              width: "100%",
              fontSize: datos.esComputadora ? "1vw" : "2.5vw",
            }}
            texto="Eliminar"
            estaProcesando={estaProcesando}
            onClick={(event) => {
              event.preventDefault();
            }}
            deshabilitado={!seleccion}
            className="eliminarVarios"
          />
        </div>
      </div>
    </>
  );
}
export default AdministrarProductos;
