import styled from 'styled-components'
import * as fg from './../../../Api/FuncionesGlobales'

export const Contenido = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.$escomputadora
      ? '15% 25% 25% 5% 20% 5% 5%'
      : '30% 10% 10% 10% 20% 10% 10%'};
  grid-template-rows: 100% 30px;
  box-sizing: content-box;
  width: 100%;
  height: 10vh;

  #contenidoLogo {
    grid-area: ${props =>
      props.$escomputadora ? '1 / 1 / 2 / 2' : '1 / 1 / 2 / 2'};
    justify-self: center;
    align-self: center;
  }
  #contenidoLogo > img {
    width: ${props => (props.$escomputadora ? '72%' : '100%')};
    box-sizing: border-box;
    padding: ${props => (props.$escomputadora ? '10px 10px 10px 10px' : '5px')};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      width: 50%;
    }
  }
  #contenidoNotificacion {
    display: none;
    grid-area: ${props =>
      props.$escomputadora ? '1 / 4 / 2 / 5' : '1 / 4 / 2 / 5'};
    justify-self: center;
    align-self: center;
  }
  #contenidoNotificacion > img {
    display: none;
    width: ${props => (props.$escomputadora ? '60%' : '60%')};
    box-sizing: border-box;
  }
  #cotenidoInfoUsuario {
    grid-area: ${props =>
      props.$escomputadora ? '1 / 5 / 2 / 6' : '1 / 5 / 2 / 6'};
    justify-self: center;
    align-self: center;
    margin-right: 20px;
    display: inline-block;
    width: ${props => (props.$escomputadora ? '100%' : '100%')};
    height: ${props => (props.$escomputadora ? '100%' : '100%')};
    text-align: center;
    box-sizing: border-box;
    height: ${props => (props.$escomputadora ? 'auto' : 'auto')};

    
  }
  #cotenidoInfoUsuario > p {
    font-weight: 500;
    font-size: ${props => (props.$escomputadora ? '1vw' : '2vw')};
    margin: 0px;
    padding: 0px;
    line-height: 0.9;
    text-align: right;

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.4vw;
    }
  }
  #cotenidoInfoUsuario > p:nth-child(2) {
    font-weight: 400;
    font-size: ${props => (props.$escomputadora ? '1vw' : '2vw')};
    margin-top: 5px;
    padding: 0px;
    line-height: 0.9;
    text-align: right;
    color: #666666;
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.4vw;
    }
  }
  #contenidoFotoUsuario {
    display: flex;
    grid-area: ${props =>
      props.$escomputadora ? '1 / 6 / 2 / 7' : '1 / 6 / 2 / 7'};
    justify-self: center;
    align-self: center;
  }
  #contenidoFotoUsuario > div {
    background-color: white;
    border-radius: 100%;
    width: 90%;
    box-sizing: border-box;

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      width: 70%;
    }
  }
  #contenidoFotoUsuario > div > img {
    vertical-align: middle;
    filter: invert(100%) brightness(80%) contrast(80%);
    width: ${props => (props.$escomputadora ? '100%' : '100%')};
    box-sizing: border-box;
  }
  #contenidoOpcionesUsuario {
    grid-area: ${props =>
      props.$escomputadora ? '1 / 7 / 2 / 8' : '1 / 7 / 2 / 8'};
    justify-self: center;
    align-self: center;
    display: inline-block;
    width: ${props => (props.$escomputadora ? '100%' : '100%')};
    height: ${props => (props.$escomputadora ? '100%' : '100%')};
    text-align: center;
    box-sizing: border-box;
    height: ${props => (props.$escomputadora ? 'auto' : 'auto')};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      width: 80%;
    }
  }

  #contenidoOpcionesUsuario > img {
    filter: invert(100%) brightness(100%) hue-rotate(0deg)
      drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.7));
    margin-left: -70%;
    width: ${props => (props.$escomputadora ? '50%' : '70%')};
    box-sizing: border-box;

    transition: transform 0.3s ease;
    ${props => props.$mostraropcionesusuario && ` transform: rotate(-90deg);`}

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      margin-left: -130%;
    }
  }

  #contenidoOpcionesUsuario:hover {
    cursor: pointer;
  }

  #menuOpcionesUsuario {
    display: ${props => (props.$mostraropcionesusuario ? 'flex' : 'none')};
    align-items: center;
    grid-area: ${props =>
      props.$escomputadora ? '2 / 5 / 3 / 7' : '2 / 5 / 3 / 7'};
    width: 80%;
    height: ${props => (props.$escomputadora ? '2vw' : '5vw')};
    background-color: #dcad2f;
    z-index: 10;
    box-sizing: border-box;
    position: relative;
    top: ${props => (props.$escomputadora ? '-2vw' : '-4vw')};
    right: -28%;
    border: 1px solid #bbbbbb;
    border-radius: 5px 0px 5px 5px;
    box-shadow: 0 0 0.5vw rgb(100, 100, 100);
  }

  #menuOpcionesUsuario > div {
    font-weight: 500;
    font-size: ${props => (props.$escomputadora ? '1vw' : '2vw')};
    color: ${props => (props.$escomputadora ? '#DDDDDD' : 'black')};
    padding: 5%;
    line-height: 0.9;
    text-align: left;
    width: 100%;
  }
`
