import React, { useEffect, useState, useContext } from 'react'
import { Page, Text, View, Document, StyleSheet, BlobProvider, Image } from '@react-pdf/renderer'
import { Contexto } from '../../../../../Componentes/Principal/index'
import LogoSanMiguel from './../../../../../Imagenes/LogoSanMiguel.png'

import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as api from './../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import * as e from './StockPdfCSS'

function StockPdf (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [listaDistribuidores, setListaDistribuidores] = useState([])
  const [listaAyudantes, setListaAyudantes] = useState([])
  const [limpiar, setLimpiar] = useState(false)
  const [distribuidor, setDistribuidor] = useState([])
  const [usuario, setUsuario] = useState([])
  const [data, setData] = useState([])
  const [usuariosSelect, setUsuaiosSelect] = useState([])
  const [fecha, setFecha] = useState('')

  const [pdfListo, setpdfListo] = useState(false)

  const [reporte, setReporte] = useState([])

  useEffect(() => {
    const llamado = async () => {
      try {
        const distribuidores = await api.listaDistribuidores()
        if (distribuidores && distribuidores.SMListaErrores.length === 0) {
          setListaDistribuidores(distribuidores.Distribuidores)
        } else {
          alert('Hubo un error. Se recargará la página.')
          window.location.reload()
        }
      } catch (error) {
        alert('Hubo un problema con la conexión. Se recargará la página.')
        window.location.reload()
      }
    }
    llamado()
  }, [])
  const cambiarAyudantes = async e => {
    if (e === '') {
      setListaAyudantes([])
    } else {
      const ayudantes = await api.listarAyudantesDistribuidor(e)
      if (ayudantes && ayudantes.SMListaErrores.length === 0) {
        setListaAyudantes(ayudantes.ayudanteDistribuidores)
      } else {
        alert('Hubo un error. Se recargará la página.')
        window.location.reload()
      }
    }
  }
  const agregarUsuario = async event => {
    console.log(usuario)
    if (usuario.length !== 0) {
      let datos = [...data]
      let usuariosApi = [...usuariosSelect]
      if (!datos.some(fila => fila[0] === usuario[0])) {
        datos.push(usuario)
        usuariosApi.push(usuario[0])
        setData(datos)
        setUsuaiosSelect(usuariosApi)
      }
    }
  }
  const eliminarUsuario = async event => {
    let usuarioElim = event.currentTarget.parentNode.parentNode.parentNode.id
    let datos = [...data]
    let usuariosApi = [...usuariosSelect]
    datos = datos.filter(fila => fila[0] !== usuarioElim)
    usuariosApi = usuariosApi.filter(item => item !== usuarioElim)
    setData(datos)
    setUsuaiosSelect(usuariosApi)
  }
  const generarpdf = async event => {
    if (fecha) {
      const reporte = await api.reporteStock(usuariosSelect, fecha)
      console.log(reporte)
      if (reporte && reporte.SMListaErrores.length === 0) {
        setReporte(reporte.programacion)
        setpdfListo(true)
      } else {
        alert('Hubo un error. Se recargara la página.')
        window.location.reload()
      }
    } else {
      alert('Ingrese una fecha')
    }
  }
  useEffect(() => {
    console.log(distribuidor)
  }, [distribuidor])
  const pdfDocument = () => (
    <Document title='San Miguel Reporte Generar Pedido de Producción' author='San Miguel' subject='Reporte' keywords='Reporte San Miguel' pdfVersion='1.5' wrap='true'>
      <Page size='A4' style={e.docpdf.page} orientation='landscape'>
        <View style={e.docpdf.headerDocument}>
          <View style={e.docpdf.informationDocument}>
            <Text>{'Fecha: ' + fg.obtenerFechaActual() + '    Hora: ' + fg.obtenerHoraActual()}</Text>
            <Text>{usuariosesion}</Text>
          </View>
          <View style={e.docpdf.titleDocument}>
            <Text>Reporte de Stock</Text>
          </View>
          <Text style={e.docpdf.logo}>
            <Image
              style={{
                width: '10cm',
                marginVertical: 15,
                marginHorizontal: 100
              }}
              src={LogoSanMiguel}
            />
          </Text>
        </View>
        <View style={e.docpdf.bodyDocument}>
          {console.log(reporte)}
          {reporte.map(cat => {
            return (
              <View key={cat.codigoCategoria} style={e.docpdf.headerCategory}>
                <View style={e.docpdf.headerCategory}>
                  <Text>{fg.capitalizar_palabra(cat.categoria || '')}</Text>
                </View>
                <View style={e.docpdf.bodyCategory}>
                  <View style={e.docpdf.headerBody}>
                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '15%'
                      }}>
                      <Text style={{ textAlign: 'center' }}>Prod. Producción</Text>
                    </View>
                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '15%'
                      }}>
                      <Text>Prod. Terminado</Text>
                    </View>
                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '5%'
                      }}>
                      <Text>Stock P. Term.</Text>
                    </View>

                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '34%',
                        flexDirection: 'column'
                      }}>
                      <Text style={{ borderBottom: '1px solid black ' }}>Stock Necesario</Text>
                      <View
                        style={{
                          width: '100%',
                          flexDirection: 'row'
                        }}>
                        {cat.pedidos.map(ped => {
                          return (
                            <Text
                              key={ped[0]}
                              style={{
                                width: 100 / (cat.pedidos.length + 1) + '%',
                                borderRight: '1px solid black'
                              }}>
                              {ped[1]}
                            </Text>
                          )
                        })}
                        <Text
                          style={{
                            textAlign: 'center',
                            width: 100 - (cat.pedidos.length * 100) / (cat.pedidos.length + 1) + '%'
                          }}>
                          Total
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '8%'
                      }}>
                      <Text>Diferencia P. Term.</Text>
                    </View>

                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '10%'
                      }}>
                      <Text style={{ borderBottom: '1px solid black ' }}>Stock P. Prod.</Text>
                      <View
                        style={{
                          width: '100%',
                          flexDirection: 'row'
                        }}>
                        <Text
                          style={{
                            width: '33.3%',
                            borderRight: '1px solid black'
                          }}>
                          #
                        </Text>
                        <Text
                          style={{
                            width: '33.3%',
                            borderRight: '1px solid black'
                          }}>
                          PxP
                        </Text>
                        <Text
                          style={{
                            width: '33.3%'
                          }}>
                          T
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '5%'
                      }}>
                      <Text>Dif. Total P. Term.</Text>
                    </View>
                    <View
                      style={{
                        ...e.docpdf.cellHeaderBody,
                        width: '8%'
                      }}>
                      <Text> Prod. Faltante</Text>
                    </View>
                  </View>
                  <View style={e.docpdf.bodyBody}>
                    {cat.productosProduccion.map((pp, index) => {
                      return (
                        <View
                          style={{
                            flexDirection: 'row',
                            backgroundColor: index % 2 === 1 ? '#dddddd' : 'white'
                          }}>
                          <View
                            style={{
                              ...e.docpdf.cellBodyBody,
                              width: '15%',
                              height: '100%',
                              justifyContent: 'center'
                            }}>
                            <Text>{fg.capitalizar_palabra(pp.productoProd)}</Text>
                          </View>
                          <View style={{ ...e.docpdf.cellBodyBody, width: '15%' }}>
                            <View>
                              {pp.stockTotalTerminado.map(stt => {
                                return (
                                  <View style={{}}>
                                    <Text
                                      style={{
                                        borderBottom: '1px solid black'
                                      }}>
                                      {fg.capitalizar_palabra(stt.productoTerm)}
                                    </Text>
                                  </View>
                                )
                              })}
                            </View>
                          </View>
                          <View style={{ ...e.docpdf.cellBodyBody, width: '5%' }}>
                            {pp.stockTotalTerminado.map(stt => {
                              return <Text style={{ borderBottom: '1px solid black' }}>{stt.stockTerminado}</Text>
                            })}
                          </View>
                          <View style={{ ...e.docpdf.cellBodyBody, width: '34%' }}>
                            {pp.stockTotalTerminado.map(stt => {
                              return (
                                <View
                                  style={{
                                    borderBottom: '1px solid black',
                                    flexDirection: 'row'
                                  }}>
                                  {stt.pedidos.map((ped, index) => {
                                    return (
                                      <Text
                                        style={{
                                          width: 100 / (stt.pedidos.length + 1) + '%'
                                        }}>
                                        {ped}
                                      </Text>
                                    )
                                  })}
                                  <Text
                                    style={{
                                      width: 100 - (stt.pedidos.length * 100) / (stt.pedidos.length + 1) + '%',
                                      borderLeft: '1px solid black',
                                      backgroundColor: index % 2 === 0 ? '#e9fbff' : '#cef4fd'
                                    }}>
                                    {stt.total_pedidos}
                                  </Text>
                                </View>
                              )
                            })}
                          </View>
                          <View
                            style={{
                              ...e.docpdf.cellBodyBody,
                              width: '8%',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}>
                            <View style={{ flexDirection: 'column', width: '50%' }}>
                              {pp.stockTotalTerminado.map(stt => {
                                return (
                                  <Text
                                    style={{
                                      borderBottom: '0.5px solid black',
                                      borderRight: '0.5px solid black'
                                    }}>
                                    {stt.falta}
                                  </Text>
                                )
                              })}
                            </View>
                            <Text
                              style={{
                                width: '50%',
                                //backgroundColor: 'green',
                                alignItems: 'center'
                              }}>
                              {fg.formatearDecimal(pp.falta_paquete_pt, 1)}
                            </Text>
                          </View>
                          <View
                            style={{
                              ...e.docpdf.cellBodyBody,
                              width: '10%',
                              flexDirection: 'row'
                            }}>
                            <View
                              style={{
                                width: '33.3%',
                                height: '100%',
                                justifyContent: 'center',
                                borderRight: '1px solid black'
                              }}>
                              <Text>{pp.stockProduccionProd.producciones}</Text>
                            </View>
                            <View
                              style={{
                                width: '33.3%',
                                height: '100%',
                                justifyContent: 'center',
                                borderRight: '1px solid black'
                              }}>
                              <Text>{pp.stockProduccionProd.paquetes}</Text>
                            </View>
                            <View
                              style={{
                                width: '33.3%',
                                height: '100%',
                                justifyContent: 'center'
                              }}>
                              <Text>{pp.stockProduccionProd.paquetes_total}</Text>
                            </View>
                          </View>
                          <View
                            style={{
                              ...e.docpdf.cellBodyBody,
                              width: '5%',
                              justifyContent: 'center'
                            }}>
                            <Text>{fg.formatearDecimal(pp.falta_paquete_pp, 1)}</Text>
                          </View>
                          <View
                            style={{
                              ...e.docpdf.cellBodyBody,
                              width: '8%',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}>
                            <View style={{ width: '50%' }}>
                              <Text>{pp.requiere.decimal}</Text>
                            </View>
                            <View
                              style={{
                                width: '50%',
                                borderLeft: '1px solid black',
                                height: '100%',
                                justifyContent: 'center',
                                backgroundColor: index % 2 === 0 ? '#e9fbff' : '#cef4fd'
                              }}>
                              <Text>{pp.requiere.entero}</Text>
                            </View>
                          </View>
                        </View>
                      )
                    })}
                  </View>
                </View>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
  return (
    <>
      <e.Contenido $escomputadora={esComputadora} $usuarios={data.length}>
        <div className='cabecera'>Reporte de Stock</div>
        <div className='detalle'>
          <div className='agregar'>
            <div>
              <Opciones
                id='OpcionesDistribuidor'
                formPersona='entra'
                alto='55px'
                moderno='Distribuidor'
                tipo='text'
                fontSize='0.9em'
                opciones={listaDistribuidores ? listaDistribuidores : []}
                clave='codUsuario'
                valor={['Nombre', 'ApellidoPaterno']}
                esComputadora={esComputadora}
                onClick={e => {
                  setLimpiar(true)
                  cambiarAyudantes(e)
                }}
                onChange={e => {
                  if (e.target.value !== '') {
                    setUsuario([e.target.value, e.target.options[e.target.selectedIndex].text])
                    setDistribuidor([e.target.value, e.target.options[e.target.selectedIndex].text])
                  } else {
                    setUsuario([])
                    setDistribuidor([])
                  }
                }}
                valorInicial={distribuidor[0]}
                estilos={{
                  backgroundColor: '#EFEFEF4D',
                  border: '2px solid #bababa'
                }}
              />
            </div>
            <div>
              <Opciones
                id='OpcionesAyudante'
                formPersona='entra'
                alto='55px'
                moderno='Ayudante'
                tipo='text'
                fontSize='0.9em'
                opciones={listaAyudantes ? listaAyudantes : []}
                clave='codUsuario'
                valor={['Nombre', 'ApellidoPaterno']}
                esComputadora={esComputadora}
                onClick={e => {
                  setLimpiar(false)
                }}
                estilos={{
                  backgroundColor: '#EFEFEF4D',
                  border: '2px solid #bababa'
                }}
                onChange={e => {
                  e.target.value !== '' ? setUsuario([e.target.value, e.target.options[e.target.selectedIndex].text]) : setUsuario(distribuidor)
                }}
                limpiar={limpiar}
              />
            </div>
          </div>
          <div className='botonagregar'>
            <Boton
              style={{
                backgroundColor: 'rgb(220, 173, 47)',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'black',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.75vw',
                padding: '0'
              }}
              texto='Agregar Usuario'
              tipo='submit'
              //estaProcesando={false}
              onClick={agregarUsuario}
            />
          </div>
          <div className='usuariosselec'>
            <div>Usuarios Seleccionados</div>
            <div className='areausuariostam'>
              <div className='areausuarios'>
                {/* map */}
                {data &&
                  data.map(e => (
                    <div className='usuario' id={e[0]} key={e[0]}>
                      <div>
                        <Boton
                          style={{
                            backgroundColor: '#E12121',
                            border: '0.15vw solid rgb(207, 205, 205)',
                            borderRadius: '50%',
                            width: esComputadora ? '1.85vw' : '4.5vw',
                            height: esComputadora ? '60%' : '50%',
                            justifyContent: 'center'
                            //padding: esComputadora ? '0.5vw' : '1vw'
                          }}
                          Promise
                          textoStyle={{
                            color: 'rgb(255, 255, 255)',
                            width: '100%',
                            fontSize: esComputadora ? '1.3vw' : '2.2vw'
                          }}
                          texto='X'
                          tipo='submit'
                          //estaProcesando={estaProcesando}
                          onClick={eliminarUsuario}
                        />
                      </div>
                      <div>{e[1]}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='fechaOpcion'>
            <Input id='fecha' moderno='Fecha de Recojo' tipo='date' onChange={e => setFecha(e.target.value)} valorInicial={fecha} esComputadora={esComputadora} />
          </div>
          <div className='generarPedido'>
            <Boton
              style={{
                backgroundColor: 'rgb(45, 139, 11)',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.75vw',
                padding: '0'
              }}
              texto='Generar Reporte'
              tipo='submit'
              //estaProcesando={false}
              onClick={generarpdf}
            />
          </div>
        </div>
        {pdfListo && (
          <BlobProvider document={pdfDocument()}>
            {({ blob, url, loading, error }) => {
              if (loading) return <div>Cargando...</div>
              if (error) return <div>Error al cargar el PDF</div>

              return (
                <>
                  {esComputadora && (
                    <div className='PDFContent'>
                      {/* Mostrar el PDF en el iframe */}
                      <iframe className='pdf' src={url} title='PDF Viewer' />
                      {/* Enlace de descarga con nombre personalizado */}
                    </div>
                  )}
                  {pdfListo && (
                    <div className='PDFContentDownload'>
                      <a href={url} download={'Pedidos Producción (' + fg.fechayhoraactualStr() + ')'}>
                        Descargar PDF
                      </a>
                    </div>
                  )}
                </>
              )
            }}
          </BlobProvider>
        )}
      </e.Contenido>
    </>
  )
}

export default StockPdf
