import React, { useState, useEffect } from 'react'
import './InicioSesion.css'
import Input from './../../Elementos/Input'
import Boton from './../../Elementos/Boton'
import LogoSanMiguel from './../../Imagenes/LogoSanMiguel.png'
import { iniciarSesion, validarSesion } from '../../Api/Api'
import { useNavigate } from 'react-router-dom'
import * as e from './InicioSesionCSS'
import * as fg from './../../Api/FuncionesGlobales'

function InicioSesion (param) {
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [estaProcesando, setestaProcesando] = useState(false)
  const [usuario, setUsuario] = useState('')
  const [contrasenia, setContrasenia] = useState('')
  const [usuarioVacio, setUsuarioVacio] = useState(false)
  const [contraseniaVacio, setContraseniaVacio] = useState(false)
  const [errores, seterrores] = useState(null)
  const [hayError, setHayError] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const validar = async () => {
      const respuesta = await validarSesion()
      if (
        respuesta.SMListaErrores.length === 0 &&
        respuesta.estado === 'activo'
      ) {
        navigate('/principal')
      }
    }
    validar()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const cambioUsuario = event => {
    setUsuario(event.target.value)
    setUsuarioVacio(false)
  }

  const cambioContrasenia = event => {
    setContrasenia(event.target.value)
    setContraseniaVacio(false)
  }
  const ingresarBotonClick = async event => {
    event.preventDefault()
    if (usuario === '') {
      setUsuarioVacio(true)
      setHayError(true)
      seterrores({
        SMListaErrores: [
          { codError: 1, descripcionError: '⚠️No puede haber campos vacíos.' }
        ]
      })
    }
    if (contrasenia === '') {
      setContraseniaVacio(true)
      setHayError(true)
      seterrores({
        SMListaErrores: [
          { codError: 1, descripcionError: '⚠️No puede haber campos vacíos.' }
        ]
      })
    }
    if (usuario !== '' && contrasenia !== '') {
      setestaProcesando(true)
      setHayError(false)
      const respuesta = await iniciarSesion(
        usuario.trim().toUpperCase(),
        contrasenia.trim()
      )
      setestaProcesando(false)

      if (respuesta.SMListaErrores.length !== 0) {
        setHayError(true)
        seterrores(respuesta)
      } else {
        setHayError(false)
        navigate('/principal')
      }
    }
  }
  return (
    <e.Contenido $escomputadora={esComputadora}>
      <div id='contenedor'>
        <div id='contenedorLogin'>
          <div id='estiloCabecera'>Iniciar Sesión</div>
          <form id='formIngreso'>
            <Input
              id='codigoUsuario'
              moderno='Código de Usuario'
              tipo='text'
              onChange={cambioUsuario}
              esComputadora={esComputadora}
              valorInicial={''}
            />
            <Input
              id='contrasenia'
              moderno='Contraseña'
              tipo='password'
              autoComplete
              onChange={cambioContrasenia}
              esComputadora={esComputadora}
              valorInicial={''}
            />
            <Boton
              id='botonIngresar'
              texto='Ingresar'
              tipo='submit'
              estaProcesando={estaProcesando}
              onClick={ingresarBotonClick}
            />
            {hayError
              ? errores.SMListaErrores.map(i => {
                  return (
                    <p id='errorParrafo' key={i.codError}>
                      {i.descripcionError}
                    </p>
                  )
                })
              : ''}
          </form>
        </div>
        <div id='contenedorLogo'>
          <img id='imagenLogo' src={LogoSanMiguel} alt='Logo' />
          <div id='eslogan'>Un Gustito que te Bendice</div>
        </div>
      </div>
    </e.Contenido>
  )
}

export default InicioSesion
