import styled from 'styled-components'

// Contenedores principales
export const Contenido = styled.div`
  grid-area: 1/1/21/21;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);

  .cabecera {
    grid-area: 1/1/2/21;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(173, 103, 38);
    font-size: ${props => (props.$escomputadora ? '2' : '4')}vw;
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;
  }
  .categoria > .cabecera {
    width: ${props => (props.$escomputadora ? '100' : '175')}%;
  }
  .filtro {
    grid-area: 2/1/4/21;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50% 50%;
    background-color: rgb(250, 250, 250);
    box-shadow: rgb(129, 129, 129) 0px 0px 0.3vw inset;
    padding: 0.5vw;
    box-sizing: border-box;
    height: ${props => (props.$escomputadora ? '10' : '20')}vw;
  }
  .filtroopciones {
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    grid-template-rows: 100%;
    padding: 0.2vw;
    box-sizing: border-box;
  }
  .tabla {
    grid-area: 4/1/19/21;
    //background-color: rgb(250, 250, 250);
    overflow: auto;
    padding: 1vw 1vw;
    box-shadow: rgb(129, 129, 129) 0px 0px 0.3vw inset;
    box-sizing: border-box;
    border: 0.1vw solid rgb(207, 205, 205);
    overflow: auto;
    width: 100%;
    height: auto;
    background-color: rgb(250, 250, 250);
  }
  .categoria {
    background-color: white;
    margin-bottom: 1vw;
    border-radius: 1vw 1vw 1vw 1vw;
    box-shadow: 0px 0px 1vw rgb(110, 110, 110);
    box-sizing: border-box;
  }
  .cuerpo {
    overflow: auto;
    width: ${props => (props.$escomputadora ? '100' : '175')}%;
    height: auto;
    border-radius: 1vw 1vw 1vw 1vw;
  }
  .descripcion {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 25% 10% 10% 10% 10% 10% 25%;
  }
  .descripcion > div {
    color: black;
    font-family: verdana;
    border-bottom: 2px solid rgb(138, 138, 138);
    text-decoration-thickness: 0.3vw;
    font-weight: 900;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1.2' : '3')}vw;
    background-color: rgb(253, 249, 226);
  }
  .detallePedido {
    height: auto;
    color: black;
    padding: 0.5vw;
    box-sizing: border-box;
    overflow: auto;
    background-color: rgb(250, 250, 250);
  }
  .detallePedido > div.productos:nth-child(even) {
    background-color: rgb(240, 240, 240);
  }
  .productos {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 25% 10% 10% 10% 10% 10% 25%;
    color: black;
    padding: 0.5vw;
    box-sizing: border-box;
    overflow: auto;
  }
  .productos > div {
    color: black;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
  }
  input {
    height: 100%;
    width: 80%;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.3')}vw;
  }
  .opciones {
    grid-area: 19/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgb(253, 249, 226);
    border-radius: 0vw 0vw 1vw 1vw;
    box-sizing: border-box;
    box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
    padding: ${props => (props.$escomputadora ? '2' : '5')}vw;
    
  }
  .botonOpciones {
    width: ${props => (props.$escomputadora ? '15%' : '30%')};
    //height: 60%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
