import { useContext, useEffect, useState } from 'react'
import { Contexto } from '../../../../../Componentes/Principal/index'

import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as api from './../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import * as e from './AgregarAyudanteCSS'

function AgregarAyudante (props) {
  const { esComputadora, rol } = useContext(Contexto)
  const { pedido, ayudantes } = props.param
  const [estaProcesando, setestaProcesando] = useState(false)

  const [ayudanteSelect, setAyudanteSelect] = useState([])
  const [listarAyudantesDistribuidor, setListaAyudantes] = useState([])

  useEffect(() => {
    console.log(pedido)
    console.log(ayudantes)
    const llamado = async () => {
      const ayudatesM = await api.listarAyudantesMaestro(null, 'N')
      console.log(ayudatesM)
      if (ayudatesM) {
        if (ayudatesM.SMListaErrores.length === 0) {
          setListaAyudantes(ayudatesM.ayudantesMaestro)
        } else {
          alert('Hubo un error. Se recargara la página.')
          window.location.reload()
        }
      } else {
        alert('Hubo un problema con la conección. Se recargara la página.')
        window.location.reload()
      }
    }
    llamado()
  }, [])

  const agregarAyudanteMaestro = async event => {
    setestaProcesando(true)
    console.log("entre")
    const nuevo = await api.actualizarAyudanteProduccion(
      parseInt(pedido),
      ayudanteSelect,
      null,
      "N"
    )
    console.log(nuevo)
    if (nuevo) {
      if (nuevo.SMListaErrores.length === 0) {
        props.cerrarmodal()
      } else {
        alert(
          'Debe seleccionar el producto e ingresar la cantidad de produccion numerica'
        )
      }
    } else {
      alert(
        'Debe seleccionar el producto e ingresar la cantidad de produccion numerica'
      )
    }
  }
  return (
    <>
      <e.Contenido $escomputadora={esComputadora}>
        <div className='ayudanteMaestroClass'>
          <Opciones
            id='ayudanteMaestro'
            formPersona='entra'
            alto='55px'
            moderno={'Categoría de Producto'}
            tipo='text'
            fontSize='0.9em'
            opciones={
              listarAyudantesDistribuidor !== null
                ? listarAyudantesDistribuidor
                : []
            }
            clave='usuario'
            valor={['nombre', 'apellido_paterno']}
            esComputadora={esComputadora}
            onClick={e => setAyudanteSelect(e)}
            estilos={{
              backgroundColor: '#EFEFEF4D',
              border: '2px solid #bababa'
            }}
            //onChange={cambiaCampoClick}
            //estilos={estiloVacio(docSelec)}
          />
        </div>
        <div className='botonCancelar'>
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vw'
            }}
            Promise
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1.3vw' : '2.75vw',
              padding: '0'
            }}
            texto='Cancelar'
            tipo='submit'
            estaProcesando={estaProcesando}
            onClick={props.cerrarmodal}
          />
        </div>
        <div className='botonAgregar'>
          <Boton
            style={{
              backgroundColor: 'rgb(45, 139, 11)',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vw'
            }}
            Promise
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1.3vw' : '2.75vw',
              padding: '0'
            }}
            texto='Aceptar'
            tipo='submit'
            estaProcesando={estaProcesando}
            onClick={event => {agregarAyudanteMaestro(event)}}
          />
        </div>
      </e.Contenido>
    </>
  )
}
export default AgregarAyudante
