import styled from 'styled-components'

export const Modal = styled.div`
  position: absolute;
  z-index: ${props => props.$capa};
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #c1c1c1cc;
  display: flex;
  justify-content: center;
  align-items: center;

  .contenidoModal {
    background-color: white;
    height: ${props => props.$height}vh;
    width: ${props => props.$width}vw;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 4vh repeat(1, 1fr);
    border-radius: ${props => (props.$escomputadora ? '0.5' : '1.5')}vw;
    padding: 1vh 1vw;
    box-sizing: border-box;
  }
  .cabecera {
    position: relative;
    grid-area: 1/1/2/2;
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 1px solid black;
    //box-shadow: 0px 0px 2px black inset;
    //margin: -1vh -1vw 0 -1vw;
  }
  div.cabecera > span:nth-child(1) {
    font-size: ${props => (props.$escomputadora ? '1.5' : '3.5')}vw;
  }
  div.cabecera > span:nth-child(2) {
    position: absolute;
    right: 0;/*0.2vw;*/
    top: 0;/*0.2vh;*/
    width: ${props => (props.$escomputadora ? '2.5' : '5')}vw;

  }
  div.cuerpomodal {
    grid-area: 2/1/3/2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(props) => `${props.$height - 6}vh`};
  }
`
