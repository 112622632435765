import styled, { css } from 'styled-components'

export const contenedor = esComputadora => ({
  gridArea: '1/1/21/21',
  display: 'grid',
  gridTemplateRows: 'repeat(20,1fr)',
  gridTemplateColumns: 'repeat(20,1fr)',
  borderRadius: esComputadora ? '' : '',
  boxShadow: '0.1vw 0.1vw 1vw #aaaaaa'
})

export const titulo = esComputadora => ({
  gridArea: '1/1/2/21',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#AD6726',
  fontSize: esComputadora ? '2vw' : '4vw',
  color: 'white',
  fontWeight: 'bolder',
  borderRadius: esComputadora ? '0.5vw 0.5vw 0 0' : '1vw 1vw 0 0'
})
export const maestro = esComputadora => ({
  gridArea: '2/1/3/21',
  backgroundColor: 'rgb(250, 250, 250)',
  padding: '0.5vw',
  boxShadow: esComputadora
    ? 'inset rgb(129, 129, 129) 0px 0px 0.3vw'
    : 'inset rgb(129, 129, 129) 0px 0px 1.0vw'
})
export const opciones = esComputadora => ({
  gridArea: '19/1/21/21',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#AD672633',
  padding: '1vh',
  gap: '1% 1%',
  boxSizing: 'border-box',
  flexDirection: 'row',
  flexWrap: 'wrap',
  overflow: 'auto',
  order: ''
})

export const Filtros = styled.div`
  grid-area: 3 / 1 / 4 / 21;
  display: grid;
  grid-template-rows: 100%;
  background-color: rgb(250, 250, 250);
  padding: 0.5vw;
  box-sizing: border-box;
  box-shadow: ${props =>
    props.$esComputadora
      ? 'inset rgb(129, 129, 129) 0px 0px 0.3vw'
      : 'inset rgb(129, 129, 129) 0px 0px 1.0vw'};
  grid-template-columns: ${props =>
    props.$esComputadora ? '40% 26% 26% 8%' : '40% 23% 23% 14%'};
`

export const Contenido = styled.div`
  ::-webkit-scrollbar {
    width: 1px; /* Ancho del scrollbar */
    height: ${props => (props.$esComputadora ? '1.5vh' : '2vw')};
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color de fondo del track del scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Color del thumb del scrollbar */
    border-radius: 10px; /* Bordes redondeados del thumb */
    border: 3px solid #f1f1f1; /* Borde alrededor del thumb */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color del thumb cuando se hace hover */
  }

  grid-area: 4/1/19/21;
  background-color: ${props => (props.$esComputadora ? 'white' : 'white')};
  overflow: auto;
  padding: 1vw 1vw;
  button:hover {
    cursor: pointer;
  }
  --borde-esquinas: 1vw;
  --width-categoria: 100vw;
  .categorias {
    background-color: white;
    margin-bottom: 1vw;
    border: 0.1vw solid rgb(204, 204, 204);
    border-radius: var(--borde-esquinas);
  }

  .cabecera {
    background-color: #ad6726;
    font-size: ${props => (props.$esComputadora ? '1.3vw' : '2.5vw')};
    text-align: center;
    color: white;
    margin: 0;
    border-top-left-radius: var(--borde-esquinas);
    border-top-right-radius: var(--borde-esquinas);
    padding: 1vw 0;
  }

  .cuerpo {
    font-size: ${props => (props.$esComputadora ? '0.9vw' : '2.2vw')};
    height: auto;
    overflow: auto;
  }
  div > p {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 100%;
  }

  div.registros > p:hover {
    //background: rgb(196, 196, 196);
    cursor: pointer;
  }

  div.cuerpo > p:first-child {
    background-color: rgb(255, 255, 255);
    font-weight: bolder;
    text-align: center;
    cursor: auto;
    width: var(--width-categoria);
    padding: 0.5vw 1vw;
  }
  div.registros {
    padding: 0.5vw 1vw;
    width: var(--width-categoria);
  }
  div.cuerpo > div:last-of-type > p {
    border-radius: 0 0 var(--borde-esquinas) var(--borde-esquinas);
  }

  --padding-elementos-grilla: 0.1vw 0.5vw;
  --border-encabezado: 0.1vw solid black;

  p > span:first-child {
    overflow: auto;
    padding: var(--padding-elementos-grilla);
  }
  p > span:nth-child(n + 2) {
    padding: var(--padding-elementos-grilla);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  p.encabezados > span:first-child {
    border-left: var(--border-encabezado);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p.encabezados > span {
    border-right: var(--border-encabezado);
    padding: var(--padding-elementos-grilla);
  }
  div.registros:nth-child(even) {
    background-color: rgb(240, 240, 240);
  }
  button {
    border: none;
    background-color: transparent;
  }
  button > .img,
  button {
    padding: 0;
    margin-right: ${props => (props.$esComputadora ? '0.3' : '2')}vw;
    width: ${props => (props.$esComputadora ? '40' : '100')}%;
  }
  button > .img.mr,
  button > .img.pr {
    width: ${props => (props.$esComputadora ? '25' : '100')}%;
  }
  .maestrostablatdoby > tr > td:first-child,
  .maestrostablatdoby > tr > td:nth-child(2),
  .maestrostablatdoby > tr > td:nth-child(3),
  .maestrostablatdoby > tr > td:nth-child(4) {
    width: ${props => (props.$esComputadora ? '10' : '14')}vw;
  }
  .tproductotablatdoby > tr > td:first-child {
    width: ${props => (props.$esComputadora ? '15' : '15')}vw;
  }

  .tproductotablatdoby > tr > td:nth-child(2) {
    width: ${props => (props.$esComputadora ? '5' : '5')}vw;
  }
  .tproductotablatdoby > tr > td:nth-child(3) {
    width: ${props => (props.$esComputadora ? '10' : '2')}vw;
  }
  .maestrostabla {
    font-size: ${props => (props.$esComputadora ? '1' : '2')}vw;
  }
  .maestros.pterminado {
    background-color: rgb(255, 246, 222);
    display: none;
  }
  ${props =>
    props.$registroselec &&
    css`
      .registros > div.${props.$registroselec} {
        background-color: rgb(255, 246, 222);
        color: black;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: ${props =>
          props.$esComputadora ? '10% 90%' : '15% 85%'};

        height: ${props => (props.$esComputadora ? '10' : '20')}vw;

        box-shadow: 0px 0px 1vw rgba(0, 0, 0, 1);
        overflow: auto;
      }

      .registros > p.${props.$registroselec} {
        background-color: rgb(133, 133, 133);
        box-shadow: 0px 0px 1vw rgba(0, 0, 0, 1);
        color: white;
        transition: 0.2s all;
      }

      p.${props.$registroselec} > span:last-child {
        background-color: rgb(202, 202, 202);
      }

      .registros > div.${props.$registroselec} th,
      caption {
        text-decoration: underline;
        text-decoration-thickness: 0.2vw;
        font-weight: 900;
        //font-size: ${props => (props.$esComputadora ? '1vw' : '2vw')};
      }
      caption {
        font-family: verdana;
        color: white;
        background-color: #528d25;
        padding: 0.5vw 0vw;
        margin-bottom: 0.5vw;
        border-radius: 0 0 1vw 1vw;
      }
      table.tproductotabla > caption {
        color: black;
        background-color: #ecab00;
      }

      .maestrostabla,
      .tproductotabla {
        text-align: center;
        //border-spacing: 4vw 0.1vw;
        border-collapse: collapse;
      }
      .conttablas {
        width: ${props => (props.$esComputadora ? '100' : '130')}vw;
        overflow: auto;
        display: grid;
        grid-template-rows: 100%;
        grid-template-columns: ${props =>
          props.$esComputadora ? '55% 45%' : '55% 45%'};
      }
      table:first-child {
        height: 70%;
        border-right: 0.1vw solid black;
        margin-left: 0.1vw;
      }

      td > button {
        width: ${props => (props.$esComputadora ? '40' : '25')}%;
      }
    `}
`

//'invert(50%) brightness(1.5) drop-shadow(1px 1px 0.5px rgba(1, 1, 1, 0.5))'
