import { useState, useEffect, useContext } from 'react';
import { Contexto } from '../../../../../Componentes/Principal/index';
import * as e from './ComprobantesCSS';
import iconopdf from '../../../../../Imagenes/Iconos/iconopdf.png';
import xml from '../../../../../Imagenes/Iconos/xml.png';
import Input from '../../../../../Elementos/Input';
import Boton from '../../../../../Elementos/Boton';
import Opciones from '../../../../../Elementos/Opciones';

function Comprobantes(props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto);

  const [comprobantes, setComprobantes] = useState([]);
  const [tipos, setTipos] = useState([
    { Tipo: 'Boletas', Valor: 'Boletas' },
    { Tipo: 'Factura', Valor: 'Factura' }
  ]);
  const [tipo, setTipo] = useState('Boletas');
  const [fechaDesde, setFechaDesde] = useState('');
  const [fechaHasta, setFechaHasta] = useState('');

  const iconostyle = {
    width: esComputadora ? "24px" : "20px",
    height: "auto",
    margin: "0 5px",
  };

  // التأثيرات والوظائف تبقى كما هي
  useEffect(() => {
    if (tipo === 'Boletas') {
      setComprobantes([
        {
          fecha: '25/02/2025',
          codigo: 'EB01 - 5857',
          receptor: 'Cliente A',
          importe: 'S/27.00'
        },
        {
          fecha: '25/02/2025',
          codigo: 'EB01 - 5856',
          receptor: 'Cliente B',
          importe: 'S/544.00'
        },
        {
          fecha: '25/02/2025',
          codigo: 'EB01 - 5855',
          receptor: 'Cliente C',
          importe: 'S/429.00'
        },
        {
          fecha: '25/02/2025',
          codigo: 'EB01 - 5854',
          receptor: 'Cliente D',
          importe: 'S/610.50'
        }
      ]);
    } else {
      setComprobantes([
        {
          fecha: '24/02/2025',
          codigo: 'FA01 - 7857',
          receptor: 'Empresa X',
          importe: 'S/1000.00',
          estado: 'Pagado',
          metodoPago: 'Transferencia'
        },
        {
          fecha: '24/02/2025',
          codigo: 'FA01 - 7856',
          receptor: 'Empresa Y',
          importe: 'S/2500.00',
          estado: 'Pendiente',
          metodoPago: 'Tarjeta'
        },
        {
          fecha: '23/02/2025',
          codigo: 'FA01 - 7855',
          receptor: 'Empresa Z',
          importe: 'S/1850.00',
          estado: 'Pendiente',
          metodoPago: 'Efectivo'
        },
        {
          fecha: '23/02/2025',
          codigo: 'FA01 - 7854',
          receptor: 'Empresa W',
          importe: 'S/3200.00',
          estado: 'Pagado',
          metodoPago: 'Transferencia'
        }
      ]);
    }
  }, [tipo]);

  const handleEdit = (index, field, value) => {
    const updatedComprobantes = [...comprobantes];
    updatedComprobantes[index] = {
      ...updatedComprobantes[index],
      [field]: value
    };
    setComprobantes(updatedComprobantes);
  };

  const handleBuscar = () => {
    console.log('Buscando comprobantes...');
    console.log('Tipo:', tipo);
    console.log('Fecha desde:', fechaDesde);
    console.log('Fecha hasta:', fechaHasta);
  };

  const handleDownload = (type, codigo) => {
    alert(`Descargando ${type} para ${codigo}`);
  };

  const handleEmitir = type => {
    alert(`Emitiendo ${type}`);
  };

  return (
    <>
      <e.Contenido $escomputadora={esComputadora}>
        {/* Header Section - نفس الكود مع تحسين الاستجابة */}
        <div className='Header'>Boletas y Facturas</div>
        
        {/* Filter Section - إضافة تحسينات الاستجابة */}
        <div className='FilterSection'>
        <Opciones
              id='tipo'
              formPersona='entra'
              alto={esComputadora ? '55px' : '45px'}
              moderno={'Categoría de Producto'}
              tipo='text'
              fontSize={esComputadora ? '0.9em' : '0.8em'}
              opciones={tipos}
              clave='Tipo'
              valor={['Valor']}
              esComputadora={esComputadora}
              onClick={e => setTipo(e)}
              estilos={{
                backgroundColor: '#EFEFEF4D',
                border: '2px solid #bababa',
                fontSize: esComputadora ? '1em' : '0.9em'
              }}
            />
          <Input
              id='desde'
              moderno='Desde'
              tipo='date'
              onChange={e => setFechaDesde(e.target.value)}
              valorInicial=''
              esComputadora={esComputadora}
              estiloContainer={{
                fontSize: esComputadora ? '1em' : '0.9em'
              }}
            />
          <Input
              id='hasta'
              moderno='Hasta'
              tipo='date'
              onChange={e => setFechaHasta(e.target.value)}
              valorInicial=''
              esComputadora={esComputadora}
              estiloContainer={{
                fontSize: esComputadora ? '1em' : '0.9em'
              }}
            />
          <Boton
              style={{
                backgroundColor: 'rgb(45, 139, 11)',
                borderRadius: esComputadora ? '0.5vw' : '2vw',
                padding: esComputadora ? '0.8vw' : '0.6rem',
                height: '90%',
                width: '100%'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1.3vw' : '0.9rem',
                padding: '0'
              }}
              texto='Buscar'
              tipo='submit'
              onClick={handleBuscar}
            />
        </div>

        {/* Table Container - تحسينات الجدول */}
        <div className='TableContainer'>
          <e.Table $escomputadora={esComputadora}>
            <e.TableHead>
              <e.TableRow>
                <e.TableCell>Fecha de Emisión</e.TableCell>
                <e.TableCell>Código de Comprobante</e.TableCell>
                <e.TableCell>Receptor</e.TableCell>
                <e.TableCell>Total</e.TableCell>
                {tipo === 'Facturas' && (
                  <>
                    <e.TableCell>Estado</e.TableCell>
                    <e.TableCell>Método de Pago</e.TableCell>
                  </>
                )}
                <e.TableCell>XML / PDF</e.TableCell>
              </e.TableRow>
            </e.TableHead>
            <tbody>
              {comprobantes.map((item, index) => (
                <e.TableRow key={index}>
                  {[
                    'fecha',
                    'codigo',
                    'receptor',
                    'importe',
                    ...(tipo === 'Facturas' ? ['estado', 'metodoPago'] : [])
                  ].map(field => (
                    <e.TableCell
                      key={field}
                      contentEditable
                      suppressContentEditableWarning
                      onBlur={e => handleEdit(index, field, e.target.innerText)}
                      style={{
                        fontSize: esComputadora ? '1em' : '0.8em',
                        padding: esComputadora ? '15px' : '10px'
                      }}
                    >
                      {item[field] || ''}
                    </e.TableCell>
                  ))}
                  <e.TableCell style={{ 
                    textAlign: 'center',
                    padding: esComputadora ? '15px' : '10px'
                  }}>
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '10px'
                    }}>
                      <e.Button
                        className='xml'
                        onClick={() => handleDownload('XML', item.codigo)}
                        style={{
                          padding: esComputadora ? '8px' : '6px'
                        }}
                      >
                        <img src={xml} alt="xml" style={iconostyle} />
                      </e.Button>
                      <e.Button
                        className='pdf'
                        onClick={() => handleDownload('PDF', item.codigo)}
                        style={{
                          padding: esComputadora ? '8px' : '6px'
                        }}
                      >
                        <img src={iconopdf} alt="Iconopdf" style={iconostyle} />
                      </e.Button>
                    </div>
                  </e.TableCell>
                </e.TableRow>
              ))}
            </tbody>
          </e.Table>
        </div>

        {/* Action Buttons - تحسينات الأزرار */}
        <div className='ActionButtons'>
          <e.Button 
            className='boleta' 
            onClick={() => handleEmitir('Boleta')}
            style={{
              fontSize: esComputadora ? '1.1em' : '0.9em',
              padding: esComputadora ? '12px 24px' : '10px 20px'
            }}
          >
            Emitir Boleta
          </e.Button>
          <e.Button 
            className='factura' 
            onClick={() => handleEmitir('Factura')}
            style={{
              fontSize: esComputadora ? '1.1em' : '0.9em',
              padding: esComputadora ? '12px 24px' : '10px 20px'
            }}
          >
            Emitir Factura
          </e.Button>
        </div>
      </e.Contenido>
    </>
  );
}

export default Comprobantes;