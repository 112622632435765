import React, { useEffect, useState, useContext } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Image
} from '@react-pdf/renderer'
import { Contexto } from '../../../../../Componentes/Principal/index'
import * as api from './../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import * as e from './DetallePDFCSS'
import LogoSanMiguel from './../../../../../Imagenes/LogoSanMiguel.png'

function DetallePDF (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [pedido, setPedido] = useState(null)

  useEffect(() => {
    const llamado = async () => {
      try {
        console.log('Pedido ID recibido:', props.param.pedido)

        // التحقق من أن props.param.pedido موجود وصحيح
        if (!props.param.pedido) {
          throw new Error('El ID del pedido no está definido')
        }

        // استدعاء API
        const ped = await api.listaIngresoProductoMaestro(
          parseInt(props.param.pedido),
          props.param.pedido,
          'N'
        )

        console.log('📌 Pedido recibido de la API:', ped)

        // التحقق من أن الاستجابة تحتوي على البيانات المتوقعة
        if (!ped) {
          throw new Error('No se recibió ninguna respuesta del API')
        }

        if (ped.SMListaErrores && ped.SMListaErrores.length > 0) {
          throw new Error(
            `Errores en la respuesta: ${ped.SMListaErrores.join(', ')}`
          )
        }

        // استخراج productos من الاستجابة
        const productos =
          ped.productosJson || ped.productos || ped.detalles || []
        console.log('📦 Productos obtenidos:', productos)

        // تحويل productos إلى مصفوفة إذا لم تكن مصفوفة بالفعل
        const productosArray = Array.isArray(productos)
          ? productos
          : Object.values(productos)
        console.log('✅ Productos en pedido:', productosArray)

        // تعيين البيانات في pedido
        setPedido({ ...ped, productosJson: productosArray })
      } catch (error) {
        console.error('Error al cargar los datos:', error)
        setPedido({ productosJson: [] }) // تعيين productosJson كمصفوفة فارغة في حالة الخطأ
      }
    }

    llamado()
  }, [props.param.pedido])

  const pdfDocument = () => (
    <Document
      title='San Miguel Reporte Pedido de Producción'
      author='San Miguel'
      subject='Reporte'
      keywords='Reporte San Miguel'
      pdfVersion='1.5'
      wrap='true'
    >
      <Page size='A4' style={e.docpdf.page}>
        <View style={e.docpdf.headerDocument}>
          <View style={e.docpdf.informationDocument}>
            <Text>
              {'Fecha: ' +
                fg.obtenerFechaActual() +
                '    Hora: ' +
                fg.obtenerHoraActual()}
            </Text>
            <Text>{usuariosesion}</Text>
          </View>
          <View style={e.docpdf.titleDocument}>
            <Text>Pedido de Producción</Text>
          </View>
          <View style={e.docpdf.logo}>
            <Image style={{ width: '2cm'  }} src={LogoSanMiguel} />
          </View>
        </View>

        <View style={e.docpdf.tabla}>
          <View style={e.docpdf.filaEncabezado}>
            <Text style={e.docpdf.celdaEncabezado}>Nombre del Producto</Text>
            <Text style={e.docpdf.celdaEncabezado}>Cantidad Solicitada</Text>
            <Text style={e.docpdf.celdaEncabezado}>Cantidad Producida</Text>
          </View>

          {pedido?.productosJson?.length > 0 ? (
            pedido.productosJson.map((item, index) => (
              <View key={index} style={e.docpdf.fila}>
                <Text style={e.docpdf.celda}>
                  {item.producto.descripcion || 'Sin Nombre'}
                </Text>
                <Text style={e.docpdf.celda}>
                  {item.detalle.solicitado ?? 'N/A'}
                </Text>
                <Text style={e.docpdf.celda}>
                  {item.detalle.produccion ?? 'N/A'}
                </Text>
              </View>
            ))
          ) : (
            <View style={e.docpdf.fila}>
              <Text style={e.docpdf.celda}>No hay datos disponibles</Text>
            </View>
          )}
        </View>
      </Page>
    </Document>
  )

  return (
    <>
      <BlobProvider document={pdfDocument()}>
        {({ blob, url, loading, error }) => {
          if (loading) return <div>Cargando...</div>
          if (error) return <div>Error al cargar el PDF</div>

          return (
            <e.Contenido $escomputadora={esComputadora}>
              <div className='PDFContent'>
                {url ? (
                  <iframe className='pdf' src={url} title='PDF Viewer' />
                ) : (
                  <div>No se pudo generar el PDF</div>
                )}
              </div>
              <div className='PDFContentDownlaod'>
                <a
                  href={url}
                  download={`Pedidos Producción (${fg.fechayhoraactualStr()})`}
                >
                  Descargar PDF
                </a>
              </div>
            </e.Contenido>
          )
        }}
      </BlobProvider>
    </>
  )
}

export default DetallePDF
