import React, { useEffect, useState, useContext } from 'react'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  BlobProvider,
  Image
} from '@react-pdf/renderer'
import * as e from './ListasPDFCSS'
import { Contexto } from '../../../../../Componentes/Principal/index'
import * as fg from '../../../../../Api/FuncionesGlobales'
import LogoSanMiguel from './../../../../../Imagenes/LogoSanMiguel.png'

function ListasPDFDoc (props) {
  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [diasSemana, setDiasSemana] = useState(props.param.diasSemana)
  const [fechas, setFechas] = useState(props.param.fechas)
  const [datosProduccion, setDatosProduccion] = useState(
    JSON.parse(JSON.stringify(props.param.datosProduccion))
  )
  const [primeracarga, setprimeracarga] = useState(true)
  const [totalTerminado, setTotalTerminado] = useState(0)
  const [mostrardetallado, setmostrardetallado] = useState(false)

  useEffect(() => {
    console.clear()
    console.log(props)
    console.log(props.param)
    let tot_term = 0
    datosProduccion.map(e => {
      // console.log('=============')
      // console.log(e)

      let pago_maestro = 0
      let total_terminado = 0
      let total_registro = { produccion: {}, productosJson: [] }
      let pago_ayu = {}
      let prod_json = {}
      e.produccion.map(f => {
        if (f !== null) {
          pago_maestro += f.produccion.pago_maestro
          total_terminado += f.produccion.total_terminado
          let pago_ayu_tot = 0
          f.produccion.ayudantes.map(ayu => {
            if (ayu.detalle.codigo in pago_ayu) {
              pago_ayu[ayu.detalle.codigo].valor.detalle.pago +=
                ayu.detalle.pago
            } else {
              pago_ayu[ayu.detalle.codigo] = {
                valor: JSON.parse(JSON.stringify(ayu))
              }
            }
          })

          //console.log(f.productosJson)
          f.productosJson.map(pj => {
            //console.log(pj)
            if (pj.detalle.producto in prod_json) {
              prod_json[pj.detalle.producto].valor.detalle.subtt_entr +=
                pj.detalle.subtt_entr

              prod_json[pj.detalle.producto].valor.detalle.ingreso +=
                pj.detalle.ingreso
            } else {
              prod_json[pj.detalle.producto] = {
                valor: JSON.parse(JSON.stringify(pj))
              }
            }
          })
        }
      })
      tot_term += total_terminado
      //console.log(prod_json)
      total_registro.produccion.pago_maestro = pago_maestro
      total_registro.produccion.total_terminado = total_terminado
      total_registro.produccion.maestro = e.maestro
      total_registro.produccion.ayudantes = []

      Object.keys(pago_ayu).map(ef => {
        total_registro.produccion.ayudantes.push(pago_ayu[ef].valor)
      })
      Object.keys(prod_json).map(ef => {
        total_registro.productosJson.push(prod_json[ef].valor)
      })

      // console.log(total_registro)
      e.produccion.push(total_registro)
      setprimeracarga(false)
    })
    setTotalTerminado(tot_term)
    //console.log(props.param.diasSemana)
  }, [])
  const pdfDocument = () => (
    <Document
      title='San Miguel Reporte Generar Pedido de Producción'
      author='San Miguel'
      subject='Reporte'
      keywords='Reporte San Miguel'
      pdfVersion='1.5'
      wrap='true'
    >
      <Page size='A4' orientation='landscape' style={e.docpdf.page}>
        <View style={e.docpdf.headerDocument}>
          <View style={e.docpdf.informationDocument}>
            <Text>
              {'Fecha: ' +
                fg.obtenerFechaActual() +
                '    Hora: ' +
                fg.obtenerHoraActual()}
            </Text>
            <Text>{usuariosesion}</Text>
          </View>
          <View style={e.docpdf.titleDocument}>
            <Text>Lista de Producciones</Text>
          </View>
          <Text style={e.docpdf.logo}>
            <Image
              style={{
                width: '10cm',
                marginVertical: 15,
                marginHorizontal: 100
              }}
              src={LogoSanMiguel}
            />
          </Text>
        </View>
        <View style={e.docpdf.bodyDocument}>
          <View style={e.docpdf.bodyContent}>
            <View style={e.docpdf.dayContent}>
              <Text
                style={{
                  ...e.docpdf.cellContent,
                  width: '5%',
                  fontSize: '5px'
                  //backgroundColor: 'red'
                }}
              >
                Fecha
              </Text>
              {diasSemana.map(ds => {
                return (
                  <Text key={ds} style={e.docpdf.cellContent}>
                    {ds}
                  </Text>
                )
              })}
            </View>
            <View style={e.docpdf.dateContent}>
              <Text
                style={{
                  ...e.docpdf.cellContent,
                  width: '5%',
                  fontSize: '5px'
                }}
              >
                Maestros
              </Text>
              {fechas.map(f => {
                return (
                  <Text
                    key={fg.formatear_fecha_ddmmyyyy(f)}
                    style={e.docpdf.cellContent}
                  >
                    {fg.formatear_fecha_ddmmyyyy(f)}
                  </Text>
                )
              })}
              <Text style={e.docpdf.cellContent}>Total</Text>
            </View>
            {datosProduccion.map(dp => {
              return (
                <View key={dp.maestro.usuario} style={e.docpdf.maestrosContent}>
                  <Text
                    style={{
                      ...e.docpdf.cellContent,
                      width: '5%',
                      //transform: 'rotate(-90deg)',
                      fontSize: '5px'
                      //backgroundColor: 'red',
                    }}
                  >
                    {fg.capitalizar_palabra(
                      dp.maestro.nombre + ' ' + dp.maestro.apellido_paterno ||
                        ''
                    )}
                  </Text>
                  {dp.produccion.map(prod => {
                    return (
                      <View
                        key={dp.maestro.usuario + 'sub'}
                        style={e.docpdf.cellContentProduction}
                      >
                        {prod !== null ? (
                          <View
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {mostrardetallado && prod.productosJson.map(pj => {
                              return (
                                <View
                                  style={e.docpdf.cellContentProduct}
                                  key={dp.maestro.usuario + 'sub'}
                                >
                                  <Text style={e.docpdf.cellProductQuantity}>
                                    {pj.detalle.ingreso}
                                  </Text>
                                  <Text style={e.docpdf.cellProduct}>
                                    {fg.capitalizar_palabra(
                                      pj.producto.descripcion || ''
                                    )}
                                  </Text>
                                  <Text style={e.docpdf.cellProductTotal}>
                                    {pj.detalle.subtt_entr}
                                  </Text>
                                </View>
                              )
                            })}
                            <View style={e.docpdf.cellContentProduct}>
                              <Text
                                style={{
                                  fontSize: '8px',
                                  width: '100%',
                                  textAlign: 'center',
                                  backgroundColor: '#bbbbbb'
                                }}
                              >
                                {fg.formatear_moneda(
                                  prod.produccion.total_terminado || 0
                                )}
                              </Text>
                            </View>
                            <View style={e.docpdf.cellContentMaster}>
                              <Text style={e.docpdf.cellProductQuantity}>
                                {'M'}
                              </Text>
                              <Text style={e.docpdf.cellProduct}>
                                {fg.capitalizar_palabra(
                                  prod.produccion.maestro.nombre +
                                    ' ' +
                                    prod.produccion.maestro.apellido_paterno ||
                                    ''
                                )}
                              </Text>
                              <Text style={e.docpdf.cellProductTotal}>
                                {fg.formatear_moneda(
                                  prod.produccion.pago_maestro || 0
                                )}
                              </Text>
                            </View>

                            {prod.produccion.ayudantes.map(pj => {
                              return (
                                <View
                                  style={e.docpdf.cellContentMaster}
                                  key={dp.maestro.usuario + 'sub2'}
                                >
                                  <Text style={e.docpdf.cellProductQuantity}>
                                    {'A'}
                                  </Text>
                                  <Text style={e.docpdf.cellProduct}>
                                    {fg.capitalizar_palabra(
                                      pj.ayudante.nombre +
                                        ' ' +
                                        pj.ayudante.apellido_paterno || ''
                                    )}
                                  </Text>
                                  <Text style={e.docpdf.cellProductTotal}>
                                    {fg.formatear_moneda(pj.detalle.pago || 0)}
                                  </Text>
                                </View>
                              )
                            })}
                          </View>
                        ) : (
                          <View style={e.docpdf.noRegisterContainer}>
                            <Text style={e.docpdf.noRegister}>No Registra</Text>
                          </View>
                        )}
                      </View>
                    )
                  })}
                </View>
              )
            })}
          </View>
          {
            <Text style={e.docpdf.cellTotal}>
              {'Total: ' + fg.formatear_moneda(totalTerminado)}
            </Text>
          }
        </View>
      </Page>
    </Document>
  )
  const mostrardetalladoFunction = e => {
    console.log(e.target.checked)
    setmostrardetallado(e.target.checked)
  }
  return (
    <>
      {!primeracarga && (
        <BlobProvider document={pdfDocument()}>
          {({ blob, url, loading, error }) => {
            if (loading) return <div>Cargando...</div>
            if (error) return <div>Error al cargar el PDF</div>

            return (
              <e.Contenido $escomputadora={esComputadora}>
                <div className='mostrarDetallado'>
                  <label htmlFor='miCheckbox'>¿Mostrar Detallado?</label>
                  <input
                    type='checkbox'
                    id='miCheckbox'
                    name='miCheckbox'
                    checked={mostrardetallado}
                    onChange={mostrardetalladoFunction}
                  />
                </div>
                <div className='PDFContent'>
                  {/* Mostrar el PDF en el iframe */}
                  <iframe className='pdf' src={url} title='PDF Viewer' />
                  {/* Enlace de descarga con nombre personalizado */}
                </div>
                <div className='PDFContentDownload'>
                  <a
                    href={url}
                    download={
                      'Pedidos Producción (' + fg.fechayhoraactualStr() + ')'
                    }
                  >
                    Descargar PDF
                  </a>
                </div>
              </e.Contenido>
            )
          }}
        </BlobProvider>
      )}
    </>
  )
}
export default ListasPDFDoc
