import styled from "styled-components";

export const Contenido = styled.div`
  grid-area: 1/1/21/21;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);

  .Header {
    grid-area: 1/1/2/21;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(173, 103, 38);
    font-size: ${(props) => (props.$escomputadora ? "2vw" : "4vw")};
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;

    @media (max-width: 768px) {
      font-size: 5vw;
    }
  }

  .FilterSection {
    grid-area: ${(props) => (props.$escomputadora ? "2/1/3/21" : "2/1/4/21")};
    display: grid;
    grid-template-columns: ${(props) => (props.$escomputadora ? "24% 24% 24% 24%" : "50% 50%")};
    grid-template-rows: ${(props) => (props.$escomputadora ? "100%" : "50% 50%")};
    box-shadow: rgb(129, 129, 129) 0px 0px 0.3vw inset;
    padding: 1.75vw;
    box-sizing: border-box;
    gap: 0.2vw;

    // @media (max-width: 768px) {
    //   padding: 1vw;
    //   gap: 2vw;
    // }
  }

  .FilterSection > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 90%;

    // @media (max-width: 768px) {
    //   flex-direction: column;
    // }
  }

  .TableContainer {
    grid-area: ${(props) => (props.$escomputadora ? "3/1/19/21" : "4/1/19/21")};
    border-collapse: collapse;
    background-color: white;
    border-radius: 0vw 0vw 1vw 1vw;
    padding: 3px;
    overflow-x: auto;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      padding: 1vw;
    }
  }

  .ActionButtons {
    grid-area: 19/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgb(253, 249, 226);
    border-radius: 1vw 1vw 1vw 1vw;
    box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1vw;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TableHead = styled.thead`
  background-color: rgb(173, 103, 38);
  color: white;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: 0.3s;

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
  }

  &.boleta {
    background-color: #ffc107;
    color: black;
  }

  &.factura {
    background-color: #28a745;
    color: white;
  }

  &.buscar {
    background-color: #d87d17;
    color: white;
  }

  .xml-icon, .pdf-icon {
    display: inline-block;
    margin: 0 5px;
    font-size: 16px;
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  .xml-icon {
    color: green; /* لون أيقونة XML */
  }

  .pdf-icon {
    color: red; /* لون أيقونة PDF */
  }
`;

export default {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
};