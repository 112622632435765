import styled from 'styled-components'

export const Contenido = styled.div`
  background-color: rgb(238, 238, 238);
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  padding: ${props => (props.$escomputadora ? '1vw' : '1vh')};
  width: 95%;
  height: 95%;
  box-sizing: border-box;

  .pedido {
    border: 0.25vw solid rgb(207, 205, 205);
    display: grid;
    grid-template-rows: repeat(20, 1fr);
    grid-template-columns: repeat(20, 1fr);
    border-radius: 1vw 1vw 1vw 1vw;
  }
  .cabecera {
    grid-area: 1/1/2/21;
    background-color: rgb(173, 103, 38);
    font-size: ${props => (props.$escomputadora ? '2' : '4')}vw;
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 70% 30%;
  }
  .cabecera > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap : 3vw
  }
  .areaayudantes {
    grid-area: 2/1/4/21;
    background-color: white;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 80% 20%;
    //padding: 0.5vw;
    height: 100%;
    width: 100%;
  }
  .agregarAyudante{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25vw;
  }
  .ayudantes {
    color: black;
    background-color: white;
    display: grid;
    overflow: auto;
    grid-template-rows: ${props => (props.$escomputadora ? '80%' : '35% 35%')};
    grid-template-columns: ${props => (props.$escomputadora ? '32% 32% 32%' : '98%')};
    padding: 0.5vw;
    height: ${props => (props.$escomputadora ? '70%' : '100%')};
    width: 98%;
    gap: 1vw;
  }
  .ayudante{
    border: 0.2vw solid rgb(143, 143, 143);
    border-radius: ${props => (props.$escomputadora ? '1vw 1vw 1vw 1vw' : '2vw 2vw 2vw 2vw')};
    color: black;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 12% 50% 38%;
    padding: 0.2vw;
    height: 100%;
    width: 100%;
  }
  .ayudante > div{
    display: flex;
    //justify-content: center;
    align-items: center;
  }
  
  .cuerpo {
    grid-area: 4/1/19/21;
    color: black;
    overflow: auto;
    background-color: white;
  }
  .cuerpo > div{
    width: ${props =>
      props.$escomputadora
        ? 100
        : 150}%;
  }
  .descripcion {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns:  ${props => (props.$rol ==="SUPER ADMINISTRADOR" ? '20% 10% 10% 15% 15% 15% 15%' : '35% 10% 10% 15% 15% 15%')};
  }
  .descripcion > div {
    color: black;
    font-family: verdana;
    border-bottom: 2px solid rgb(138, 138, 138);
    text-decoration-thickness: 0.3vw;
    font-weight: 900;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1.2' : '3')}vw;
    background-color: rgb(253, 249, 226);
  }
  .detallePedido {
    display: grid;
    height: auto;
    width: 100%;
    color: black;
    padding: 0.5vw;
    box-sizing: border-box;
    overflow: auto;
    
  }
  .detallePedido > div.productos:nth-child(even) {
    background-color: rgb(240, 240, 240);
  }
  .productos {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns:${props => (props.$rol ==="SUPER ADMINISTRADOR" ? '20% 10% 10% 15% 15% 15% 15%' : '35% 10% 10% 15% 15% 15%')};
    color: black;
    padding: 0.5vw;
    box-sizing: border-box;
    overflow: auto;
  }
  .productos > div {
    color: black;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
  }
  input {
    height: 100%;
    width: 80%;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.3')}vw;
  }
  .opciones {
    grid-area: 19/1/21/21;
    color: black;
    background-color: #ffffff;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
    border-radius: 1vw 1vw 1vw 1vw;
    box-sizing: border-box;
    box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  }
  .opciones > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: verdana;
    text-decoration-thickness: 0.3vw;
    font-weight: 900;
    font-size: ${props => (props.$escomputadora ? '1.5' : '3')}vw;
  }

  .cantProductoListClass {
    grid-area: 1/1/2/21;
  }
  .ProductoListClass {
    grid-area: 2/1/3/21;
  }
  .cantProdModalClass {
    grid-area: 3/1/4/21;
  }

  .cantProductoListClass,
  .ProductoListClass,
  .cantProdModalClass,
  .opciones > div {
    padding: 0.3vw;
  }
`
