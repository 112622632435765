import * as e from './GenerarPedidoProduccionCSS'
import { Contexto } from '../../../../../Componentes/Principal/index'
import Input from '../../../../../Elementos/Input'
import Boton from '../../../../../Elementos/Boton'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import AgregarProductoProd from './AgregarProductoProd'
import PedidoPdf from './PedidoPdf'
import * as api from '../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import { useContext, useEffect, useState } from 'react'

function GenerarPedidoProduccion (props) {
  const { esComputadora, rol } = useContext(Contexto)
  const [listadoMaestros, setListadoMaestros] = useState([])
  const [produccionMaestros, setProduccionMaestros] = useState([])
  const [estaProcesando, setestaProcesando] = useState(false)

  //MODAL
  const [vermodal, setvermodal] = useState(false)
  const [dispotivo, setdispositivo] = useState([]) //ayuda a identificar si es wc, hc o wm, hm
  const [opclick, setopclick] = useState('')
  const [primeracarga, setprimeracarga] = useState(true)
  const [param, setparam] = useState({
    pedido: null
  })
  const [opmodal, setopmodal] = useState({
    app: { wc: 40, hc: 60, wm: 70, hm: 50, t: 'Agregar Producto Producción' },
    pdf: { wc: 70, hc: 80, wm: 90, hm: 70, t: 'PDF Generar Pedido Producción' }
  })
  const [verpdf, setverpdf] = useState(false)
  //FIN MODAL

  useEffect(() => {
    const llamado = async () => {
      const maestros = await api.listarMaestros(null, 'S')
      if (maestros) {
        if (maestros.SMListaErrores.length === 0) {
          setListadoMaestros(maestros.maestro)
          let produccionesTemp = {}
          const promesas = maestros.maestro.map(async e => {
            const llamado = await api.listaIngresoProductoMaestro(
              null,
              e.usuario,
              'S'
            )
            if (llamado) {
              if (llamado.SMListaErrores.length === 0) {
                produccionesTemp = {
                  ...produccionesTemp,
                  [llamado.produccion.maestro.usuario]: llamado
                }
              } else {
                alert('Hubo un error. Se recargara la página.')
                window.location.reload()
              }
            } else {
              alert(
                'Hubo un problema con la conección. Se recargara la página.'
              )
              window.location.reload()
            }
          })
          await Promise.all(promesas)
          console.log(produccionesTemp)
          setprimeracarga(false)
          setProduccionMaestros(produccionesTemp)
        } else {
          alert('Hubo un error. Se recargara la página.')
          window.location.reload()
        }
      } else {
        alert('Hubo un problema con la conección. Se recargara la página.')
        window.location.reload()
      }
    }
    llamado()
  }, [])

  const salgodeinput = async e => {
    console.log(produccionMaestros)
    let codProduccion = parseInt(
      e.currentTarget.parentNode.parentNode.parentNode.parentNode.id
    )
    let codProducto = parseInt(e.currentTarget.parentNode.parentNode.id)
    let valor =
      e.currentTarget.value === '' ? 0 : parseFloat(e.currentTarget.value)
    const editarInput = await api.editarIngresoProductoMaestro(
      parseInt(codProduccion),
      parseFloat(valor),
      1,
      parseInt(codProducto)
    )
    if (editarInput) {
      if (editarInput.SMListaErrores.length === 0) {
        console.log(editarInput)
        let subtotal = document.getElementById(
          codProducto + '-' + codProduccion
        )
        console.log(codProducto + '-' + codProduccion)
        if (subtotal) {
          subtotal.innerHTML = fg.formatear_moneda(
            editarInput.productoJson.subtt_soli
          )
        }

        let total = document.getElementById(codProduccion + 'total')
        total.innerHTML =
          'TOTAL: ' +
          fg.formatear_moneda(editarInput.produccion.total_solicitado)
        //e.currentTarget.parentNode.parentNode.pago
      } else {
        alert('Hubo un error. Se recargara la página.')
        window.location.reload()
      }
    } else {
      alert('Hubo un problema con la conección. Se recargara la página.')
      window.location.reload()
    }
  }

  const clickAgregarProductoEvento = event => {
    console.log(event)
    setvermodal(true)
    setopclick(!primeracarga ? 'app' : '')
    // setOrigenEvento('r1')
    // const codCategoria = event.currentTarget.parentNode.getAttribute('catprod')
    // setModal(true)
    // setModoModal('agregar')
    // setEditarProducto(false)
    // setEliminarProducto(false)
    // setAgregarProducto(true)
    // setParamAgregarProd({
    //   ['codCategoria']: codCategoria,
    //   productos: paramEditProd,
    //   codUsuario: distSelec
    // })
    // setParamEliminarProd({})
    // setParamEditProd({})
  }

  useEffect(() => {
    console.log('entre en primera carga', !primeracarga, opclick !== '')
    if (!primeracarga && opclick !== '') {
      console.log('entre despues de la primera carga')
      setdispositivo(esComputadora ? ['wc', 'hc'] : ['wm', 'hm'])
      setvermodal(true)
    }
  }, [opclick, esComputadora])

  const cerrarModal = async () => {
    if (opclick !== 'pdf') {
      console.log(param)
      const usuario = param.pedido.maestro.usuario
      const llamado = await api.listaIngresoProductoMaestro(null, usuario, 'S')
      if (llamado) {
        if (llamado.SMListaErrores.length === 0) {
          setProduccionMaestros(
            !primeracarga &&
              produccionMaestros && {
                ...produccionMaestros,
                [usuario]: llamado
              }
          )
        } else {
          alert('Hubo un error. Se recargara la página.')
          window.location.reload()
        }
      } else {
        alert('Hubo un problema con la conección. Se recargara la página.')
        window.location.reload()
      }
    }
    setvermodal(false)
    setopclick('')
    console.log('enter despues de pdf false')
  }
  const generarPedidoEvento = async event => {
    setestaProcesando(true)
    console.log(produccionMaestros)
    let error = false
    const guardar = Object.keys(produccionMaestros).map(async e => {
      console.log(produccionMaestros[e])
      console.log(produccionMaestros[e].produccion.ingreso)
      const llamado = await api.guardarIngresoProductoMaestro(
        produccionMaestros[e].produccion.ingreso,
        1
      )
      console.log(llamado)
      if (llamado) {
        if (llamado.SMListaErrores.length === 0) {
          //window.location.reload()
        } else {
          error = true
        }
      } else {
        error = true
      }
    })
    await Promise.all(guardar)

    setestaProcesando(false)
    if (error === true) {
      alert('Hubo un error. Se recargara la página.')
      //window.location.reload()
    }
    // setvermodal(true)
    // setopclick(!primeracarga ? 'app' : '')
    // setOrigenEvento('r1')
    // const codCategoria = event.currentTarget.parentNode.getAttribute('catprod')
    // setModal(true)
    // setModoModal('agregar')
    // setEditarProducto(false)
    // setEliminarProducto(false)
    // setAgregarProducto(true)
    // setParamAgregarProd({
    //   ['codCategoria']: codCategoria,
    //   productos: paramEditProd,
    //   codUsuario: distSelec
    // })
    // setParamEliminarProd({})
    // setParamEditProd({})
  }
  return (
    <>
      {vermodal && dispotivo.length > 0 && (
        <ModalGenerico
          capa={1}
          width={opmodal[opclick][dispotivo[0]]}
          height={opmodal[opclick][dispotivo[1]]}
          titulo={opmodal[opclick].t}
          cerrarmodal={cerrarModal}
        >
          {['mpp', 'app'].includes(opclick) && (
            <AgregarProductoProd cerrarmodal={cerrarModal} param={param} />
          )}
          {['pdf'].includes(opclick) && (
            <PedidoPdf cerrarmodal={cerrarModal} param={param} />
          )}
        </ModalGenerico>
      )}
      <e.Contenido
        $escomputadora={esComputadora}
        $cantMaestros={listadoMaestros.length}
      >
        <div className='cabecera'>Generar Pedido de Producción</div>
        <div className='filtro'>
          <div style={{ padding: '0.1vw' }}>
            <Input
              style={{}}
              textoStyle={{}}
              id='fnomape'
              moderno={'Producto'}
              tipo='text'
              onChange={e => {
                //setfnomape(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              //limpiar={fnomape === '' ? true: false}
            />
          </div>
          <div style={{ padding: '0.1vw' }}>
            <Input
              id='fnomape'
              moderno={'Cantidad > a'}
              tipo='text'
              onChange={e => {
                //setfnomape(e.currentTarget.value.toUpperCase().trim())
                //filtrosNomApe(e.currentTarget.value)
              }}
              valorInicial={''}
              esComputadora={esComputadora}
              //limpiar={fnomape === '' ? true: false}
            />
          </div>
          <div
            style={{
              padding: '0.1vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1vw' : '2.75vw',
                padding: '0'
              }}
              texto='Limpiar Filtros'
              tipo='submit'
              estaProcesando={false}
              onClick={event => {
                //event.preventDefault()
                //setfnomape('')
                //setfndoc('')
                //setfusuario('')
              }}
            />
          </div>
        </div>
        <div className='cuerpo'>
          <div className='pedido'>
            {listadoMaestros.length > 0 &&
              listadoMaestros.map(e => (
                <div className='pedidoMaestro' key={e.usuario}>
                  <div
                    className='cabecera cabecerapedido'
                    style={{ fontSize: esComputadora ? '1.3vw' : '3.5vw' }}
                  >
                    {fg.capitalizar_palabra(
                      e.nombre +
                        ' ' +
                        e.apellido_paterno +
                        ' ' +
                        e.apellido_materno
                    )}
                  </div>

                  <div
                    className='cuerpoPedido'
                    id={
                      produccionMaestros[e.usuario] &&
                      produccionMaestros[e.usuario].produccion.ingreso
                    }
                  >
                    <div className='descripcion'>
                      <div>Producto</div>
                      <div title='Paquetes por Produccion'>Paq. X Prod.</div>
                      <div>Cantidad</div>
                      <div>Pago</div>
                    </div>
                    <div className='detallePedido'>
                      {produccionMaestros[e.usuario] &&
                        produccionMaestros[e.usuario].productosJson.map(e => (
                          <div
                            className='productos'
                            id={e.producto.producto}
                            key={e.producto.producto}
                          >
                            <div>
                              {fg.capitalizar_palabra(e.producto.descripcion)}
                            </div>
                            <div>{e.producto.paquetes}</div>
                            <Input
                              id='cantidadEntradaProd'
                              moderno=''
                              tipo='number'
                              maxDigitos='4'
                              onChange={e => {
                                if (
                                  e.currentTarget.value === '' &&
                                  !estaProcesando
                                ) {
                                  console.log('entre')
                                  salgodeinput(e)
                                }
                              }}
                              onBlur={!estaProcesando && salgodeinput}
                              valorInicial={
                                e.detalle.solicitado !== 0
                                  ? e.detalle.solicitado
                                  : ''
                              }
                              esComputadora={esComputadora}
                              deshabilitado={estaProcesando}
                            />
                            <div
                              id={
                                e.producto.producto + '-' + e.detalle.produccion
                              }
                            >
                              {fg.formatear_moneda(e.detalle.subtt_soli)}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <textarea
                    className='comentario'
                    id={
                      produccionMaestros[e.usuario] &&
                      produccionMaestros[e.usuario].produccion.ingreso + 'com'
                    }
                    name='comentario'
                    rows='4'
                    cols='50'
                    maxlength='250'
                    disabled={estaProcesando}
                    placeholder='Ingresa un Comentario'
                  ></textarea>
                  <div className='total'>
                    <div className='botonAgregar'>
                      <Boton
                        style={{
                          backgroundColor: 'rgb(220, 173, 47)',
                          borderRadius: esComputadora ? '0.5vw' : '1vw',
                          padding: esComputadora ? '0.8vw' : '1vw'
                        }}
                        Promise
                        textoStyle={{
                          color: 'black',
                          width: '100%',
                          fontSize: esComputadora ? '1.3vw' : '2.75vw',
                          padding: '0'
                        }}
                        texto='Agregar Producto'
                        tipo='submit'
                        estaProcesando={estaProcesando}
                        onClick={event => {
                          setparam(
                            !primeracarga &&
                              produccionMaestros && {
                                ...param,
                                pedido: produccionMaestros[e.usuario].produccion
                              }
                          )
                          clickAgregarProductoEvento(event)
                        }}
                      />
                    </div>
                    <div
                      id={
                        produccionMaestros[e.usuario] &&
                        produccionMaestros[e.usuario].produccion.ingreso +
                          'total'
                      }
                    >
                      TOTAL:{' '}
                      {produccionMaestros[e.usuario] &&
                        fg.formatear_moneda(
                          produccionMaestros[e.usuario].produccion
                            .total_solicitado
                        )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='opciones'>
          <div className='botonOpciones'>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1.3vw' : '2.75vw',
                padding: '0'
              }}
              texto='Ver PDF'
              tipo='submit'
              estaProcesando={estaProcesando}
              onClick={event => {
                setopclick(!primeracarga ? 'pdf' : '')
                //primeracarga || setopclick('pdf')
                console.log('entre a ver modal pdf')
                //event.preventDefault()
                //setfnomape('')
                //setfndoc('')
                //setfusuario('')
              }}
            />
          </div>
          <div className='botonOpciones'>
            <Boton
              style={{
                backgroundColor: 'rgb(45, 139, 11)',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              Promise
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1.3vw' : '2.75vw',
                padding: '0'
              }}
              texto='Generar Pedido'
              tipo='submit'
              estaProcesando={estaProcesando}
              onClick={event => {
                generarPedidoEvento(event)
                //event.preventDefault()
                //setfnomape('')
                //setfndoc('')
                //setfusuario('')
              }}
            />
          </div>
        </div>
      </e.Contenido>
    </>
  )
}
export default GenerarPedidoProduccion
