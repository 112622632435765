import React, { useState, useEffect } from 'react'
import notificacion from './../../../Imagenes/Iconos/notificacion.png'
import usuario from './../../../Imagenes/Iconos/usuario.png'
import flechaOpciones from './../../../Imagenes/Iconos/flechaOpciones.png'
import LogoSanMiguel from './../../../Imagenes/LogoSanMiguel.png'
import { cerrarSesion, validarSesion, obtenerCookie } from '../../../Api/Api'
import { useNavigate } from 'react-router-dom'

import './BarraSuperior.css'
import '../EstilosGenerales/EstilosGenerales.css'
import * as e from './BarraSuperiorCSS'

function BarraSuperior () {
  const [estaProcesando, setestaProcesando] = useState(false)
  const [esComputadora, setesComputadora] = useState(
    window.innerWidth > window.innerHeight
  )
  const [isHovered, setIsHovered] = useState(false)
  const [idHovered, setIdHovered] = useState('')
  const [diferenciaDimensiones, setDifDim] = useState(
    window.innerWidth - window.innerHeight
  )
  const [mostrarOpcionesUsuario, setMostrarOpcionesUsuario] = useState(null)
  const navigate = useNavigate()
  const [hayError, setHayError] = useState(false)
  const [errores, seterrores] = useState(null)

  const [nombresUsuario, setNombresUsuarios] = useState(
    obtenerCookie('nombreUsuario') + ' ' + obtenerCookie('apellidoPaterno')
  )
  const [rol, setrol] = useState(obtenerCookie('codUsuario'))

  useEffect(() => {
    const validar = async () => {
      const respuesta = await validarSesion()
      if (respuesta.SMListaErrores.length !== 0) {
        alert('Sesión cerrada, vuelve a ingresar')
        navigate('/')
      }
    }
    validar()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setesComputadora(window.innerWidth > window.innerHeight)
      if (
        window.innerWidth > window.innerHeight &&
        window.innerWidth > window.innerHeight > 0
      ) {
        setDifDim(window.innerWidth - window.innerHeight)
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const manejadorOpcionClick = async event => {
    if (mostrarOpcionesUsuario == true) {
      setMostrarOpcionesUsuario(false)
    } else {
      setMostrarOpcionesUsuario(true)
    }
  }
  const manejadorCerrarSesionClick = async () => {
    setestaProcesando(true)
    setHayError(false)
    const respuesta = await cerrarSesion()
    setestaProcesando(false)
    if (respuesta.SMListaErrores.length !== 0) {
      setHayError(true)
      seterrores(respuesta)
    } else {
      setHayError(false)
      navigate('/')
    }
  }
  return (
    <>
      <e.Contenido
        $escomputadora={esComputadora}
        $mostraropcionesusuario={mostrarOpcionesUsuario}
      >
        <div id='contenidoLogo'>
          <img src={LogoSanMiguel} alt='Logo' />
        </div>
        <div id='contenidoNotificacion'>
          <img src={notificacion} alt='Logo' />
        </div>
        <div id='cotenidoInfoUsuario'>
          <p>
            {nombresUsuario.toUpperCase()} - {rol}
          </p>
          <p>{obtenerCookie('descRol')}</p>
        </div>
        <div id='contenidoFotoUsuario'>
          <div id='fondoAuxFoto'>
            <img src={usuario} alt='Logo' />
          </div>
        </div>
        <div
          id='contenidoOpcionesUsuario'
          onMouseEnter={event => {
            setIsHovered(true)
            setIdHovered(event.target.id)
          }}
          onMouseLeave={() => {
            setIsHovered(false)
            setIdHovered('')
          }}
          onClick={manejadorOpcionClick}
        >
          <img src={flechaOpciones} alt='Logo' />
        </div>
        <div id='menuOpcionesUsuario'>
          <div
            onMouseEnter={event => {
              setIsHovered(true)
              setIdHovered(event.target.id)
            }}
            onMouseLeave={() => {
              setIsHovered(false)
              setIdHovered('')
            }}
            onClick={estaProcesando ? null : manejadorCerrarSesionClick}
          >
            Cerrar Sesión
            {estaProcesando && (
              <div
                className='cargando'
                style={{
                  display: 'inline-block',
                  width: '15px',
                  height: '15px',
                  marginLeft: '5px',
                  position: 'relative',
                  top: '3px'
                }}
              ></div>
            )}
          </div>
        </div>
      </e.Contenido>
    </>
  )
}

export default BarraSuperior
