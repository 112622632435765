import React, { useState, useEffect } from 'react'

import * as e from './OpcionesCSS'
import * as fg from './../Api/FuncionesGlobales'

function Opciones (param) {
  const [isContent, setIsContent] = useState(false)
  const [cambiecontenido, setcambiecontenido] = useState(false)

  useEffect(() => {
    const ejecucion = async () => {
      let inicial = param.valorInicial ? true : false
      if (inicial) {
        setIsContent(true)
      } else setIsContent(false)
    }
    ejecucion()
  }, [param.valorInicial])

  useEffect(() => {
    if (param.limpiar) {
      setIsContent(false)
    }
  }, [param.limpiar])
  return (
    <>
      <e.Contenido
        id='lista'
        $escomputadora={param.esComputadora}
        $id={param.id ? param.id : 'opcionesGenerico'}
        $iscontent={isContent}
      >
        <select
          disabled={param.deshabilitado}
          id={param.id ? param.id : 'opcionesGenerico'}
          onChange={async event => {
            if (event.currentTarget.value) setIsContent(true)
            else setIsContent(false)
            setcambiecontenido(true)
            await param.onClick(event.target.value)
            if (param.onChange) {
              await param.onChange(event)
            }
          }}
          onBlur={evento => {
            if (evento.currentTarget.value === '') setIsContent(false)
          }}
          {...((param.valorInicial ? param.valorInicial : '').toString() !==
            '' && !cambiecontenido
            ? { value: param.valorInicial.toString() || '' }
            : {})}
        >
          <option value=''> </option>
          {param.opciones.map(i => {
            return (
              <option key={i[param.clave]} value={i[param.clave]}>
                {param.valor.map(val => fg.capitalizar_palabra(i[val]) + ' ')}
              </option>
            )
          })}
        </select>

        <label
          id="textosinvalor"
          // style={{
          //   ...e.inputLabelCSS(param.esComputadora, param.textoStyle),
          //   ...(isContent ? e.inputLabelActivoCSS(param.esComputadora) : {})
          // }}
        >
          {param.moderno}
        </label>
      </e.Contenido>
    </>
  )
}

export default Opciones
