import styled from 'styled-components';

export const Contenido = styled.div`
  grid-area: 1/1/21/21;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);

  .cabecera {
    grid-area: 1/1/2/21;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(173, 103, 38);
    font-size: ${props => (props.$escomputadora ? '2' : '4')}vw;
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;
  }

  .filtro {
    grid-area: 2/1/3/21;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: 100%;
    background-color: rgb(250, 250, 250);
    box-shadow: rgb(129, 129, 129) 0px 0px 0.3vw inset;
    padding: 0.5vw;
    box-sizing: border-box;
    height: ${props => (props.$escomputadora ? '5' : '10')}vw;
  }
  .filtro > div{
  display: flex;
            align-items: center;
            justify-content: center;
  padding: 2vw;
  }
  .tabla-produccion {
    
    width: ${props =>
      props.$escomputadora
        ? props.$dias * 100/7
        : props.$dias * 175/7}%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0px 4px 8px rgb(170, 170, 170);
    border-radius: 0vw 0vw 1vw 1vw;
    overflow: hidden;
  }

  .tabla-produccion th {
    width: 75px;
    background-color: rgb(173, 103, 38);
    color: white;
    padding: 0.2vw;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
  }

  .tabla-produccion td {
    padding: 0.5vw;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
    border: 2px solid rgb(211, 211, 211);
    font-weight: bold;
  }

  .tabla-produccion .disponible {
    background-color:rgb(250, 250, 250);
    color: white;
    font-weight: bold;
  }

  .tabla-produccion .faltante {
    background-color:rgb(250, 250, 250);
    color: white;
    font-weight: bold;
  }

  .contenedor {
    grid-area: 3/1/19/21;
    width: 100%;
    overflow-x: auto;
  }
  .opciones {
    grid-area: 19/1/21/21;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: rgb(253, 249, 226);
    border-radius: 1vw 1vw 1vw 1vw;
    box-sizing: border-box;
    box-shadow: rgb(170, 170, 170) 0.1vw 0.1vw 1vw;
  }
  .botonOpciones {
    width: ${props => (props.$escomputadora ? '15%' : '30%')};
    margin-right: 5%;
  }
  .total {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-family: verdana;
    text-decoration-thickness: 0.3vw;
    font-weight: 900;
    font-size: ${props => (props.$escomputadora ? '1.5' : '3')}vw;
  }
`;
