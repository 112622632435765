import { useContext, useEffect, useState } from 'react'
import { Contexto } from '../../../../../Componentes/Principal/index'

import Opciones from '../../../../../Elementos/Opciones'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import * as api from './../../../../../Api/Api'
import * as e from './AgregarProductoProdCSS'

function AgregarProductoProd (props) {
  const { esComputadora, rol } = useContext(Contexto)
  const [produccionSelect, setProduccionSelect] = useState([])
  const [categorias, setCategorias] = useState([])
  const [categoriaSelect, setCategoriaSelect] = useState([])
  const [productos, setProductos] = useState([])
  const [productosOpciones, setProductosOpciones] = useState([])
  const [productoSelect, setProductoSelect] = useState([])
  const [canProduccion, setCanProduccion] = useState([])
  const { pedido } = props.param
  useEffect(() => {
    console.log(pedido)
    const llamado = async () => {
      const categorias = await api.obtenerCategorias('N')
      console.log(categorias)
      if (categorias) {
        if (categorias.SMListaErrores.length === 0) {
          setCategorias(categorias.categorias)
        } else {
          alert('Hubo un error. Se recargara la página.')
          window.location.reload()
        }
      } else {
        alert('Hubo un problema con la conección. Se recargara la página.')
        window.location.reload()
      }
      const productosProduccion = await api.listaProductoMaestro(
        null,
        'S',
        'S',
        'N'
      )
      console.log(productosProduccion)
      if (productosProduccion) {
        if (productosProduccion.SMListaErrores.length === 0) {
          setProductos(productosProduccion.productos)
          setProductosOpciones(
            productosProduccion.productos.map(item => item.productos).flat()
          )
        } else {
          alert('Hubo un error. Se recargara la página.')
          window.location.reload()
        }
      } else {
        alert('Hubo un problema con la conección. Se recargara la página.')
        window.location.reload()
      }
    }
    llamado()
  }, [])
  //   useEffect(() => {
  //     console.log(productoSelect)
  //   }, [
  //     listadoMaestros,
  //     produccionMaestros,
  //     categorias,
  //     productos,
  //     productoSelect
  //   ])
  const cambiarCategoria = async e => {
    if (e === '') {
      let opciones = productos
        .map(item => item.productos)
        .flat()
      setProductosOpciones(opciones)
      setProductoSelect('')
    } else {
      setCategoriaSelect(parseInt(e))
      let opciones = productos
        .filter(c => c.categoria.categoria === parseInt(e))
        .map(item => item.productos)
        .flat()
      setProductosOpciones(opciones)
      setProductoSelect('')
    }

    //const productosProduccion = await api.listaProductoMaestro(null, 'S', 'S', 'N')
    //console.log(productosProduccion)
    //console.log(categoria)
  }
  const agregarProduccion = async event => {
    const nuevo = await api.editarIngresoProductoMaestro(
      parseInt(pedido.ingreso),
      parseFloat(canProduccion),
      1,
      parseInt(productoSelect)
    )
    if (nuevo){
      if (nuevo.SMListaErrores.length === 0) {
        props.cerrarmodal();
      }
      else{
        alert('Debe seleccionar el producto e ingresar la cantidad de produccion numerica')
      }
    }
    else {
      alert('Debe seleccionar el producto e ingresar la cantidad de produccion numerica')
    }
  }
  return (
    <>
      <e.Contenido $escomputadora={esComputadora}>
        <div className='cantProductoListClass'>
          <Opciones
            id='cantProductoList'
            formPersona='entra'
            alto='55px'
            moderno={'Categoría de Producto'}
            tipo='text'
            fontSize='0.9em'
            opciones={categorias !== null ? categorias : []}
            clave='codCategoria'
            valor={['descCategoria']}
            esComputadora={esComputadora}
            onClick={e => cambiarCategoria(e)}
            estilos={{
              backgroundColor: '#EFEFEF4D',
              border: '2px solid #bababa'
            }}
            //onChange={cambiaCampoClick}
            //estilos={estiloVacio(docSelec)}
          />
        </div>

        <div className='ProductoListClass'>
          <Opciones
            id='ProductoList'
            formPersona='entra'
            alto='55px'
            moderno={'Producto'}
            tipo='text'
            fontSize='0.9em'
            opciones={productosOpciones !== null ? productosOpciones : []}
            clave='producto'
            valor={['descripcion']}
            esComputadora={esComputadora}
            onClick={e => {
              setProductoSelect(e)
            }}
            estilos={{
              backgroundColor: '#EFEFEF4D',
              border: '2px solid #bababa'
            }}
            limpiar={productoSelect === '' ? true : false}
            //onChange={cambiaCampoClick}
            //estilos={estiloVacio(docSelec)}
          />
        </div>
        <div className='cantProdModalClass'>
          <Input
            style={{}}
            textoStyle={{}}
            id='cantProdModal'
            moderno={'Producciones'}
            tipo='number'
            onChange={e => {
              setCanProduccion(e.currentTarget.value.toUpperCase().trim())
              //filtrosNomApe(e.currentTarget.value)
            }}
            // onClick={e => {console.log(e)
            //   //setfnomape(e.currentTarget.value.toUpperCase().trim())
            //   //filtrosNomApe(e.currentTarget.value)
            // }}
            valorInicial={''}
            esComputadora={esComputadora}
            //limpiar={fnomape === '' ? true: false}
          />
        </div>
        <div className='botonCancelar'>
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vw'
            }}
            Promise
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1.3vw' : '2.75vw',
              padding: '0'
            }}
            texto='Cancelar'
            tipo='submit'
            estaProcesando={false}
            onClick={props.cerrarmodal}
          />
        </div>
        <div className='botonAgregar'>
          <Boton
            style={{
              backgroundColor: 'rgb(45, 139, 11)',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vw'
            }}
            Promise
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1.3vw' : '2.75vw',
              padding: '0'
            }}
            texto='Aceptar'
            tipo='submit'
            estaProcesando={false}
            onClick={agregarProduccion}
          />
        </div>
      </e.Contenido>
    </>
  )
}

export default AgregarProductoProd
