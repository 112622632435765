import styled, { keyframes } from "styled-components";
import * as fg from "./../Api/FuncionesGlobales";

export const animacionSpinner = (esComputadora) =>
  keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
export const StyledSpinner = styled.div`
  box-sizing: border-box;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: ${(props) => animacionSpinner(props.esComputadora)} 1s linear
    infinite;
  margin: auto;
`;

export const Spinner = (param) => (
  <StyledSpinner esComputadora={param.esComputadora} />
);

export const Contenido = styled.div`
  button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.2s;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      height: 5vw;
    }
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3) {
      height: 2.5vw;
    }
  }
  button > div {
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.4vw !important;
    }
      @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3) {
      font-size: 0.8vw !important;
    }
  }
`;
