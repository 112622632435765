import { useEffect, useState, useContext } from 'react'
import { Contexto } from '../../../index'
import Boton from '../../../../../Elementos/Boton'
import Input from '../../../../../Elementos/Input'
import Opciones from '../../../../../Elementos/Opciones'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as e from './REMaestroCSS'
import borrar from './../../../../../Imagenes/Iconos/borrar.png'
import editar from './../../../../../Imagenes/Iconos/editar.png'

import * as api from './../../../../../Api/Api'
import * as fg from './../../../../../Api/FuncionesGlobales'

function REMaestro (props) {
  const { esComputadora } = useContext(Contexto)
  const [listoriginal, setlistoriginal] = useState({
    maestros: []
  })
  const [filtros, setfiltros] = useState({
    maestros: []
  })
  const [datos, setdatos] = useState({
    codProd: props.param.codigoprod,
    codMaestro: props.param.maestrorel || '',
    pagoMaestro: props.param.pagomaestro || ''
  })


  useEffect(() => {
    console.log(props)
    const ejecucion = async () => {
      const maestro = await api.listarMaestros(null, 'S')
      if (maestro) {
        //console.log(maestro)
        if (maestro.SMListaErrores.length === 0) {
          //console.log(maestro.maestro)
          setlistoriginal(l => ({ ...l, maestros: maestro.maestro }))
          setfiltros(l => ({ ...l, maestros: maestro.maestro }))
        }
      }
    }
    ejecucion()
    //&listarMaestros
  }, [])

  useEffect(() => {
    console.log(datos)
  }, [datos])

  const asignarvalor = e => {
    let temp = e.target
    setdatos(d => ({ ...d, [temp.id]: temp.value }))
  }
  return (
    <>
      <e.Contenido $escomputadora={esComputadora}>
        <div className='nomprodparam'>
          {['mmr'].includes(props.modo) &&
            'Editando Pago- ' +
              fg.capitalizar_palabra(props.param.nombrema || '')}
          {['rmp'].includes(props.modo) &&
            'Relacionar con ' + props.param.nombreprod}
        </div>
        <div className='nomprodparam'>
          {['mpp'].includes(props.modo) &&
            'Editando - ' + props.param.nombreprod}
          {['app'].includes(props.modo)}
        </div>

        <div className='codMaestroclass input'>
          <Opciones
            style={{}}
            valorInicial={datos.codMaestro}
            id='codMaestro'
            formPersona='entra'
            moderno='Maestro'
            opciones={filtros.maestros.length > 0 ? filtros.maestros : []}
            clave='usuario'
            valor={['nombre', 'apellido_paterno']}
            esComputadora={esComputadora}
            onClick={e => {}}
            onChange={e => {
              asignarvalor(e)
            }}
            deshabilitado={['mmr'].includes(props.modo) ? true: false}
          />
        </div>
        <div className='pagoMaestroclass input'>
          <Input
            id='pagoMaestro'
            moderno={'Pago'}
            tipo='number'
            onChange={e => {
              asignarvalor(e)
            }}
            valorInicial={datos.pagoMaestro}
            esComputadora={esComputadora}
          />
        </div>

        <div className='aceptarboton input'>
          <Boton
            style={{
              backgroundColor: '#2D8B0B',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Aceptar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={e => {
              const llamado = async () => {
                let paramValidos = true
                Object.keys(datos).forEach(d => {
                  console.log(datos[d])
                  const etiqueta = document.getElementById(d)
                  if (etiqueta) {
                    console.log(etiqueta)
                    if (['', '0', 0].includes(datos[d]) && d !=="pagoMaestro") {
                      console.log(datos)
                      etiqueta.setAttribute('vacio', 'vacio')
                      paramValidos = false
                    } 
                    else if (['', '0', 0].includes(datos[d]) && d ==="pagoMaestro") {
                      console.log("entro correcto")
                    }
                    else {
                      etiqueta.removeAttribute('vacio')
                    }
                  }
                })
                if (paramValidos) {
                  const modificar = await api.registroProductoMaestro(
                    [[parseInt(datos.codProd), parseFloat(datos.pagoMaestro)]],
                    datos.codMaestro,
                    'N'
                  )
                  if (modificar) {
                    if (modificar.SMListaErrores.length === 0) {
                      props.cerrarmodal()
                    } else {
                      console.log(modificar)
                      alert('Fallo actualizando el producto')
                    }
                  }
                } else {
                  alert('Es necesario llenar los campos marcados en rojo')
                }
              }
              llamado()
              //setTipoAccion(e.currentTarget.id)
            }}
          />
        </div>
        <div className='cancelarboton input'>
          <Boton
            style={{
              backgroundColor: '#E12121',
              borderRadius: esComputadora ? '0.5vw' : '1vw',
              padding: esComputadora ? '0.8vw' : '1vh 2vw'
            }}
            textoStyle={{
              color: 'white',
              width: '100%',
              fontSize: esComputadora ? '1vw' : '2.5vw'
            }}
            texto='Cancelar'
            tipo='submit'
            estaProcesando={false}
            id='agregarinsumo'
            onClick={e => {
              //setTipoAccion(e.currentTarget.id)
              props.cerrarmodal()
            }}
          />
        </div>
      </e.Contenido>
    </>
  )
}

export default REMaestro
