import styled, { css } from 'styled-components'

/*
export const inputLabelActivoCSS = (esComputadora, textoStyle) => ({
  top: '20%',
  fontSize: esComputadora ? '0.8vw' : '2.0vw',
  color: '#333',
  ...textoStyle
})
export const inputLabelCSS = (esComputadora, textoStyle) => ({
  position: 'absolute',
  top: '50%',
  left: esComputadora ? '1vw' : '2.5vw',
  transform: 'translateY(-50%)',
  color: '#999',
  pointerEvents: 'none',
  transition: '0.2s',
  fontSize: esComputadora ? '1vw' : '2.5vw',
  ...textoStyle
})

export const contraseniaCSS = esComputadora => ({
  position: 'absolute',
  right: '3vw',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
  fontSize: esComputadora ? '1.3vw' : '3vw'
})
export const inputTextCSS = esComputadora => ({
  padding: esComputadora ? '2vw 1.5vw 0.25vw 1vw' : '4vw 3vw 0.5vw 2vw',
  height: 'auto',
  width: '100%' ,
  border: esComputadora ? '0.1vw solid #ccc' : '0.3vw solid #ccc',
  borderRadius: esComputadora ? '0.5vw' : '1vw',
  fontSize: esComputadora ? '1.3vw' : '3vw',
  boxSizing: 'border-box',
  backgroundColor: 'white'
})

export const inputTextDateCSS = esComputadora => ({
  padding: esComputadora ? '2vw 1.5vw 0.25vw 1vw' : '4vw 3vw 0.5vw 2vw',
  height: 'auto',
  width: '100%' ,
  border: esComputadora ? '0.1vw solid #ccc' : '0.3vw solid #ccc',
  borderRadius: esComputadora ? '0.5vw' : '1vw',
  fontSize: esComputadora ? '1.3vw' : '2.5vw',
  boxSizing: 'border-box',
  color: 'transparent',
  backgroundColor: 'white'
})

export const inputFieldDateCSS = esComputadora => ({
  padding: esComputadora ? '2vw 1.5vw 0.25vw 1vw' : '4vw 3vw 0.5vw 2vw',
  height: 'auto',
  width: '100%' ,
  border: esComputadora ? '0.2vw solid #ccc' : '0.4vw solid #ccc',
  borderRadius: esComputadora ? '0.5vw' : '1vw',
  fontSize: esComputadora ? '1vw' : '2.5vw',
  color: '#333',
  boxSizing: 'border-box'
})
  */

export const Contenido = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;

  input {
    width: 100%;
    box-sizing: border-box;
    height: auto;

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      height: 5vw !important;
    }
  }
  input:focus {
    outline: none;
  }

  input[type='date'] {
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    color: ${props => (props.$contenido ? 'black' : 'transparent')};
  }

  #contrasenialogin {
    position: absolute;
    right: 3vw;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    fontsize: ${props => (props.$escomputadora ? '1.3vw' : '3vw')};
  }

  #labelinput {
    position: absolute;
    top: 50%;
    left: ${props => (props.$escomputadora ? '1vw' : '2.5vw')};
    transform: translateY(-50%);
    color: #999;
    pointer-events: none;
    transition: 0.2s;
    font-size: ${props => (props.$escomputadora ? '1vw' : '2.5vw')};

    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
      (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
      font-size: 1.5vw;
      left: 1.5vw;
    }
  }

  ${props =>
    props.$contenido &&
    css`
      #labelinput {
        top: 20%;
        font-size: ${props => (props.$escomputadora ? '0.8vw' : '2.0vw')};
        color: #333;

        @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
          (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
          font-size: 1.2vw;
        }
      }
    `}

  ${props =>
    props.$id &&
    css`
      #${props.$id} {
        background-color: ${props =>
          props.$deshabilitado ? '#CCCCCC' : '#ffffff'};
        ${props =>
          props.$moderno !== '' &&
          `
            padding: ${
              props.$escomputadora
                ? '2vw 1.5vw 0.25vw 1vw'
                : '4vw 3vw 0.5vw 2vw'
            };
          `}
        height: auto;
        width: 100%;
        border: ${props =>
          props.$escomputadora ? '0.1vw solid #ccc' : '0.1vw solid #ccc'};
        border-radius: ${props => (props.$escomputadora ? '0.5vw' : '1vw')};
        font-size: ${props => (props.$escomputadora ? '1.3vw' : '3vw')};
        box-sizing: border-box;

        @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3),
          (min-aspect-ratio: 3/5) and (max-aspect-ratio: 1/1) {
          font-size: 2vw;

          ${props =>
            props.$moderno !== '' &&
            `
            padding: 2.5vw 3vw 0.5vw 2vw;
          `}
        }
          
      }
    `}

    ${props =>
    props.$id &&
    css`
      #${props.$id}:focus {
        border: ${props =>
          props.$escomputadora
            ? '0.1vw solid rgb(74, 151, 74)'
            : '0.1vw solid rgb(74, 151, 74)'} ;
        
    `}
`
