import styled from 'styled-components'

export const Contenido = styled.div`
  grid-area: 1/1/21/21;
  display: grid;
  grid-template-rows: repeat(20, 1fr);
  grid-template-columns: repeat(20, 1fr);

  .cabecera {
    grid-area: 1/1/2/21;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(173, 103, 38);
    font-size: ${props => (props.$escomputadora ? '2' : '4')}vw;
    color: white;
    font-weight: bolder;
    border-radius: 1vw 1vw 0px 0px;
  }
  .cabecera.cabecerapedido {
    grid-area: 1/1/1/2;
  }

  .filtro {
    grid-area: 2/1/3/21;
    display: grid;
    grid-template-columns: 50% 30% 20%;
    grid-template-rows: 100%;
    background-color: rgb(250, 250, 250);
    box-shadow: rgb(129, 129, 129) 0px 0px 0.3vw inset;
    padding: 0.5vw;
    box-sizing: border-box;
  }
  .opciones {
    grid-area: 19/1/21/21;
    color: white;
    background-color: #ad6726;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cuerpo {
    grid-area: 3/1/19/21;
    color: white;
    overflow: auto;
  }

  .pedido {
    height: 100%;
    width: ${props =>
      props.$escomputadora
        ? props.$cantMaestros * 50
        : props.$cantMaestros * 100}%;
    color: white;
    display: flex;
    gap: 2.5vw;
    align-items: center;
  }
  .pedidoMaestro {
    height: 97%;
    width: 100%;
    color: white;
    border: 2px solid rgb(207, 205, 205);
    border-radius: 1vw 1vw 1vw 1vw;
    box-shadow: 0px 0px 1vw rgb(110, 110, 110);
    display: grid;
    grid-template-rows: ${props =>
      props.$escomputadora ? '10% 60% 15% 15%' : '10% 70% 10% 10%'};
    grid-template-columns: 100%;
  }
  .cuerpoPedido {
    color: black;
    height: 100%;
    width: 100%;
    grid-area: 2/1/3/2;
    overflow: auto;
  }
  .descripcion {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 40% 20% 20% 20%;
  }
  .descripcion > div {
    color: black;
    font-family: verdana;
    border-bottom: 2px solid rgb(138, 138, 138);
    text-decoration-thickness: 0.3vw;
    font-weight: 900;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1.2' : '3')}vw;
    background-color: rgb(253, 249, 226);
  }
  .detallePedido {
    display: grid;
    height: auto;
    width: 100%;
    color: black;
    padding: 0.5vw;
    box-sizing: border-box;
    overflow: auto;
  }
  .detallePedido > div.productos:nth-child(even) {
    background-color: rgb(240, 240, 240);
  }
  .productos {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 40% 20% 20% 20%;
    color: black;
    padding: 0.5vw;
    box-sizing: border-box;
    overflow: auto;
    height: auto;
  }
  .productos > div {
    color: black;
    text-align: center;
    font-size: ${props => (props.$escomputadora ? '1' : '2.5')}vw;
  }
  .productos > div > input {
    height: 90% !important;
    @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/3) {
      font-size: 1vw !important;
    }
  }
  .botonOpciones {
    width: ${props => (props.$escomputadora ? '15%' : '30%')};
    margin-right: 5%;
  }
  .comentario {
    grid-area: 3/1/4/2;
    height: 100%;
    width: 100%;
    color: black;
    //align-text: center;
    box-sizing: border-box;
    //border: 1.5px solid rgb(207, 205, 205);
    //border-radius: 1vw 1vw 1vw 1vw;
    font-size: ${props => (props.$escomputadora ? '1.2' : '2')}vw;
    //box-shadow: 0px 0px 1vw rgb(110, 110, 110);
  }
  .total {
    grid-area: 4/1/5/2;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 55% 45%;
    height: 100%;
    width: 100%;
    color: black;
    align-text: center;
    box-sizing: border-box;
    overflow: auto;
    border: 2px solid rgb(207, 205, 205);
    border-radius: 1vw 1vw 1vw 1vw;
    box-shadow: 0px 0px 1vw rgb(110, 110, 110);
  }
  .total > div {
    color: black;
    font-family: verdana;
    text-decoration-thickness: 0.3vw;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => (props.$escomputadora ? '1.2' : '3')}vw;
  }
  .total > .botonAgregar {
    height: 100%;
    padding: 1% 20%;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  
`
