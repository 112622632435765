import React, { useState, useEffect, useContext } from 'react'
import * as e from './ListasProduccionCSS'
import ListasPDF from './ListasPDF'

import Input from '../../../../../Elementos/Input'
import Boton from '../../../../../Elementos/Boton'
import Opciones from '../../../../../Elementos/Opciones'
import { Contexto } from '../../../../../Componentes/Principal/index'
import ModalGenerico from './../../../../../Elementos/ModalGenerico'
import * as api from './../../../../../Api/Api'
import * as fg from '../../../../../Api/FuncionesGlobales'
import VerProduccionDetalle from './VerPedidoDetalle'

function ListasProduccion (props) {
  const [maestro, setMaestro] = useState('')

  const { esComputadora, rol, usuariosesion } = useContext(Contexto)
  const [listaMaestro, setListaMaestro] = useState([])
  const [datosProduccion, setDatosProduccion] = useState([])
  const [fechaDesde, setFechaDesde] = useState(null)
  const [fechaHasta, setFechaHasta] = useState(null)
  // ✅ Editable Dates
  const [fechas, setFechas] = useState([])
  const [diasSemana, setDiasSemana] = useState([])

  //TOTALES
  const [totalM, setTotalM] = useState({})
  const [total, setTotal] = useState(0)

  //MODAL
  const [vermodal, setvermodal] = useState(false)
  const [dispotivo, setdispositivo] = useState([]) //ayuda a identificar si es wc, hc o wm, hm
  const [opclick, setopclick] = useState('')
  const [primeracarga, setprimeracarga] = useState(true)
  const [param, setparam] = useState({
    pedido: null,
    datosProduccion: null,
    diasSemana: null,
    fechas: null
  })
  const [opmodal, setopmodal] = useState({
    app: { wc: 80, hc: 90, wm: 90, hm: 90, t: 'Ver Detalle de la Producción' },
    pdf: { wc: 80, hc: 80, wm: 90, hm: 70, t: 'Lista Producciones Pdf' }
  })
  //FIN MODAL

  const filtroFechas = async event => {
    console.log(fechaDesde)
    console.log(fechaHasta)
    console.log(fechaDesde ? fechaDesde : null, fechaHasta ? fechaHasta : null)
    if (
      (fechaDesde && fechaHasta) ||
      (fechaDesde === '' && fechaHasta === '')
    ) {
      const producciones = await api.listasIngresoProductoMaestro(
        fechaDesde ? fechaDesde : null,
        fechaHasta ? fechaHasta : null,
        null
      )
      console.log(producciones)
      if (producciones && producciones.SMListaErrores.length === 0) {
        setFechas(producciones.fechas)
        setDiasSemana(producciones.dias)
        setDatosProduccion(producciones.producciones)
        let sumaGeneral = 0
        const sumaPorCadaMaestro = producciones.producciones.reduce(
          (acc, item) => {
            const sumaMaestro = item.produccion.reduce(
              (acumulador, produccion) => {
                if (produccion !== null) {
                  acumulador += produccion.produccion.total_terminado || 0
                }
                return acumulador
              },
              0
            )

            // Guardar la suma con la clave como el usuario del maestro
            acc[item.maestro.usuario] = sumaMaestro

            sumaGeneral += sumaMaestro
            return acc
          },
          {}
        )
        console.log(sumaPorCadaMaestro)
        console.log(sumaGeneral)
        setTotalM(sumaPorCadaMaestro)
        setTotal(sumaGeneral)
      } else {
        alert('Hubo un error. Se recargara la página.')
        window.location.reload()
      }
    } else {
      alert('Ingrese un rango de fechas o ningun rango')
    }
  }
  useEffect(() => {
    const obtenerMaestros = async () => {
      try {
        const producciones = await api.listasIngresoProductoMaestro(
          null,
          null,
          null
        )
        console.log(producciones)
        if (producciones && producciones.SMListaErrores.length === 0) {
          setFechas(producciones.fechas)
          setDiasSemana(producciones.dias)
          setDatosProduccion(producciones.producciones)
          let sumaGeneral = 0
          const sumaPorCadaMaestro = producciones.producciones.reduce(
            (acc, item) => {
              const sumaMaestro = item.produccion.reduce(
                (acumulador, produccion) => {
                  if (produccion !== null) {
                    acumulador += produccion.produccion.total_terminado || 0
                  }
                  return acumulador
                },
                0
              )

              // Guardar la suma con la clave como el usuario del maestro
              acc[item.maestro.usuario] = sumaMaestro

              sumaGeneral += sumaMaestro
              return acc
            },
            {}
          )
          console.log(sumaPorCadaMaestro)
          console.log(sumaGeneral)
          setTotalM(sumaPorCadaMaestro)
          setTotal(sumaGeneral)
        } else {
          alert('Hubo un error. Se recargara la página.')
          //window.location.reload()
        }
      } catch (error) {
        alert('Hubo un problema con la conexión. Se recargará la página.')
        //window.location.reload()
      }
      setprimeracarga(false)
    }
    obtenerMaestros()
  }, [])

  useEffect(() => {
    console.log('entre en primera carga', !primeracarga, opclick !== '')
    if (!primeracarga && opclick !== '') {
      console.log('entre despues de la primera carga')
      setdispositivo(esComputadora ? ['wc', 'hc'] : ['wm', 'hm'])
      setvermodal(true)
    }
  }, [opclick, esComputadora])

  const cerrarModal = async () => {
    setvermodal(false)
    console.log(param.pedido)
    if (param.pedido) {
      const pedidoActualizado = await api.listaIngresoProductoMaestro(
        param.pedido.produccion.ingreso,
        param.pedido.produccion.maestro.usuario,
        'N'
      )
      let produccionesTemp = []
      if (pedidoActualizado && pedidoActualizado.SMListaErrores.length === 0) {
        const promesas = datosProduccion.map(async e => {
          console.log(param.pedido.produccion.maestro.usuario)
          console.log(pedidoActualizado)
          if (
            param.pedido.produccion.maestro.usuario ===
            pedidoActualizado.produccion.maestro.usuario
          ) {
            let detalleProdTemp = []
            const promesas2 = e.produccion.map(async p => {
              if (
                p &&
                p.produccion.ingreso === param.pedido.produccion.ingreso
              ) {
                console.log(datosProduccion)
                console.log(p)
                console.log(pedidoActualizado.produccion)
                console.log(pedidoActualizado.productosJson)
                detalleProdTemp.push({
                  produccion: pedidoActualizado.produccion,
                  productosJson: pedidoActualizado.productosJson
                })
              } else {
                detalleProdTemp.push(p)
              }
            })
            await Promise.all(promesas2)
            produccionesTemp.push({
              maestro: e.maestro,
              produccion: detalleProdTemp
            })
          } else {
            produccionesTemp.push(e)
          }
        })
        await Promise.all(promesas)
        console.log(datosProduccion)
        console.log(produccionesTemp)
        let sumaGeneral = 0
        const sumaPorCadaMaestro = produccionesTemp.reduce((acc, item) => {
          const sumaMaestro = item.produccion.reduce(
            (acumulador, produccion) => {
              if (produccion !== null) {
                acumulador += produccion.produccion.total_terminado || 0
              }
              return acumulador
            },
            0
          )

          // Guardar la suma con la clave como el usuario del maestro
          acc[item.maestro.usuario] = sumaMaestro

          sumaGeneral += sumaMaestro
          return acc
        }, {})
        console.log(sumaPorCadaMaestro)
        console.log(sumaGeneral)
        setTotalM(sumaPorCadaMaestro)
        setTotal(sumaGeneral)
        setDatosProduccion(produccionesTemp)
      } else {
        alert('Hubo un error. Se recargara la página.')
        console.log(pedidoActualizado)
        //window.location.reload()
      }
    }
  }
  const clickVerProduccion = event => {
    console.log('entre')
    setvermodal(true)
    setopclick(!primeracarga ? 'app' : '')
    // setOrigenEvento('r1')
    // const codCategoria = event.currentTarget.parentNode.getAttribute('catprod')
    // setModal(true)
    // setModoModal('agregar')
    // setEditarProducto(false)
    // setEliminarProducto(false)
    // setAgregarProducto(true)
    // setParamAgregarProd({
    //   ['codCategoria']: codCategoria,
    //   productos: paramEditProd,
    //   codUsuario: distSelec
    // })
    // setParamEliminarProd({})
    // setParamEditProd({})
  }

  return (
    <>
      {vermodal && dispotivo.length > 0 && (
        <ModalGenerico
          capa={1}
          width={opmodal[opclick][dispotivo[0]]}
          height={opmodal[opclick][dispotivo[1]]}
          titulo={opmodal[opclick].t}
          cerrarmodal={cerrarModal}
        >
          {['mpp', 'app'].includes(opclick) && (
            <VerProduccionDetalle cerrarmodal={cerrarModal} param={param} />
          )}
          {['pdf'].includes(opclick) && (
            <ListasPDF cerrarmodal={cerrarModal} param={param} />
          )}
        </ModalGenerico>
      )}
      <e.Contenido $escomputadora={esComputadora} $dias={fechas.length}>
      
        <div className='cabecera'>Producción</div>
        {rol === "SUPER ADMINISTRADOR"&&<div className='filtro'>
          <div>
            <Input
              id='desde'
              moderno='Desde'
              tipo='date'
              onChange={e => setFechaDesde(e.target.value)}
              valorInicial=''
              esComputadora={esComputadora}
            />
          </div>
          <div>
            <Input
              id='hasta'
              moderno='Hasta'
              tipo='date'
              onChange={e => setFechaHasta(e.target.value)}
              valorInicial=''
              esComputadora={esComputadora}
            />
          </div>
          <div>
            <Boton
              style={{
                backgroundColor: 'rgb(45, 139, 11)',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1.3vw' : '2.75vw',
                padding: '0'
              }}
              texto='Buscar'
              tipo='submit'
              onClick={event => {
                filtroFechas(event)
              }}
            />
          </div>
        </div>}
        <div className='contenedor'>
          <table className='tabla-produccion' style={{ fontSize: '1em' }}>
            <thead>
              <tr>
                <th style={{ width: '100px' }}></th>
                {diasSemana.map((dia, index) => (
                  <th key={index} style={{}}>
                    {dia}
                  </th>
                ))}
                {rol === "SUPER ADMINISTRADOR" &&<th></th>}
              </tr>
              <tr>
                <th></th>
                {fechas.map((fecha, index) => (
                  <th key={index}>
                    <div>{fecha}</div>
                  </th>
                ))}
                {rol === "SUPER ADMINISTRADOR" &&<th>Total</th>}
              </tr>
            </thead>
            <tbody>
              {datosProduccion.map((m, indexFila) => (
                <tr key={indexFila}>
                  <td style={{}}>
                    {fg.capitalizar_palabra(
                      m.maestro.nombre + ' ' + m.maestro.apellido_paterno
                    )}
                  </td>
                  {m.produccion.map((cantidad, indexColumna) => (
                    <td
                      key={indexColumna}
                      className={cantidad != null ? 'disponible' : 'faltante'}
                    >
                      <Boton
                        style={{
                          backgroundColor:
                            cantidad != null
                              ? cantidad.produccion.fecha_ingreso === null
                                ? 'rgb(255, 166, 175)'
                                : 'rgb(255, 221, 171)'
                              : 'rgb(224, 111, 123)',
                          borderRadius: esComputadora ? '0.5vw' : '1vw',
                          padding: esComputadora ? '0.8vw' : '1vw'
                        }}
                        textoStyle={{
                          color: 'black',
                          width: '100%',
                          fontSize: esComputadora ? '1vw' : '2.75vw',
                          padding: '0'
                        }}
                        texto={
                          cantidad != null
                            ? rol === "SUPER ADMINISTRADOR" ? fg.formatearDecimal(cantidad.produccion.total_terminado,1) : "Detalle"
                            : 0
                        }
                        tipo='number'
                        estaProcesando={false}
                        onClick={event => {
                          setparam(
                            !primeracarga &&
                              cantidad && {
                                ...param,
                                pedido: cantidad
                              }
                          )
                          clickVerProduccion(event)
                        }}
                        deshabilitado={cantidad === null ? true : false}
                      />
                    </td>
                  ))}
                  {rol === "SUPER ADMINISTRADOR"&&<td style={{}}>
                    {fg.formatear_moneda(totalM[m.maestro.usuario])}
                  </td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {rol === "SUPER ADMINISTRADOR"&&<div className='opciones'>
          <div className='botonOpciones'>
            <Boton
              style={{
                backgroundColor: '#E12121',
                borderRadius: esComputadora ? '0.5vw' : '1vw',
                padding: esComputadora ? '0.8vw' : '1vw'
              }}
              textoStyle={{
                color: 'white',
                width: '100%',
                fontSize: esComputadora ? '1.3vw' : '2.75vw',
                padding: '0'
              }}
              texto='Ver PDF'
              tipo='submit'
              onClick={event => {
                setparam(
                  !primeracarga && {
                    datosProduccion: datosProduccion,
                    fechas: fechas,
                    diasSemana: diasSemana
                  }
                )
                setopclick(!primeracarga ? 'pdf' : '')
                setvermodal(true)
                //event.preventDefault()
                //setfnomape('')
                //setfndoc('')
                //setfusuario('')
              }}
            />
          </div>
          <div className='total'>Total {fg.formatear_moneda(total)}</div>
        </div>}
      </e.Contenido>
    </>
  )
}
export default ListasProduccion
