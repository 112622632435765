import React, { useState, useEffect, Suspense, lazy } from 'react'
import ErrorBoundary from './ErrorBoundary'
import ListarPedidos from './Pedidos/ListarPedidos'
import GenerarPedidoDist from './GenerarPedidoDist/GenerarPedidoDist'
import GenerarPedido from './GenerarPedido/GenerarPedido'
import VentaTienda from './VentaTienda/VentaTienda'
import RegistroVentasTienda from './RegistroVentasTienda/RegistroVentasTienda'
import StockProductos from './StockProductos/StockProductos'
import CrearPersona from './InformacionGeneral/Personal/CrearPersona'
import AdministrarProductos from './InformacionGeneral/Productos/AdministrarProductos'
import Asistencias from './Asistencias/Asistencias'
//import Empresas from './InformacionGeneral/General/Empresas'
import Empresas from './InformacionGeneral/General/Empresas/Empresas'
import Marcas from './InformacionGeneral/General/Marcas/Marcas'
import Preventistas from './InformacionGeneral/Personal/Preventistas/Preventistas'
import Insumos from './InformacionGeneral/Insumos/Insumos'
import ListaIngresos from './Almacen/ListaIngresos/ListaIngresos'
import GenerarPedidoInsumos from './Almacen/GenerarPedidoInsumos/GenerarPedidoInsumos'
import ListaSalidas from './Almacen/ListaSalidas/ListaSalidas'
import GenerarSalidaInsumos from './Almacen/GenerarSalidaInsumo/GenerarSalidaInsumos'
import StockAlmacen from './Almacen/StockAlmacen/StockAlmacen'
import Personal from './InformacionGeneral/Personal/Personal/Personal'
import ProductosProd from './InformacionGeneral/ProductosProduccion/ProductosProd'
import GenerarPedidoProduccion from './Produccion/GenerarPedidoProduccion/GenerarPedidoProduccion'
import ListasProduccion from './Produccion/ListasProduccion/ListasProduccion'
import StockProducciones from './Produccion/StockProducciones/StockProducciones'



import Comprobantes from './Ventas/Comprobantes/Comprobantes'
import Guias from './Ventas/Guias/Guias'


import * as fg from './../../../Api/FuncionesGlobales'
import { obtenerCookie } from '../../../Api/Api'
function ContenidoCentral (param) {
  

  const contenedorContenidoCentralstyle = {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: param.esComputadora ? '2vw':'4vw'
  }
  const contenidostyle = {
    display: 'grid',
    gridTemplateRows: 'repeat(20, 1fr)',
    gridTemplateColumns: 'repeat(20, 1fr)',
    width: '100%',
    height: '100%',
    padding: param.esComputadora ? '1vw':'2vw',
    boxSizing: 'border-box',
    border: '1px solid #BBBBBB',
    borderRadius: '10px'
  }
  return (
    <>
      <div
        id='contenedorContenidoCentral'
        style={contenedorContenidoCentralstyle}
      >
        <div id='contenido' style={contenidostyle}>
          {param.opcion === null && <div style={{
            gridArea:'1/1/21/21',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems:'center',
            justifyContent: 'center',
            flexDirection: param.esComputadora ? "row":"column",
            //backgroundColor: "#CEAC5C",
            //boxShadow: "0 0 20px #ceac5c",
            borderRadius: param.esComputadora ? "1vw" : "2vw"
          }}>
            
            
            <div style={{
              display: 'flex',
              //color: fg.hex_rgb('#EE1D23', 1.0),
              fontFamily: "'Carattere', cursive",
              fontWeight: 600,
              fontStyle: 'normal',
              fontSize: param.esComputadora ? '2.9vw' : '6vw',
              textShadow:
                '2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff',
              marginTop: param.esComputadora ? '0':'4vw',
              marginLeft: param.esComputadora ? '2vw':'0',
              flexDirection: 'column',
              alignItems: "center",
              justifyContent: "center",
            }}>
              <div style={{letterSpacing: "0.3vw"}}> "
                {
                  fg.capitalizar_palabra(obtenerCookie("nombreUsuario") ? obtenerCookie("nombreUsuario"):"")
                }, Bienvenido(a) a San Miguel"
              </div>
              {false && <><div>Te desea la familia San Miguel</div>
              <div style={{color: "green"}}>"Dios es Bueno todo el Tiempo"</div></>}
            </div>
            
          </div>}
              {param.opcion==='despachoDePedidos' && <ListarPedidos esComputadora={param.esComputadora}/>}
              {param.opcion==='generarPedidoDistribuidor' && <GenerarPedidoDist esComputadora={param.esComputadora}/>}
              {param.opcion==='generarPedido' && <GenerarPedido esComputadora={param.esComputadora}/>}
              {param.opcion==='ventaTienda' && <VentaTienda esComputadora={param.esComputadora}/>}
              {param.opcion==='registroDeVentas' && <RegistroVentasTienda esComputadora={param.esComputadora}/>}
              {param.opcion==='stockProductos' && <StockProductos esComputadora={param.esComputadora}/>}
              {param.opcion==='crearPersona' && <CrearPersona esComputadora={param.esComputadora}/>}
              {param.opcion==='administrarProductos' && <AdministrarProductos esComputadora={param.esComputadora}/>}
              {param.opcion==='asistencias' && <Asistencias esComputadora={param.esComputadora}/>}
              {param.opcion==='empresasig' && <Empresas/>}
              {param.opcion==='marcasig' && <Marcas/>}
              {param.opcion==='preventistaig' && <Preventistas/>}
              {param.opcion==='insumosig' && <Insumos/>}
              {param.opcion==='listaingresosalmacen' && <ListaIngresos/>}
              {param.opcion==='generarpedidoinsumo' && <GenerarPedidoInsumos/>}
              {param.opcion==='listasalidasalmacen' && <ListaSalidas/>}
              {param.opcion==='generarsalidainsumos' && <GenerarSalidaInsumos/>}
              {param.opcion==='stockalmacen' && <StockAlmacen/>}
              {param.opcion==='personalig' && <Personal/>}
              {param.opcion==='productosproduccionig' && <ProductosProd/>}
              {param.opcion==='generarPedidoProduccion' && <GenerarPedidoProduccion/>}
              {param.opcion==='listasProduccion' && <ListasProduccion/>}
              {param.opcion==='stockProducciones' && <StockProducciones/>}
              {param.opcion==='comprobantes' && <Comprobantes/>}
              {param.opcion==='guias' && <Guias/>}
       
        </div>
      </div>
    </>
  )
}

export default ContenidoCentral
