import * as e from "./GuiasCSS";
import { Contexto } from "../../../../../Componentes/Principal/index";
import React, { useState, useEffect, useContext } from "react";
import Boton from "../../../../../Elementos/Boton";
import Input from "../../../../../Elementos/Input";
import Opciones from "../../../../../Elementos/Opciones";
import * as api from "./../../../../../Api/Api";
import * as fg from "../../../../../Api/FuncionesGlobales";
import ModalGenerico from "./../../../../../Elementos/ModalGenerico";

function Guias(props) {
  const { esComputadora, rol } = useContext(Contexto);

  useEffect(() => {}, []);

  return (<></>)
}
//     <e.Contenido $escomputadora={esComputadora}>
//       <div className="cabecera">Guía de Emisión</div>
//       <div
//         className="filtro"
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           width: "100%",
//           alignItems: "center",
//           padding: "0 2vw",
//         }}
//       >
//         <div style={{ padding: "0.1vw", flex: 1 }}>
//         <Opciones
//               id='opcionMaestros'
//               formPersona='entra'
//               alto='55px'
//               moderno='emitidos/recibidos'
//               tipo='text'
//               fontSize='0.9em'
//               opciones={[]}
//               clave='usuario'
//               valor={["nombre", "apellido_paterno"]}
//               esComputadora={esComputadora}
//               onClick={e => (e)}
//               estilos={{
//                 backgroundColor: "#EFEFEF4D",
//                 border: "2px solid #bababa"
//               }}
//             />
//         </div>
//         <div style={{ padding: "0.1vw", flex: 1 }}>
//           <Input
//             id="fnomape"
//             moderno={"desde"}
//             tipo="date"
//             onChange={(e) => {
//               //setfnomape(e.currentTarget.value.toUpperCase().trim())
//               //filtrosNomApe(e.currentTarget.value)
//             }}
//             valorInicial={""}
//             esComputadora={esComputadora}
//           />
//         </div>
//         <div style={{ padding: "0.1vw", flex: 1 }}>
//           <Input
//             id="fnomape"
//             moderno={"hasta"}
//             tipo="date"
//             onChange={(e) => {
//               //setfnomape(e.currentTarget.value.toUpperCase().trim())
//               //filtrosNomApe(e.currentTarget.value)
//             }}
//             valorInicial={""}
//             esComputadora={esComputadora}
//           />
//         </div>
//         <div
//           style={{
//             padding: "0.1vw",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//         </div>
//       </div>
//     </e.Contenido>
//   );
// }

export default Guias;
